import React from "react";
import { withStyles, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import LoadingButton from '@mui/lab/LoadingButton';
// import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import { teal, grey } from "@material-ui/core/colors";
import CloseIcon from '@mui/icons-material/Close';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from "@mui/lab/DatePicker";
import AddChildImage from "../assets/addChild.svg"
import KidfolioContext from "../store/KidfolioContext";
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import EventsDashboardImg from "../assets/EventsDashboardImg.svg";

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    secondaryColor: {
        color: grey[700]
    },
    heading: {
        fontSize: 18,
        fontWeight: 600
    },
    noPadding: {
        paddingTop: '0px !important',
        paddingLeft: 0,
        paddingRight: 0
    },
    padding: {
        padding: 0
    },
    mainHeader: {
        alignItems: "center"
    },
    mainContent: {
        padding: 40
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    }
});

function EditEventDialog(props) {

    const { classes, open } = props;
    const [eventName, setEventName] = React.useState(props.eventData.post.title);
    const [eventDesc, setEventDesc] = React.useState(props.eventData.post.description);
    const [eventDate, setEventDate] = React.useState(props.eventData.post.dateTime);
    const [eventNameRequiredError, setEventNameRequiredError] = React.useState(false);
    const [eventDateRequiredError, setEventDateRequiredError] = React.useState(false);
    const [addInProgress, setAddInProgress] = React.useState(false);
    const ctx = useContext(KidfolioContext);

    async function editEventDetails() {

        let validationFailed = false;
        if (!eventName || !eventName.trim()) {
            setEventNameRequiredError(true);
            validationFailed = true;
        }
        if (!eventDate) {
            setEventDateRequiredError(true);
            validationFailed = true;
        }
        if (validationFailed) {
            return;
        }
        setAddInProgress(true);
        const editEventObj = {
            ...props.eventData.post,
            // type: props.eventData.post.type,
            title: eventName,
            description: eventDesc,
            dateTime: eventDate,
            // ownerId: props.eventData.post.ownerId,
            // beneficiaryId: props.eventData.post.beneficiaryId,
            // giftItems: props.eventData.post.giftItems,
            // targetAmounts: props.eventData.post.targetAmounts,
            // defaultImage: props.eventData.post.defaultImage
        }
        await ctx.editEvent(props.eventData.post._id, editEventObj);
        await ctx.fetchChildEvents(props.eventData.post.beneficiaryId);
        props.handleClose();
    }

    return (
        <Dialog
            className={classes.root}
            maxWidth="xs"
            open={props.visible}
            onClose={() => props.handleClose}
        >
            <DialogContent className={classes.noPadding}>
                <Grid container>
                    <Grid item xs={12} align="right" className={classes.padding}>
                        <IconButton
                            edge="start"
                            align="right"
                            color="inherit"
                            aria-label="Close"
                            style={{ padding: 8 }}
                        >
                            <CloseIcon onClick={props.handleClose} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction="row" className={classes.mainHeader}>
                            <Grid item xs={12} align="center">
                                <img src={EventsDashboardImg} alt="Event" style={{ height: '70px', marginBottom: 20 }} />
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Typography className={classes.heading} variant="h5">
                                    Edit Event Details
                                </Typography>
                            </Grid>

                        </Grid>
                        <Grid
                            container
                            direction="row"
                            className={classes.mainContent}
                            spacing={1}
                        >
                            {/* <Grid item xs={12}>
                                <TextField
                                    error={eventNameRequiredError}
                                    helperText={eventNameRequiredError ? "Event's name is required" : ' '}
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    label="Event's Name"
                                    value={eventName}
                                    onChange={(event) => {
                                        setEventName(event.target.value);
                                        setEventNameRequiredError(false);
                                    }}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Event Date"
                                        views={["year", "month", "day"]}
                                        value={eventDate}
                                        onChange={(dateValue) => {
                                            setEventDate(dateValue);
                                            setEventDateRequiredError(false);
                                        }}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            error={eventDateRequiredError}
                                            helperText={eventDateRequiredError ? "Event's date is required" : ' '}
                                            fullWidth
                                            margin="dense"
                                            variant="outlined" />
                                        }
                                    />
                                </LocalizationProvider>
                            </Grid>
                            {/* <Grid item xs={12} >
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    label="Description"
                                    value={eventDesc}
                                    onChange={(event) => {
                                        setEventDesc(event.target.value);
                                    }}
                                />
                            </Grid> */}
                            <Grid item xs={12} align="center">
                                {!addInProgress ? <Button type={"submit"} variant="contained" disableElevation
                                    style={{ width: '100%', background: '#0079D9', marginTop: 20 }}
                                    onClick={editEventDetails}>
                                    <Typography style={{ color: '#ffffff' }}>Save</Typography>
                                </Button> :
                                    <LoadingButton loading loadingIndicator="Saving..." variant="contained"
                                        style={{ width: '100%', marginTop: 20 }}>
                                        Saving...
                                    </LoadingButton>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(styles)(EditEventDialog);
