import axios from "axios";
import { useLocation } from "react-router-dom";
import GiftedLogo from "../../assets/Gifted_Logo.svg";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { Container } from "@material-ui/core";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ProfileCard from "./ProfileCard";
import KidfolioContext from "../../store/KidfolioContext";
import { useContext } from "react";
import GiftInHand from "../../assets/GiftInHand.svg";

function AddUserDetails(props) {
    const { state } = useLocation();
    const ctx = useContext(KidfolioContext);
    const baseURL = process.env.REACT_APP_APP_BASE_URL;

    const addUserDetails = async () => {
        await axios.post(
            `${baseURL}/userApi/users`, {
            "fullName": state.fullName,
            "phoneNumber": '',
            "emailId": state.email,
            "location": {
                "city": "",
                "state": ""
            }
        },
            {
                headers: {
                    "x-firebase-auth": ctx.firebaseToken
                }
            }
        )
    }

    return (
        <div style={{
            padding: '8px',
            height: '100vh',
            background: 'transparent',
            backgroundColor: 'transparent',
            position: 'relative'
        }}>
            <Container maxWidth="sm" sx={{ border: 1, borderRadius: 2, gap: 2, margin: 4, mx: "auto", width: '30%', background: "#ffffff" }}>
                <Box sx={{ width: '90%', justifyContent: "center", padding: "auto", alignItems: "center", mx: "auto" }} >
                    <Stack spacing={2} direction="column"
                        justifyContent="center" alignItems="center">
                        <Paper sx={{
                            width: "347px",
                            background: "#FFFFFF",
                            border: "0.5px solid #D0D0D0",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: 'none',
                            padding: '20px',
                        }}>
                            <div style={{
                                padding: '8px',
                                display: 'flex',
                                minHeight: '6vh',
                                background: 'transparent',
                                backgroundColor: 'transparent',
                                position: 'relative',
                                justifyContent: "center"
                            }}>
                                <img style={{ width: "44px" }} src={GiftInHand} />
                            </div>
                            <div style={{
                                padding: '12px',
                                display: 'flex',
                                minHeight: '10vh',
                                background: 'transparent',
                                backgroundColor: 'transparent',
                                position: 'relative',
                                justifyContent: "center"
                            }}>
                                {/*<AddUserDetailsForm />*/}
                                <ProfileCard email={state.email} fullName={state.fullName} token={state.token} />
                            </div>
                        </Paper>
                    </Stack>
                </Box>
            </Container>
        </div>
    );
}

export default AddUserDetails;