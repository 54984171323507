
const event_contributors_data_mock = [
    {
        "contributions": [
            {
                "_id": "621f37eddc51cf80c83a57fa",
                "amount": 10,
                "beneficiaryId": "5f9a54e150bf9cc4bbac86771",
                "channel": "Wallet",
                "contributorId": "5e8b2f6d50ee57d62ea8860f",
                "contributorMessage": {
                    "personalMessage": "Happy b"
                },
                "createdOn": "2022-03-02T09:25:01.969Z",
                "currency": "USD",
                "fee": 0,
                "giftItems": [
                    {
                        "amount": 5,
                        "title": "Art Class"
                    },
                    {
                        "amount": 5,
                        "title": "Piano"
                    }
                ],
                "paymentOption": {
                    "identifier": "jgyjgjguhuhkuhuhujgtdyfuy",
                    "vendorName": "Catherine"
                },
                "postId": "621f37898d42360d27cddf5e",
                "postOwnerId": "5e8b2f6d50ee57d62ea8860f",
                "providerName": "Catherine",
                "sourceAccountId": "621f37898d42360d27cddf5e",
                "status": "processed"
            },   {
                "_id": "621f37eddc51cf80c83a57fa",
                "amount": 10,
                "beneficiaryId": "5f9a54e150bf9cc4bbac8677",
                "channel": "Wallet",
                "contributorId": "5e8b2f6d50ee57d62ea8860f",
                "contributorMessage": {
                    "personalMessage": "Happy b"
                },
                "createdOn": "2022-03-02T09:25:01.969Z",
                "currency": "USD",
                "fee": 0,
                "giftItems": [
                    {
                        "amount": 5,
                        "title": "Art Class"
                    },
                    {
                        "amount": 5,
                        "title": "Piano"
                    }
                ],
                "paymentOption": {
                    "identifier": "jgyjgjguhuhkuhuhujgtdyfuy",
                    "vendorName": "Catherine"
                },
                "postId": "621f37898d42360d27cddf5e",
                "postOwnerId": "5e8b2f6d50ee57d62ea8860f",
                "providerName": "Catherine",
                "sourceAccountId": "621f37898d42360d27cddf5e",
                "status": "processed"
            },   {
                "_id": "621f37eddc51cf80c83a57fa",
                "amount": 10,
                "beneficiaryId": "5f9a54e150bf9cc4bbac38677",
                "channel": "Wallet",
                "contributorId": "5e8b2f6d50ee57d62ea8860f",
                "contributorMessage": {
                    "personalMessage": "Happy b"
                },
                "createdOn": "2022-03-02T09:25:01.969Z",
                "currency": "USD",
                "fee": 0,
                "giftItems": [
                    {
                        "amount": 5,
                        "title": "Art Class"
                    },
                    {
                        "amount": 5,
                        "title": "Piano"
                    }
                ],
                "paymentOption": {
                    "identifier": "jgyjgjguhuhkuhuhujgtdyfuy",
                    "vendorName": "Catherine"
                },
                "postId": "621f37898d42360d27cddf5e",
                "postOwnerId": "5e8b2f6d50ee57d62ea8860f",
                "providerName": "Catherine",
                "sourceAccountId": "621f37898d42360d27cddf5e",
                "status": "processed"
            }
        ],
        "contributor": {
            "_id": "5e8b2f6d50ee57d62ea8860f",
            "active": true,
            "beneficiaryIds": [
                "5f7af97ac1bfcd463d3bafee",
                "5f7af9c65e407b129e977da1",
                "5f7b18a95e407b129e977da2",
                "5f7b193fc1bfcd463d3bafef",
                "5f7c43f2c1bfcd463d3baff0",
                "5f7dcfdac1bfcd463d3baff1",
                "5f803e0fc1bfcd463d3baff2",
                "5f806396c1bfcd463d3baff3",
                "5f8064e6c1bfcd463d3baff4",
                "5f9902ec1e6399d8e4b8ef82",
                "5f99711a3155a3d80bca5201",
                "5f9971a93155a3d80bca5203",
                "5f9a54e150bf9cc4bbac8677",
                "622af5aef5fd76c2880e97f4",
                "624585dd820b403707d552ad",
                "6245a51bf5fd76c2880e97f5"
            ],
            "createdOn": "2020-04-06T13:32:29.503Z",
            "giftItems": [
                "raceeee",
                "Car"
            ],
            "paymentOptions": [
                {
                    "identifier": "apple@apple.com",
                    "vendorName": "Apple Pay"
                },
                {
                    "identifier": "jgyjgjguhuhkuhuhujgtdyfuy",
                    "vendorName": "Catherine"
                }
            ],
            "policyAcceptances": {
                "App_PP": {
                    "accepted": true,
                    "version": 1
                },
                "App_TOS": {
                    "accepted": true,
                    "version": 1
                },
                "Dwolla_TOS": {
                    "accepted": true,
                    "version": 1
                }
            },
            "profile": {
                "emailId": "sarath@kiranum.com",
                "fullName": "Putin",
                "imageId": "5ec51713d997b3f3c278cd62",
                "location": {}
            },
            "uid": "bepO40fjRgROdGPXkXk9GPnUJB03",
            "updatedOn": "2022-03-31T10:42:11Z"
        },
        "count": 1,
        "post": {
            "_id": "621f37898d42360d27cddf5e",
            "accountId": null,
            "beneficiaryId": "5f9a54e150bf9cc4bsdbac8677",
            "commentIds": [
                "6245e94ef57d72c9fedaffed"
            ],
            "createdOn": "2022-03-02T09:23:21Z",
            "dateTime": "2022-03-03T18:30:00Z",
            "defaultImage": "https://static.kidfolio.app/assets/images/event-birthday-3.jpg",
            "description": "",
            "giftItems": [
                "Art Class",
                "Piano"
            ],
            "location": null,
            "ownerId": "5e8b2f6d50ee57d62ea8860f",
            "paymentOptions": [
                {
                    "identifier": "apple@apple.com",
                    "vendorName": "Apple Pay"
                },
                {
                    "identifier": "jgyjgjguhuhkuhuhujgtdyfuy",
                    "vendorName": "Catherine"
                }
            ],
            "shareUrl": "https://giftedstaging.page.link/kaxdTQqDr8KnrAis8",
            "targetAmounts": null,
            "title": "Birthday",
            "updatedOn": "2022-03-11T07:05:45Z",
            "virtualLink": ""
        },
        "recentContribution": "2022-03-02T09:25:01.969Z",
        "totalContribution": 10,
        "totalCount": 1,
        "totalFee": 0
    }
];

export default event_contributors_data_mock;
