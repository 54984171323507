import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container, makeStyles } from "@material-ui/core";
import HomePage from "./pages/HomePage";
import ChildPage from "./pages/ChildPage";
import Header from "./components/Header";
import { useState, useContext, useEffect } from "react";
import AppContainer from "./AppContainer";
import { getFromSession } from "./utils/Util";
import KidfolioContext from "./store/KidfolioContext";


const useStyles = makeStyles(() => ({
    App: {
        backgroundColor: "#fff7f8",
        color: "white",
        minHeight: "100vh",
        maxWidth: '1190px',
        padding: '0px 45px 20px 45px',
        margin: 'auto'
    },
    AppContainer: {
        width: '100%',
        alignItems: "center",
        margin: "auto"
    },
    MainContainer: {
        width: '100%',
        alignItems: "center",
        margin: "auto"
    },
    Header: {
        width: "100%",
        alignItems: "center",
        margin: "auto"
    }
}));

function App() {
    // useEffect(() => {
    //     document.body.style.overflow = "hidden";
    // }, []);
    const classes = useStyles();
    const kidfolioCtx = useContext(KidfolioContext);
    const user = getFromSession('user') || kidfolioCtx.user;

    return (
        <BrowserRouter>
            <div className={classes.App}>
                <div className={classes.Header}>
                    <Header />
                </div>
                <div className={user ? classes.AppContainer : classes.MainContainer}>
                    <AppContainer />
                </div>
            </div>
        </BrowserRouter>

    );
}

export default App;
