import React, {useCallback, useContext, useState} from 'react';

import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {useNotify} from "../utils/Notify";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
import {Connection, Keypair, PublicKey, SystemProgram, Transaction} from "@solana/web3.js";
import {wait} from "@testing-library/user-event/dist/utils";
import {makeStyles, MenuItem, Select, TextField} from "@material-ui/core";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PaymentDetailContext from "../store/PaymentDetailContext";
import {WalletMultiButton} from "@solana/wallet-adapter-material-ui";
import KidfolioContext from "../store/KidfolioContext";
import Divider from "@material-ui/core/Divider";
import SolPayMark from "../assets/Solana Pay Mark.svg";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";
import {AddCard} from "@mui/icons-material";
import {web3} from "@project-serum/anchor";
import * as splToken from "@solana/spl-token";

// import ChildCard from "./ChildCard";

const defaultValues = {
    public_address: "",
    amount: 0
};

const useStyles = makeStyles({
    leftUpperDiv: {
        padding: '8px',
        display: 'scroll',
        minHeight: '5vh',
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative'
    },
    leftLowerDiv: {
        padding: '8px',
        minHeight: '55vh',
        marginTop: '20px',
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative'
    },
})

function PaymentContent(props) {
    const { connection } = useConnection();
    const { publicKey, sendTransaction, wallet } = useWallet();
    const notify = useNotify();
    const classes = useStyles();
    // let paymentData = {
    //     public_address: "",
    //     amount: 0
    // }
    const [formValues, setFormValues] = useState(defaultValues);
    const paymentDetailCtx = useContext(PaymentDetailContext);
    const paymentDetails = paymentDetailCtx.paymentDetail;
    const kidfolioCtx = useContext(KidfolioContext);
    const childList = kidfolioCtx.childData;
    const [selectedValue, setSelectedValue] = useState('')


    const initiateUSDCPayment = async () => {
        if (!publicKey) throw new WalletNotConnectedError();
        // TODO: Move to env file and select based on environment.
        const tokenMintAddress = process.env.REACT_APP_USDC_MINT_ADDRESS;
        const mintPublicKey = new web3.PublicKey(tokenMintAddress);
        const {TOKEN_PROGRAM_ID} = splToken;
        // const anotherKeypair = Keypair.generate();
        try {
            const fromTokenAccount = await splToken.getOrCreateAssociatedTokenAccount(
                connection,
                wallet.payer,
                mintPublicKey,
                publicKey
            );

            const destPublicKey = new web3.PublicKey(props.receiverWalletAddress);
            // Get the derived address of the destination wallet which will hold the custom token
            const associatedDestinationTokenAddr = await splToken.getOrCreateAssociatedTokenAccount(
                connection,
                wallet.payer,
                mintPublicKey,
                destPublicKey
            );

            const receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr.address);

            const instructions = [];

            instructions.push(
                splToken.createTransferInstruction(
                    fromTokenAccount.address,
                    associatedDestinationTokenAddr.address,
                    publicKey,
                    1,
                    [],
                    TOKEN_PROGRAM_ID
                )
            );
            const amountToSend = props.amount * 1000000;

            let transaction = new web3.Transaction().add(splToken.createTransferInstruction(
                fromTokenAccount.address,
                associatedDestinationTokenAddr.address,
                publicKey,
                amountToSend,
                [],
                TOKEN_PROGRAM_ID
            ))

            const signature = await sendTransaction(transaction, connection);
            notify('info', 'Transaction sent:', signature);

            await connection.confirmTransaction(signature, 'processed').then(props.onSuccess);
            notify('success', 'Transaction successful!', signature);
        } catch (error) {
            //notify('error', `Transaction failed!`, {"description": error?.message} );
            props.onFailure();
            return;
        }

        //TODO: Here for reference.
        //
        // Sign transaction, broadcast, and confirm
        // let signature = await web3.sendAndConfirmTransaction(
        //     connection,
        //     transaction,
        //     [anotherKeypair]
        // );
        // console.log("SIGNATURE", signature);
        // console.log("SUCCESS");

        // const transaction = new web3.Transaction().add(...instructions);
        // transaction.feePayer = publicKey;
        // transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        // console.log("Transaction", transaction);
        // // transaction.signatures = [{publicKey: publicKey}];
        //
        // const transactionSignature = await connection.sendRawTransaction(
        //     transaction.serialize(),
        //     {skipPreflight: true}
        // );
        // console.log("TransactionSignature", transactionSignature);
        //
        // await connection.confirmTransaction(transactionSignature);
    }


    const initiateSOLPayment = useCallback(async () => {
        if (!publicKey) throw new WalletNotConnectedError();
        await wait(1000)
        const toAddress = new PublicKey(props.receiverWalletAddress);
        const lamportsToSend = props.amount * 1000000000;

        try{
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: toAddress,
                    lamports: lamportsToSend,
                })
            );

            const signature = await sendTransaction(transaction, connection);
            notify('info', 'Transaction sent:', signature);

            await connection.confirmTransaction(signature, 'processed').then(props.onSuccess);
            notify('success', 'Transaction successful!', signature);
            // setTransactionSignature(signature);
            // handleOpenSuccessModal();

            await connection.confirmTransaction(signature, 'processed');
        } catch (error) {
            //notify('error', `Transaction failed!`, {"description": error?.message} );
            props.onFailure();
            return;
        }


    }, [publicKey, sendTransaction, connection, paymentDetails]);

    const childDropDownValue = (name, id) => {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() + '-' + id
    }

    // formValues.public_address.toString().slice(0,4) + '...' + formValues.public_address.toString().slice((formValues.public_address.length - 4), formValues.public_address.length)

    return (
        <div style={{paddingTop: '20px', paddingBottom: '20px', padding: '30px'}}>
            <div className={classes.leftUpperDiv}>
                <Typography variant={'h6'} style={{fontFamily: "Montserrat",
                    fontStyle: "bold",
                    fontWeight: "700",
                    lineHeight: "22px",
                    letterSpacing: "0px",
                    textAlign: "center",
                    color: "#000000",
                    paddingTop: "10px",
                    paddingLeft: "30px",
                    float: 'left'
                }}>
                    <img src={SolPayMark} />
                </Typography>
                <div style={{float: 'right', paddingRight: '40px'}}>
                    <IconButton
                        edge="start"
                        align="right"
                        color="inherit"
                        aria-label="Close"
                        style={{ padding: 8 }}
                    >
                        <CloseIcon onClick={props.onClose} />
                    </IconButton>
                </div>
            </div>
            <Divider />
            <div style={{ padding: '20px'}}>
                <form>
                    <div>
                        <span style={{fontWeight:400, fontSize: '16px'}}> Send {props.childName}</span>
                        <span  style={{marginLeft: 300}}>  <AttachMoneyIcon style={{color:'#0079D9', top: 5, position: 'relative'}}/>{props.amount} USDC</span>
                        <div  style={{marginTop: 30}}>Address: &nbsp;{props.receiverWalletAddress.substring(0,6)}...{props.receiverWalletAddress.substring(props.receiverWalletAddress.length-3, props.receiverWalletAddress.length)}
                        </div>
                        <div style={{marginTop:30}}><AddCard style={{color:'#0079D9', top: 5, position: 'relative'}}/>
                            <span style={{marginLeft:30}}>Select your Solana Wallet</span></div>
                        <div style={{marginTop: 30}}><WalletMultiButton color={'inherit'}/></div>
                    </div>

                    &nbsp;
                    <Button style={{background: '#0079D9', marginTop: '10px', float: 'right'}} onClick={initiateUSDCPayment}><Typography style={{color: '#ffffff'}}>Send USDC</Typography></Button>
                </form>
            </div>
        </div>
    );
}

export default PaymentContent;
