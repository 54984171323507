import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import KidfolioContext from "../store/KidfolioContext";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { getChildProfile } from '../utils/Util';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    heading: {
        display: 'flex',
        fontWeight: 700,
        fontSize: 18,
    },
    closeBtn: {
        position: 'absolute',
        right: 5,
        top: 5,
    },
    item: {
        width: 300,
        display:'flex', 
        flexDirection: 'row',
        marginBottom: 20,
        alignItems:'center',
        cursor: 'pointer',
        padding: 10,
        borderRadius: 10,
        '&:hover': {
            background: '#D0FFF1',
        },
    }
});

function SelectChildDialog(props) {

    const classes = useStyles();
    const ctx = useContext(KidfolioContext);

    const getChildPhoto = (benId) => {
        return getChildProfile(ctx.beneficiaryImageUrl, benId);
    }

    return (
        <Dialog
            className={classes.root}
            maxWidth="xs"
            open={props.visible}
        >
            <DialogContent>
                <div>
                    <div className={classes.closeBtn} >
                        <IconButton
                            edge="start"
                            align="right"
                            color="inherit"
                            aria-label="Close"
                            style={{ padding: 8 }}
                        >
                            <CloseIcon onClick={props.handleClose} />
                        </IconButton>
                    </div>
                    <div className={classes.heading}>Select Child</div>
                    <div style={{display:'flex', flexDirection:'column', marginTop: 40, height: 350, overflow: 'auto'}}>
                    { ctx.beneficiariesData.data.beneficiaries.slice().map((child, index) => (
                        <div  key={index} className={classes.item} onClick={ () => props.childSelected(child)}>
                            <div style={{}}>
                                <img src={getChildPhoto(child.id)} style={{height: '55px', width: '55px', float: "left", borderRadius: "15px"}}/>
                            </div>
                            <div  style={{paddingLeft: 20}}>
                                {child.profile.fullName}
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default SelectChildDialog;
