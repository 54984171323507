import { Card, CardMedia, makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import FetchImage from "./FetchImage";
import React, { useContext, useEffect, useState } from "react";
import KidfolioContext from "../store/KidfolioContext";


const useStyles = makeStyles({
    card: {
        backgroundColor: '#FEA621',
        width: '184px',
        height: '210px',
        marginRight: 40,
        borderRadius: '30px',
        transformStyle: "preserve-3d",
        boxShadow: "0px 4.478689670562744px 15.675413131713867px 6.718034744262695px #FFFFFF33 inset, -22.393447875976562px 105.24920654296875px 161.23281860351562px 0px #FFFFFF33",
        cursor: 'pointer',
        border: 'none'
    },
    cardLayout: {
        position: "relative",
        left: "0",
        right: "0",
        height: "50%"
    }
})
export default function ChildCard(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const ctx = useContext(KidfolioContext);
    const [childPhoto, setChildPhoto] = useState(null);

    // console.log(props.childDetail);
    // console.log(dayjs(props.childDetail.profile.birthDate).format("MMM DD, YYYY"));


    const handleClickEvent = (x) => {
        // console.log("Click handler",x, "props", props.childDetail.id);
        //navigate("/child");
        navigate({
            pathname: `/child/${props.childDetail.id}`,
        });
    }

    const capitalizeFirstLetter = (string) => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : "";
    }

    return (
        <Card variant="outlined" className={classes.card} onClick={(e) => { handleClickEvent(e.target) }} style={{ backgroundColor: props.backgroundColor, color: props.textColor }}>
            <div className={classes.cardLayout} style={{ top: "0" }}>
                <img src={props.childPhoto} style={{ height: '75px', width: '75px', float: "left", marginLeft: "25px", marginTop: "25px", borderRadius: "15px" }} />
            </div>
            <div className={classes.cardLayout} style={{ bottom: "0" }}>
                <h3 style={{
                    verticalAlign: 'middle', fontFamily: "Montserrat",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "22px",
                    letterSpacing: "0px",
                    textAlign: "left",
                    paddingLeft: "25px", marginBottom: "8px"
                }}>{capitalizeFirstLetter(props.childDetail.profile?.fullName)}</h3>
                <h5 style={{
                    verticalAlign: 'bottom', fontFamily: "Montserrat",
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "16px",
                    letterSpacing: "0px",
                    textAlign: "left",
                    paddingLeft: "25px", marginTop: "0"
                }}>{dayjs(props.childDetail.profile.birthDate).format("MMM DD, YYYY")}</h5>
            </div>
        </Card>
    )
}

