import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useContext, useEffect, useState } from "react";
import KidfolioContext from "../store/KidfolioContext";
import { makeStyles, Button } from "@material-ui/core";
import EventDetailsCard from "./EventDetailsCard";
import isPast from 'date-fns/isPast'
import EventsDashboardImg from "../assets/EventsDashboardImg.svg";
import AddIcon from '@mui/icons-material/Add';
import AddEventDialog from '../components/AddEventDialog'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles({
    noEvents: {
        background: "#D0FFF1",
        boxShadow: "-10px 48.5967px 140px rgba(126, 123, 160, 0.2)",
        backdropFilter: "blur(19px)",
        borderRadius: "34px",
        height: '420px',
        maxWidth: '400px',
        padding: 30,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 250,
        margin: 'auto'
    },
    noPastEvents: {
        background: "#FADFE2",
        boxShadow: "-10px 48.5967px 140px rgba(126, 123, 160, 0.2)",
        backdropFilter: "blur(19px)",
        borderRadius: "34px",
        height: '420px',
        maxWidth: '400px',
        padding: 30,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 250,
        margin: 'auto'
    },
    tabPanel: {
        marginLeft: '-20px',
        marginRight: '-20px',
        '& > div': {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    tabPanelContent: {
        margin: '24px 24px 24px 24px',
        cursor: 'pointer'
    }
});

export default function eventsTabComponent(props) {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const ctx = useContext(KidfolioContext);
    const [addEvent, setAddEvent] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addNewEvent = () => {
        setAddEvent(true);
    }

    const handleClose = () => {
        setAddEvent(false);
    }

    const isEventPresent = (type) => {
        let present = false;
        switch (type) {
            case 'PAST':
                present = ctx.childEventData.some(item => {
                    const date = new Date(item.post.dateTime);
                    return isPast(date);
                })
                break;
            case 'UPCOMING':
                present = ctx.childEventData.some(item => {
                    const date = new Date(item.post.dateTime);
                    return !isPast(date);
                })
                break;
            default:
                break;
        }
        return present;
    }

    const getEvents = (type) => {
        let events = [];
        switch (type) {
            case 'PAST':
                events = ctx.childEventData.filter(item => {
                    const date = new Date(item.post.dateTime);
                    return isPast(date);
                })
                break;
            case 'UPCOMING':
                events = ctx.childEventData.filter(item => {
                    const date = new Date(item.post.dateTime);
                    return !isPast(date);
                })
                break;
            default:
                break;
        }
        return events;
    }

    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} centered>
                    <Tab label="Upcoming Events" {...a11yProps(0)} />
                    <Tab label="Past Events" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel className={classes.tabPanel} value={value} index={0} >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                        (ctx.childEventData && isEventPresent('UPCOMING')) ?
                            getEvents('UPCOMING').map((pastEventData, index) => (
                                (pastEventData.post.beneficiaryId === props.childId) ?
                                    <div key={index} className={classes.tabPanelContent} >
                                        <EventDetailsCard childDetails={pastEventData} />
                                    </div> : <div key={index}></div>
                            )) :
                            <div className={classes.noEvents}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                                    <img src={EventsDashboardImg} style={{
                                        height: '85px', width: '145px'
                                    }} />
                                    <div style={{
                                        fontSize: 21, lineHeight: '26px', marginTop: 20, marginBottom: 40, width: 175
                                    }}>Add an event to celebrate</div>
                                    <div style={{ width: 270, marginBottom: 40 }}>and begin collecting gifts from friends & family.</div>
                                    <div>
                                        <Button
                                            variant="contained"
                                            style={{
                                                height: 32,
                                                backgroundColor: "#0096FF",
                                                color: 'white'
                                            }}
                                            onClick={addNewEvent}
                                            startIcon={<AddIcon />}>
                                            New Event
                                        </Button>
                                    </div>
                                </div>
                                {addEvent ? <AddEventDialog childName={props.childData.profile.fullName} childId={props.childId} visible={addEvent} handleClose={handleClose} /> : <div />}
                            </div>
                    }
                </div>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={1}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                        (ctx.childEventData && isEventPresent('PAST')) ?
                            getEvents('PAST').map((upcomingEventData, index) => (
                                (upcomingEventData.post.beneficiaryId === props.childId) ?
                                    <div key={index} className={classes.tabPanelContent} >
                                        <EventDetailsCard childDetails={upcomingEventData} />
                                    </div> : <div key={index}></div>
                            )) :
                            <div className={classes.noPastEvents}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                                    <div style={{
                                        fontSize: 21, lineHeight: '26px', marginTop: 20, marginBottom: 40, width: 175
                                    }}>You dont have any past events.</div>
                                    <div style={{ width: 270, marginBottom: 60 }}>But you can start creating new events and begin collecting gifts from friends & family.</div>
                                    <div>
                                        <Button
                                            variant="contained"
                                            style={{
                                                height: 32,
                                                backgroundColor: "#0096FF",
                                                color: 'white'
                                            }}
                                            onClick={addNewEvent}
                                            startIcon={<AddIcon />}>
                                            New Event
                                        </Button>
                                    </div>
                                </div>
                                {addEvent ? <AddEventDialog childName={props.childData.profile.fullName} childId={props.childId} visible={addEvent} handleClose={handleClose} /> : <div />}
                            </div>
                    }
                </div>
            </TabPanel>
        </div>
    );
}
