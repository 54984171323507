import { Box, Button, Link, TextField } from "@material-ui/core";
import { useContext, useState } from "react";
import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    signInWithPopup
} from "firebase/auth";
import { auth } from "../../firebase";
import { useNotify } from "../../utils/Notify";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { googleProvider } from "../../config/authGoogleProvider";
import { facebookProvider } from "../../config/authFacebookProvider";
import { appleProvider } from "../../config/authAppleProvider";
import axios from "axios";
import KidfolioContext from "../../store/KidfolioContext";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import GoogleIcon from "../../assets/GoogleIcon.svg"
import AppleIcon from "../../assets/AppleIcon.svg"
import EmailIcon from "../../assets/EmailIcon.svg"
import FacebookIcon from "../../assets/FacebookIcon.svg"

const Signup = ({ handleClose }) => {
    const notify = useNotify();
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_APP_BASE_URL;
    const [isProcessing, setIsProcessing] = useState(false);
    const { setRegisterId } = useContext(KidfolioContext);
    // let isProcessing = false;

    const handleGoogleLogin = async () => {
        setIsProcessing(true);
        try {
            signInWithPopup(auth, googleProvider)
                .then(async (result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    notify(
                        "success", `Sign Up Successful. Welcome ${result.user.email}`, ""
                    );
                    const userResult = await axios.post(
                        `${baseURL}/userApi/users`, {
                        "fullName": user.email,
                        "phoneNumber": '',
                        "emailId": user.email,
                        "location": {
                            "city": "",
                            "state": ""
                        }
                    },
                        {
                            headers: {
                                "x-firebase-auth": user.accessToken
                            }
                        }
                    ).then(async (response) => {
                        setIsProcessing(false);
                        await setRegisterId(response.data.id);
                        navigate(
                            '/addUserDetails', {
                            state: {
                                email: user.email,
                                fullName: user.email,
                                token: user.accessToken
                            }
                        }
                        )
                    })
                    // navigate(
                    //     '/addUserDetails', {
                    //     state: {
                    //         email: result.user.email,
                    //         fullName: result.user.email
                    //     }
                    // }
                    // )
                }).catch((error) => {
                    setIsProcessing(false);
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...
                });
        } catch (error) {
            setIsProcessing(false);
            notify(
                "error", error.message, ""
            );
            return;
        }
    }

    const handleFacebookLogin = async () => {
        try {
            signInWithPopup(auth, facebookProvider)
                .then((result) => {
                    // The signed-in user info.
                    const user = result.user;

                    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                    const credential = FacebookAuthProvider.credentialFromResult(result);
                    const accessToken = credential.accessToken;

                    // ...
                })
                .catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.email;
                    // The AuthCredential type that was used.
                    const credential = FacebookAuthProvider.credentialFromError(error);
                    notify(
                        "error", error.message, ""
                    );

                    // ...
                });
        } catch (error) {
            notify(
                "error", error.message, ""
            );
            return;
        }
    }

    const handleAppleLogin = async () => {
        try {
            signInWithPopup(auth, appleProvider)
                .then((result) => {
                    // The signed-in user info.
                    const user = result.user;

                    // Apple credential
                    const credential = OAuthProvider.credentialFromResult(result);
                    const accessToken = credential.accessToken;
                    const idToken = credential.idToken;

                    // ...
                })
                .catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.email;
                    // The credential that was used.
                    const credential = OAuthProvider.credentialFromError(error);
                    notify(
                        "error", error.message, ""
                    );
                    return;

                    // ...
                });
        } catch (error) {
            notify(
                "error", error.message, ""
            );
            return;
        }
    }

    // const handleSignUp = async () => {
    //   if (password !== confirmPassword) {
    //     notify("error", "Passwords do not match", "" )
    //     return;
    //   }
    //
    //   try {
    //     const result = await createUserWithEmailAndPassword(
    //       auth,
    //       email,
    //       password
    //     );
    //     notify("success", `Sign Up Successful. Welcome ${result.user.email}`, "")
    //     // setAlert({
    //     //   open: true,
    //     //   message: `Sign Up Successful. Welcome ${result.user.email}`,
    //     //   type: "success",
    //     // });
    //
    //     handleClose();
    //   } catch (error) {
    //     notify("error", error.message, "")
    //     // setAlert({
    //     //   open: true,
    //     //   message: error.message,
    //     //   type: "error",
    //     // });
    //     return;
    //   }
    // };

    return (
        <>
            {(!isProcessing) ?
                <Box
                    p={2}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        width: '100%'
                    }}
                >

                    <div style={{
                        display: 'flex', alignItems: 'center',
                        height: '36px',
                        border: 'solid 1px gray',
                        padding: '10px',
                        boxSizing: 'border-box',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        background: '#3BFFBE'
                    }}
                        onClick={() => navigate({
                            pathname: `/signUpWithEmail`
                        })}>
                        <div style={{
                            width: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>  <img style={{ width: "18px" }} src={EmailIcon} /> </div>
                        <div style={{ fontSize: 15 }}> Sign-up with Email </div>
                    </div>

                    <div style={{
                        display: 'flex', alignItems: 'center',
                        height: '36px',
                        border: 'solid 1px gray',
                        padding: '10px',
                        boxSizing: 'border-box',
                        borderRadius: '6px',
                        cursor: 'pointer',
                    }}
                        onClick={handleGoogleLogin}>
                        <div style={{
                            width: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>  <img style={{ width: "18px" }} src={GoogleIcon} /> </div>
                        <div style={{ fontSize: 15 }}> Sign-up with Google </div>
                    </div>

                    <div style={{
                        display: 'flex', alignItems: 'center',
                        height: '36px',
                        border: 'solid 1px gray',
                        padding: '10px',
                        boxSizing: 'border-box',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        background: 'black',
                        color: 'white'
                    }}
                        onClick={handleAppleLogin}>
                        <div style={{
                            width: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'black'
                        }}>  <img style={{ width: "16px" }} src={AppleIcon} /> </div>
                        <div style={{ fontSize: 15 }}> Sign-up with Apple </div>
                    </div>

                    <div style={{
                        display: 'flex', alignItems: 'center',
                        height: '36px',
                        border: 'solid 1px gray',
                        padding: '10px',
                        boxSizing: 'border-box',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        background: '#4F7CBF',
                        color: 'white'
                    }}
                        onClick={handleFacebookLogin}>
                        <div style={{
                            width: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>  <img style={{ width: "14px" }} src={FacebookIcon} /> </div>
                        <div style={{ fontSize: 15 }}> Sign-up with Facebook </div>
                    </div>
                    <div style={{ fontSize: 14, marginTop: 20, textAlign: 'center' }}>By creating an account, you accept our Service Terms and Privacy Policy</div>
                </Box >
                :
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>}
        </>
    );

};

export default Signup;
