import { Box, Button, TextField, makeStyles } from "@material-ui/core";
import { useContext, useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useNotify } from "../../utils/Notify";
import * as React from "react";
import axios from "axios";
import KidfolioContext from "../../store/KidfolioContext";
import { useNavigate } from "react-router-dom";
import { wait } from "@testing-library/user-event/dist/utils";
import LoadingButton from "@mui/lab/LoadingButton";

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';

import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles({
    inputClass: {
        '& label': {
            lineHeight: 0.7,
        },
        '& input': {
            fontSize: '12px'
        },
    },
});

function SignUpWithEmailForm() {
    const classes = useStyles();
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const kidfolioCtx = useContext(KidfolioContext);
    const notify = useNotify();
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_APP_BASE_URL;
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async () => {
        setIsProcessing(true);
        // if (password !== confirmPassword) {
        //     notify("error", "Passwords do not match", "")
        //     return;
        // }

        try {
            const result = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            ).then(async res => {
                // console.log("res", res);
                // console.log("res.user.accessToken", res.user.accessToken);
                // console.log("Kidfolio Ctx sign up INSIDE");
                // console.log(kidfolioCtx.user);
                // console.log(kidfolioCtx.firebaseToken);
                // console.log("Kidfolio Ctx sign up INSIDE END");
                const userResult = await axios.post(
                    `${baseURL}/userApi/users`, {
                    "fullName": fullName,
                    "phoneNumber": '',
                    "emailId": email,
                    "location": {
                        "city": "",
                        "state": ""
                    }
                },
                    {
                        headers: {
                            "x-firebase-auth": res.user.accessToken
                        }
                    }
                ).then(async (response) => {
                    await kidfolioCtx.setRegisterId(response.data.id);
                    navigate(
                        '/addUserDetails', {
                        state: {
                            email: email,
                            fullName: fullName
                        }
                    }
                    )
                })
            });

            // notify("success", `Sign Up Successful. Welcome ${result.user.email}`, "")
            //TODO: Change this flow to route to /verifyEmail once smtp server is setup.
            // const userResult = await axios.post(
            //     `${baseURL}/userApi/users`, {
            //     "fullName": "",
            //     "phoneNumber": '',
            //     "emailId": email,
            //     "location": {
            //         "city": "",
            //         "state": ""
            //     }
            // },
            //     {
            //         headers: {
            //             "x-firebase-auth": kidfolioCtx.firebaseToken
            //         }
            //     }
            // ).then(async (res) => {
            //     console.log("Register ID", res.data.id);
            //     await kidfolioCtx.setRegisterId(res.data.id);
            //     navigate(
            //         '/addUserDetails', {
            //             state: {
            //                 email: email,
            //                 fullName: fullName
            //             }
            //         }
            //     )
            // })
            setIsProcessing(false);
        } catch (error) {
            setIsProcessing(false);
            notify("error", error.message, "")
            return;
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box
            p={2}
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
            }}
        >
            <TextField
                className={classes.inputClass}
                variant="outlined"
                type="text"
                label={<span style={{ fontSize: 12 }}>
                    Full Name
                </span>}
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                style={{ width: '100%', fontSize: '12px' }}
                size="small"
            />
            <TextField
                className={classes.inputClass}
                variant="outlined"
                type="email"
                label={<span style={{ fontSize: 12 }}>
                    Email
                </span>}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: '100%', fontSize: '12px' }}
                size="small"
            />

            <FormControl variant="outlined">
                <InputLabel style={{ fontSize: 12, lineHeight: 0.8 }} htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                    className={classes.inputClass}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    style={{ height: 36, fontSize: 12 }}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label={<span style={{ fontSize: 12 }}>
                        Password
                    </span>}
                />
            </FormControl>


            {(!isProcessing) ?
                <Button
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: "#0079D9", width: '100%', color: "#ffffff", height: '36px', fontSize: 15 }}
                    onClick={handleSubmit}
                    disabled={!email || !fullName || !password}
                >
                    Sign up
                </Button>
                :
                <LoadingButton loading loadingIndicator="Processing..." variant="contained"
                    style={{ width: '100%', height: '36px', fontSize: 15 }}>
                    Processing...
                </LoadingButton>}
            <div style={{ fontSize: 14, marginTop: 20, textAlign: 'center' }}>By creating an account, you accept our Service Terms and Privacy Policy</div>
        </Box>
    )
}

export default SignUpWithEmailForm;