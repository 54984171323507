import { Divider, Typography } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import ProgressBar from "../../Common/ProgressBar";
import GiftIconWallet from "../../../assets/GiftIconWallet.svg";
import Link from "@mui/material/Link";
import { useContext, useEffect, useState } from "react";
import KidfolioContext from "../../../store/KidfolioContext";

function ChildGiftList(props) {
    const ctx = useContext(KidfolioContext);
    const beneficiaryWalletAddress = props.beneficiaryWalletAddress;
    const [giftList, setGiftList] = useState(null);
    const [totalGiftAmount, setTotalGiftAmount] = useState(0);
    const [giftsTarget, setGiftsTarget] = useState([]);

    useEffect(() => {
        let allContributionsList = [];
        let giftsTargetSet = [];
        if (ctx.childEventData) {
            ctx.childEventData.forEach((eventList, index) => {
                if (eventList.post.giftItems) {
                    eventList.post.giftItems.forEach((item, index) => {
                        if (eventList?.post?.targetAmounts?.length > 0 && eventList?.post?.targetAmounts.length >= index) {
                            giftsTargetSet.push({ item: item, target: eventList.post?.targetAmounts[index] })
                        }
                        else {
                            giftsTargetSet.push({ item: item, target: 0 })
                        }
                    })
                }
                if (eventList.noOfContributions > 0) {
                    eventList.post?.contributions?.forEach((contributions, index) => {
                        if (contributions?.giftItems) {
                            contributions.giftItems.forEach((it, index) => {
                                allContributionsList.push(contributions.giftItems);
                            })
                        }
                    });
                } else {
                    eventList.post?.giftItems?.forEach((it, index) => {
                        allContributionsList.push([{ amount: 0, title: it }])
                    })
                }
            })
            giftsTargetSet = giftsTargetSet.reduce((unique, o) => {
                if (!unique.some(obj => obj.item === o.item && obj.target === o.target)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            setGiftsTarget(giftsTargetSet);
            const temp = [];
            allContributionsList ? allContributionsList.flatMap((it) => {
                return it.forEach((innerIt, index) => {
                    temp.push(innerIt);
                })
            }) : [];
            const finList = temp ? temp.reduce((finList, item) => (
                {
                    ...finList,
                    [item.title]: [...(finList[item.title] || []), item]
                }), {}) : [];
            const giftsList = [];
            for (let title in finList) {
                if (finList.hasOwnProperty(title)) {
                    let total = 0;
                    finList[title].forEach((value, index) => {
                        total = total + Math.round((value.amount + Number.EPSILON) * 100) / 100;
                    })
                    const target = giftsTargetSet[giftsTargetSet.findIndex(function (e) { return e.item === title; })]
                    if (target !== undefined) {
                        giftsList.push({ item: title, amount: total, target: target.target });
                    } else {
                        giftsList.push({ item: title, amount: total, target: 'NA' });
                    }
                }
            }
            setGiftList(giftsList);
            let totalGift = 0;
            giftsList.forEach((gift) => {
                totalGift += gift.amount
            });
            setTotalGiftAmount(totalGift);
        }
    }, [])

    return (
        <Paper sx={{
            background: "white",
            width: '314px',
            padding: '15px',
            textAlign: 'center',
            minHeight: '75vh',
            height: '75vh',
            boxShadow: 'none',
            borderRadius: '25px',
            overflow: 'scroll'
        }}>
            <div style={{ minHeight: '35vh' }}>
                <Typography variant={'h6'} style={{
                    fontFamily: "Montserrat",
                    fontStyle: "bold",
                    fontWeight: "700",
                    lineHeight: "22px",
                    letterSpacing: "0px",
                    textAlign: "center",
                    color: "#000000",
                    paddingTop: "20px",
                    paddingBottom: "20px"
                }}>
                    Balance
                </Typography>
                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '1em' }}>
                    <Paper style={{ width: '210px', height: '110px', alignItems: 'center', borderRadius: '1em', backgroundColor: '#4BFFD9' }}>
                        <img src={GiftIconWallet} width={'20px'} height={'24px'} style={{ marginTop: '12px' }} />
                        <div style={{
                            fontStyle: "normal",
                            fontWeight: "325",
                            fontSize: 14,
                            padding: 10,
                            color: "#000000"
                        }}>Total Gift Amount</div>
                        <div style={{
                            fontStyle: "bold",
                            fontWeight: "350",
                            fontSize: 18,
                            color: "#000000"
                        }}>${totalGiftAmount}</div>
                    </Paper>
                </div>
                <div style={{ justifyContent: 'center', marginTop: 30, marginBottom: 20 }}>
                    <Typography variant={'h6'} style={{
                        fontStyle: "bold",
                        fontWeight: "700",
                        fontSize: 14,
                        lineHeight: "22px",
                        letterSpacing: "0px",
                        color: "#000000",
                        paddingTop: "1em"
                    }}>
                        Wallet Address
                    </Typography>
                    <Typography variant={'h6'} style={{
                        fontWeight: "400",
                        fontSize: '14px',
                        color: "#000000",
                        paddingTop: "1em"
                    }}>
                        {(beneficiaryWalletAddress !== 'NA') ? beneficiaryWalletAddress.slice(0, 2) + '*****************' + beneficiaryWalletAddress.slice(beneficiaryWalletAddress.length - 2) : 'NA'}
                    </Typography>
                    {/*<Typography variant={'h6'} style={{fontFamily: "Montserrat",*/}
                    {/*    fontStyle: "bold",*/}
                    {/*    fontWeight: "500",*/}
                    {/*    lineHeight: "22px",*/}
                    {/*    fontSize: '1em',*/}
                    {/*    color: "#000000",*/}
                    {/*    paddingTop: "1em"*/}
                    {/*}}>*/}
                    {/*    /!*TODO: Connect to appropriate component/create component for below functionalities  *!/*/}
                    {/*    /!*<Link>Edit Address</Link>&nbsp;/&nbsp;*!/*/}
                    {/*    <Link>Add Fund</Link>*/}
                    {/*</Typography>*/}
                </div>
            </div>
            <Divider />
            <div style={{ minHeight: '35vh', marginLeft: '1em', overflow: "scroll" }}>
                <Typography variant={'h6'} style={{
                    fontStyle: "bold",
                    fontSize: 18,
                    fontWeight: "700",
                    letterSpacing: "0px",
                    textAlign: "left",
                    color: "#000000",
                    paddingTop: 20,
                    paddingBottom: 20
                }}>
                    Gift List
                </Typography>
                <div style={{ overflow: "scroll" }}>
                    {giftList ? giftList.map((gift, index) => (
                        <div key={index} style={{ marginBottom: 20 }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant={'h6'} style={{
                                    fontWeight: "500",
                                    width: "50%",
                                    textAlign: "left",
                                    color: "#273240",
                                    paddingTop: "10px",
                                    fontSize: 13
                                }}>
                                    {gift.item}
                                </Typography>
                                <Typography variant={'h6'} style={{
                                    fontWeight: "600",
                                    textAlign: "right",
                                    color: "#273240",
                                    paddingTop: "10px",
                                    fontSize: 13
                                }}>
                                    {`$${gift.amount}/$${(gift.target) !== 'NA' ? gift.target : '--'}`}
                                </Typography>
                            </div>
                            <ProgressBar bgcolor={'red'} progress={(parseFloat(gift.amount) / parseFloat(gift.target)) * 100} height={10} />
                        </div>
                    )) : <div />}
                    {/*TODO: Connect to appropriate component/create component for below functionalities  */}
                    {/*<Typography variant={'h6'} style={{fontFamily: "Montserrat",*/}
                    {/*    fontStyle: "bold",*/}
                    {/*    fontWeight: "500",*/}
                    {/*    lineHeight: "22px",*/}
                    {/*    fontSize: '1em',*/}
                    {/*    color: "#000000",*/}
                    {/*    paddingTop: "1em"*/}
                    {/*}}> <Link>Add Gift</Link>*/}
                    {/*</Typography>*/}
                </div>
            </div>
        </Paper>
    );
}

export default ChildGiftList;