import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import Button from "@material-ui/core/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Dialog, FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import AddProfileImage from "../../assets/AddProfileImage.svg";
import KidfolioContext from "../../store/KidfolioContext";
import LogoutIcon from "../../assets/Logout.svg";
import axios from "axios";
import 'react-image-crop/dist/ReactCrop.css'
import Box from "@mui/material/Box";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useNotify } from "../../utils/Notify";
import { getSolanaVendorName } from "../../utils/Util";
import ImageCrop from "../Common/ImageCrop";

const styles = makeStyles({
    secondaryColor: {
        color: grey[700]
    },
    heading: {
        fontSize: 18,
        fontWeight: 600
    },
    subHeading: {
        fontSize: 16,
        fontWeight: 500
    },
    noPadding: {
        paddingTop: '0px !important',
        paddingLeft: 0,
        paddingRight: 0
    },
    padding: {
        padding: 0
    },
    mainHeader: {
        alignItems: "center"
    },
    mainContent: {
        padding: 15
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    },
    inputClass: {
        '& label': {
            lineHeight: 0.7,
        },
        '& input': {
            fontSize: '12px'
        },
    },
})

function UserProfile(props) {
    const ctx = useContext(KidfolioContext);
    const userDetails = ctx.loggedInUserDetails;
    const updateUserDetails = ctx.updateUserDetails;
    const [openDialog, setOpenDialog] = useState(true);
    const [firstName, setFirstName] = useState(userDetails?.profile?.fullName ? userDetails.profile.fullName : '');
    const [email, setEmail] = useState(userDetails?.profile?.emailId ? userDetails.profile.emailId : '');
    const [phoneNumber, setPhoneNumber] = useState(userDetails?.profile?.phoneNumber ? userDetails.profile.phoneNumber : '');
    const getSolanaAddress = () => {
        if (userDetails.paymentOptions) {
            const solanaOption = userDetails.paymentOptions.find(item => {
                return item.vendorName === getSolanaVendorName(ctx.paymentOption);
            })
            if (solanaOption) {
                return solanaOption.identifier;
            }
        }
        return "";
    }
    const [solanaAddress, setSolanaAddress] = useState(getSolanaAddress());
    const [userDOB, setUserDOB] = useState(userDetails?.profile?.birthDate ? userDetails.profile.birthDate : null);
    // const [gender, setGender] = useState(userDetails?.profile?.gender ? userDetails.profile.gender : 'Undeclared');
    const [userSolAdd, setuserSolAdd] = useState(null);
    const [firstNameRequiredError, setFirstNameRequiredError] = useState(false);
    const [emailRequiredError, setEmailRequiredError] = useState(false);
    const [phoneNumberRequiredError, setPhoneNumberRequiredError] = useState(false);
    const [userDOBRequiredError, setUserDOBRequiredError] = useState(false);
    // const [genderRequiredError, setGenderRequiredError] = useState(false);
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(ctx.userProfileImageUrl || AddProfileImage);
    const [file, setFile] = useState('');
    const [oldImagePreviewUrl, setOldImagePreviewUrl] = useState(AddProfileImage);
    const [imageUpdated, setImageUpdated] = useState(false);
    const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
    const navigate = useNavigate();
    const notify = useNotify();
    const imageComponent = useRef(null);
    const classes = styles();
    const baseURL = process.env.REACT_APP_APP_BASE_URL;



    const handleClose = () => {
        setImageUploadDialogOpen(false);
    }

    const logOut = async () => {
        await signOut(auth);
        navigate({
            pathname: `/login`,
        });
        ctx.avictData();
    };

    //TODO: Enable this if we need animation for photo upload.
    //
    // const addUploadPreviewIcon = value => {
    //     setOldImagePreviewUrl(imagePreviewUrl);
    //     setImagePreviewUrl(UploadIcon);
    // }
    //
    // const removeUploadPreviewIcon = value => {
    //     // setOldImagePreviewUrl(imagePreviewUrl);
    //     setImagePreviewUrl(oldImagePreviewUrl);
    // }

    const photoUpload = e => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            setFile(file);
            setImagePreviewUrl(reader.result);
            setImageUpdated(true);
            setImageUploadDialogOpen(true);
        }
        reader.readAsDataURL(file);
    }

    async function updateUserProfile() {
        let validationFailed = false;
        let imageId = userDetails.profile.imageId;

        if (!firstName || !firstName.trim()) {
            setFirstNameRequiredError(true);
            validationFailed = true;
        }
        if (!userDOB) {
            setUserDOBRequiredError(true);
            validationFailed = true;
        }
        // if (!phoneNumber) {
        //     setPhoneNumberRequiredError(true);
        //     validationFailed = true;
        // }
        if (!email) {
            setEmailRequiredError(true);
            validationFailed = true;
        }
        // if (!gender) {
        //     setGenderRequiredError(true);
        //     validationFailed = true;
        // }
        if (validationFailed) return;

        if (imageUpdated) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('content-type', 'multipart/form-data');
            const response = await axios.post(
                `${baseURL}/resourceApi/resources`, formData,
                {
                    headers: {
                        "x-firebase-auth": ctx.firebaseToken,
                        "content-type": 'multipart/form-data'
                    }
                }
            )
            imageId = response.data.id;
            setImageUpdated(false);
        }

        setUpdateInProgress(true);

        const userDetail = {
            fullName: firstName,
            phoneNumber: phoneNumber,
            emailId: email,
            location: userDetails.location,
            birthDate: userDOB,
            // gender: gender,
            imageId: imageId
        }
        await updateUserDetails(userDetail);
        if (getSolanaAddress() !== solanaAddress) {
            const solanaVendorName = getSolanaVendorName(ctx.paymentOption);
            let paymentObj = [
                {
                    "vendorName": solanaVendorName,
                    "identifier": solanaAddress
                }
            ]
            const userPaymentOptions = JSON.parse(JSON.stringify(userDetails.paymentOptions || []));
            if (userPaymentOptions && userPaymentOptions.length) {

                const isPresent = userPaymentOptions.some(item => {
                    return (item.vendorName === solanaVendorName);
                })
                if (isPresent) {
                    paymentObj = userPaymentOptions.map(item => {
                        if (item.vendorName === solanaVendorName) {
                            item['identifier'] = solanaAddress;
                        }
                        return item;
                    })
                } else {
                    paymentObj = paymentObj.concat(userPaymentOptions);
                }
            }

            await ctx.updateSolanaAddressForUser(paymentObj);
        }
        ctx.fetchUserDetail();
        setUpdateInProgress(false);
        props.handleClose();
    }

    return (
        <div>
            <Dialog open={props.open} maxWidth="xs" disableEnforceFocus>
                <Grid container>
                    <Grid item xs={12} align="right" className={classes.padding}>
                        <IconButton
                            edge="start"
                            align="right"
                            color="inherit"
                            aria-label="Close"
                            style={{ padding: 8 }}
                        >
                            <CloseIcon onClick={props.handleClose} />
                        </IconButton>
                    </Grid>
                    <Box style={{ overflow: 'scroll', padding: 20 }}>
                        <Grid item xs={12}>
                            <Grid container direction="row" className={classes.mainHeader}>
                                <Grid item xs={12} align="center">
                                    <Typography className={classes.heading} variant="h5">
                                        User Profile
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                className={classes.mainContent}
                                spacing={1}
                            >
                                <Grid item xs={12} align="left">
                                    <Typography className={classes.subHeading} variant="h5">
                                        Add your Profile Image
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align="left">
                                    <label htmlFor="photo-upload">
                                        <img height='90px' width='90px' htmlFor="photo-upload" src={imagePreviewUrl}
                                            style={{ borderRadius: '150px', cursor: 'pointer', width: '90px', height: '90px' }} ref={imageComponent} />
                                        {/*// <img height= '90px' width='90px' htmlFor="photo-upload" src={AddProfileImage}*/}
                                        {/*//      style={{borderRadius: '150px', cursor:'pointer', width: '90px', height: '90px'}}/>}*/}
                                        <input id="photo-upload" type="file" onChange={photoUpload} hidden={true} />
                                    </label>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 10 }}>
                                    <TextField
                                        className={classes.inputClass}
                                        error={firstNameRequiredError}
                                        helperText={firstNameRequiredError ? "First's name is required" : ' '}
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        label={<span style={{ fontSize: 12 }}>
                                            Full Name
                                        </span>}
                                        id="firstName"
                                        value={firstName}
                                        onChange={(event) => {
                                            setFirstName(event.target.value);
                                            setFirstNameRequiredError(false);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.inputClass}
                                        error={emailRequiredError}
                                        helperText={emailRequiredError ? "Email is required" : ' '}
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        label={<span style={{ fontSize: 12 }}>
                                            Email
                                        </span>}
                                        id="email"
                                        value={email}
                                        onChange={(event) => {
                                            setEmail(event.target.value);
                                            setEmailRequiredError(false);
                                        }}
                                        inputMode={"email"}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.inputClass}
                                        error={phoneNumberRequiredError}
                                        helperText={phoneNumberRequiredError ? "Phone Number is required" : ' '}
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        label={<span style={{ fontSize: 12 }}>
                                            Phone Number
                                        </span>}
                                        id="phoneNumber"
                                        value={phoneNumber}
                                        onChange={(event) => {
                                            setPhoneNumber(event.target.value);
                                            setPhoneNumberRequiredError(false);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disableFuture
                                            label={<span style={{ fontSize: 12 }}>
                                                Birthday
                                            </span>}
                                            openTo="day"
                                            views={["year", "month", "day"]}
                                            value={userDOB}
                                            onChange={(dobVal) => {
                                                setUserDOB(dobVal);
                                                setUserDOBRequiredError(false);
                                            }}
                                            renderInput={(params) => <TextField
                                                className={classes.inputClass}
                                                {...params}
                                                error={userDOBRequiredError}
                                                helperText={userDOBRequiredError ? "User's Birthday is required" : ' '}
                                                fullWidth
                                                margin="dense"
                                                variant="outlined" />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.inputClass}
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        label={<span style={{ fontSize: 12 }}>
                                            Solana Address (Optional)
                                        </span>}
                                        id="solanaAddressId"
                                        value={solanaAddress}
                                        onChange={(event) => {
                                            setSolanaAddress(event.target.value);
                                        }}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} >
                                    <Typography className={classes.subHeading} variant="h5">Gender</Typography>
                                    <RadioGroup
                                        row
                                        label="Gender"
                                        name="Gender"
                                        value={gender}
                                        onChange={(event) => {
                                            setGender(event.target.value);
                                            setGenderRequiredError(false);
                                        }}
                                        style={{ paddingLeft: '1em' }}
                                    >
                                        <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                        <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                        <FormControlLabel value="Undeclared" control={<Radio />} label="Prefer Not To Say" />
                                    </RadioGroup>
                                    {genderRequiredError && <div style={{ fontSize: 14, color: 'red' }}>Gender is required </div>}
                                </Grid> */}
                                <Grid item xs={12} align="center" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div
                                        style={{ width: '40%', marginTop: 20, display: 'flex', alignItems: 'center' }}
                                        onClick={logOut}
                                    >
                                        <img height='20px' width='20px' src={LogoutIcon} />
                                        <Typography style={{ color: '#0078D9', marginLeft: '5px', cursor: 'pointer' }}>Log Out</Typography>
                                    </div>
                                    {!updateInProgress ? <Button type={"submit"} variant="contained" disableElevation
                                        style={{ width: '40%', background: '#0079D9', marginTop: 20 }}
                                        onClick={updateUserProfile}>
                                        <Typography style={{ color: '#ffffff' }}>Save</Typography>
                                    </Button> :
                                        <LoadingButton loading loadingIndicator="Adding..." variant="contained"
                                            style={{ width: '40%', marginTop: 20 }}>
                                            Adding...
                                        </LoadingButton>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Dialog>
            <Dialog open={imageUploadDialogOpen} maxWidth="xs" disableEnforceFocus>
                <ImageCrop img={imagePreviewUrl} handleClose={handleClose} handleCancel={handleClose} setImageUrl={setImagePreviewUrl} setFile={setFile} />
            </Dialog>
        </div>
    )
}

export default UserProfile;