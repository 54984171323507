import ChildProfile_Blue from "../assets/ChildProfile_Blue.svg";
import ChildProfile_Cyan from "../assets/ChildProfile_Cyan.svg";
import ChildProfile_Red from "../assets/ChildProfile_Red.svg";
import ChildProfile_Orange from "../assets/ChildProfile_Orange.svg";
import { PublicKey } from "@solana/web3.js";
import { getAccount, getAssociatedTokenAddress, TokenAccountNotFoundError } from "@solana/spl-token";

export function setInSession(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getFromSession(key) {
    const result = window.sessionStorage.getItem(key);
    return result ? JSON.parse(result) : null;
}

export function getTokenFromSession() {
    const user = getFromSession('user');
    let token = null;
    if (user && user.stsTokenManager) {
        token = user.stsTokenManager.accessToken;
    }
    return token;
}

export const cardBgColor = ['#FEA621', '#ED6471', '#0078D9', '#3BFFD9'];
export const childProfilePhoto = [ChildProfile_Orange, ChildProfile_Red, ChildProfile_Blue, ChildProfile_Cyan];
export const childProfileTextColor = ['black', 'white', 'white', 'black'];
export const eventImageColor = ['sb', 'rd', 'or', 'cy'];
export const eventImageBgColor = ['#CCE4F7', '#FCE0E2', '#FEEAC9', '#D8FFF2'];


export const getChildProfile = (beneficiaryImageUrl, benId) => {
    let childPhoto = null;
    if (beneficiaryImageUrl && beneficiaryImageUrl.length) {
        const child = beneficiaryImageUrl.find(ben => {
            return ben.beneficiaryId === benId;
        });
        if (child) {
            childPhoto = child.imageUrl || childProfilePhoto[child.index % (childProfilePhoto.length)];
        }
    }
    return childPhoto;
}

export const getChildCardBackgroundColor = (beneficiaryImageUrl, benId) => {
    let bgColor = null;
    if (beneficiaryImageUrl && beneficiaryImageUrl.length) {
        const child = beneficiaryImageUrl.find(ben => {
            return ben.beneficiaryId === benId;
        });
        if (child) {
            bgColor = cardBgColor[child.index % (cardBgColor.length)];
        }
    }
    return bgColor;
}


export const getChildCardTextColor = (beneficiaryImageUrl, benId) => {
    let textColor = null;
    if (beneficiaryImageUrl && beneficiaryImageUrl.length) {
        const child = beneficiaryImageUrl.find(ben => {
            return ben.beneficiaryId === benId;
        });
        if (child) {
            textColor = childProfileTextColor[child.index % (childProfileTextColor.length)];
        }
    }
    return textColor;
}

export const getColorCode = (index) => {
    return eventImageColor[index % (eventImageColor.length)];
}

export const getBackgroundColor = (color) => {
    switch (color) {
        case 'sb':
            return eventImageBgColor[0]
        case 'rd':
            return eventImageBgColor[1]
        case 'or':
            return eventImageBgColor[2]
        case 'cy':
            return eventImageBgColor[3]
        default:
            break;
    }
}

export const getSolanaVendorName = (paymentOption) => {
    if (paymentOption && paymentOption.length) {
        const solanaOption = paymentOption.find(item => {
            return item.vendorName.indexOf("Solana") !== -1;
        })
        if (solanaOption) {
            return solanaOption.vendorName;
        }
    }
    return "";
}

//TODO: Validate solana address each time while adding/editing address.
export const validateSolanaAddress = (address) => {
    try {
        let pubkey = new PublicKey(address)
        return PublicKey.isOnCurve(pubkey.toBuffer())
    } catch (error) {
        return false
    }
}

//TODO: All use cases of getting token account balance to use this. Changes to be made during clean-up.
export const getTokenAccountBalance = async (tokenAddress, key, connection) => {
    try {
        const userCouponAddress = await getAssociatedTokenAddress(tokenAddress, key)
        const userCouponAccount = await getAccount(connection, userCouponAddress)
        const { amount } = userCouponAccount
        console.log("Amount", amount);
        // do whatever with amount
    } catch (e) {
        if (e instanceof TokenAccountNotFoundError) {
            // No account for the user
            // amount = 0
            console.log("No token found")
        } else {
            console.error('Error getting coupon balance', e)
        }
    }
}
