import React, { useState } from "react";
import ShareEventDialog from "./ShareEventDialog";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core";
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';

const useStyles = makeStyles({
    card: {
        background: "#EBE9E9",
        minWidth: '400px',
        minHeight: '240px',
        margin: 'auto',
        borderRadius: '30px',
        transformStyle: "preserve-3d"
    }

});

function ShareEventClickable(props) {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);

    const handleClose = () => {
        setVisible(false);
    }

    const shareEventHandler = () => {
        setVisible(true);
    }

    return (
        <div>

            <Button
                variant="contained"
                style={{
                    height: 36,
                    backgroundColor: "#0096FF",
                    color: 'white'
                }}
                onClick={shareEventHandler}
                startIcon={<IosShareRoundedIcon />}>
                Share Event
            </Button>
            {visible ? <ShareEventDialog visible={visible} handleClose={handleClose} eventId={props.eventId} eventData={props.eventData} /> : <div />}
        </div>
    )
}

export default ShareEventClickable;