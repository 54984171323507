import { Container, makeStyles, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import VerifyEmail from "../../assets/Verify Email.svg";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        padding: '8px',
        height: '100vh',
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative'
    },
    padding: {
        padding: 0
    },
});

function EmailVerification() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { state } = useLocation();

    return (
        <div className={classes.root}>
            <Container maxWidth="sm" sx={{ border: 1, borderRadius: 2, gap: 2, margin: 4, mx: "auto", width: '30%', background: "#ffffff" }}>
                <Box sx={{ width: '90%', justifyContent: "center", padding: "auto", alignItems: "center", mx: "auto" }} >
                    <Paper sx={{
                        width: "347px",
                        height: "620px",
                        background: "#FFFFFF",
                        border: "0.5px solid #D0D0D0",
                        boxSizing: "border-box",
                        borderRadius: "6px"
                    }}>
                        <div style={{
                            padding: '8px',
                            display: 'flex',
                            minHeight: '6vh',
                            background: 'transparent',
                            backgroundColor: 'transparent',
                            position: 'relative',
                            justifyContent: "center",
                            paddingTop: '2em'
                        }}>
                            <img src={VerifyEmail} />
                        </div>
                        <div style={{
                            padding: '12px',
                            display: 'flex',
                            minHeight: '10vh',
                            background: 'transparent',
                            backgroundColor: 'transparent',
                            position: 'relative',
                            justifyContent: "center"
                        }}>
                            <Grid Container spacing={2}>
                                <Grid item xs={12} align="center" className={classes.padding}>
                                    <Typography variant={'h5'} style={{
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '18px',
                                        lineHeight: '22px', margin: '1em 0 1em 0'
                                    }}>
                                        Verify Your Email
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align="center" className={classes.padding}>
                                    <Typography variant={'h5'} style={{
                                        fontFamily: 'Montserrat',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '22px', margin: '1em 0 1em 0'
                                    }}>
                                        We sent an email to your email. Please open it so we can verify it’s you.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align="center" className={classes.padding}>
                                    <Button variant="contained" type="submit"
                                        style={{ color: 'white' }}
                                        className={"save"}
                                        onClick={() => navigate({
                                            pathname: `/addUserDetails`
                                        }, {
                                            state: {
                                                email: state.email,
                                                fullName: state.fullName
                                            }
                                        })}
                                        fullWidth>
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </Box>
            </Container>
        </div>
    )
}

export default EmailVerification;