import { createContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import getFirebaseToken from "../utils/FirebaseToken";
import axios from "axios";
import dayjs from "dayjs";
import event_contributors_data_mock from "../mocks/EventContributors";
import { setInSession, getFromSession, getColorCode, getSolanaVendorName } from "../utils/Util";
import { signOut } from "firebase/auth";
import { useNotify } from "../utils/Notify";

const utc = require('dayjs/plugin/utc')

const initialState = { data: null, status: 'NOT_STARTED' };

const KidfolioContext = createContext({
    user: null,
    childData: { beneficiaries: [] },
    beneficiariesData: initialState,
    userId: null,
    loggedInUserDetails: [],
    userProfileImageUrl: [],
    beneficiaryImageUrl: [],
    usersAllContributions: [],
    resourceIdAndURL: {},
    eventImageColorCode: {},
    eventContributorImageColorCode: {},
    upcomingEventData: [],
    childEventData: [],
    eventInvitees: [],
    eventContributorsData: null,
    categoriesData: [],
    giftsData: [],
    paymentOption: [],
    notificationData: [],
    registeredId: null,
    firebaseToken: null,
    guestUser: false
});

export function KidfolioContextProvider(props) {
    const [user, setUser] = useState(null);
    const [childData, setChildData] = useState([]); // TODO : Can be replaced with beneficiariesData later (Refatoring).
    const [beneficiariesData, setBeneficiariesData] = useState(initialState);
    const [upcomingEventData, setUpcomingEventData] = useState(null);
    const [amountOptionsData, setAmountOptionsData] = useState(null);
    const [childEventData, setChildEventData] = useState(null);
    const [eventContributorsData, setEventContributorsData] = useState(null);
    const [usersAllContributions, setUsersAllContributions] = useState(null);
    const [categoriesData, setCategoriesData] = useState([]);
    const [giftsData, setGiftsData] = useState([]);
    const [firebaseToken, setFirebaseToken] = useState(null);
    const [loggedInUserDetails, setLoggedInUserDetails] = useState([]);
    const [userId, setUserId] = useState(null);
    const [userProfileImageUrl, setUserProfileImageUrl] = useState(null);
    const [beneficiaryImageUrl, setBeneficiaryImageUrl] = useState([]);
    const [eventImageColorCode, setEventImageColorCode] = useState({});
    const [resourceIdAndURL, setResourceIdAndURL] = useState({});
    const [eventContributorImageColorCode, setEventContributorImageColorCode] = useState({});
    const [notificationData, setNotificationData] = useState(null);
    const [paymentOption, setPaymentOption] = useState([]);
    const [registeredId, setRegisteredId] = useState(null);
    const [eventInvitees, setEventInvitees] = useState([]);
    const [guestUser, setGuestUser] = useState(false);
    const [authChecked, setAuthChecked] = useState(false);

    const baseURL = process.env.REACT_APP_APP_BASE_URL;
    const notify = useNotify();

    useEffect(() => {
        setAuthChecked(false);
        onAuthStateChanged(auth, async (user) => {
            console.log("on Change detect", user);
            setAuthChecked(true);
            if (user) {
                const token = await getFirebaseToken();
                setFirebaseToken(token);
                setUser(user);
                setInSession("user", user);
                await fetchUserDetail(token);
            } else {
                setUser(null);
                setFirebaseToken(null);
                setInSession("user", null);
                setIsGuestUser(null)
            }
        });
    }, []);

    useEffect(async () => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            setBeneficiariesData({ data: {}, status: 'IN_PROGRESS' });
            fetchChildren();
        }
    }, [user]);

    useEffect(async () => {
        getUsersAllContributions();
        fetchNotifications();
        getPaymentOption();
        fetchCategories();
    }, [user, userId]);

    useEffect(async () => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            const { data } = await axios.get(`${baseURL}/fundingApi/config`, {
                headers: {
                    "x-firebase-auth": token
                }
            });
            setAmountOptionsData(data);
        }
    }, [user]);

    const setIsGuestUser = (isGuest) => {
        setGuestUser(isGuest);
        setInSession('isGuest', isGuest);
    }

    const isGuestUser = () => {
        const result = getFromSession('isGuest') || guestUser;
        return result === true || result === 'true';
    }

    const fetchNotifications = async () => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && userId) {
            const { data } = await axios.get(`${baseURL}/notificationApi/notifications/${userId}`, {
                headers: {
                    "x-firebase-auth": token
                }
            });
            setNotificationData(data);

            const senderImageIds = [];

            if (data && data.length) {
                data.forEach(item => {
                    if (item.data && item.data.Category === "Contribution"
                        && item.data.notificationDetails) {
                        const info = JSON.parse(item.data.notificationDetails);
                        const imageId = info.senderImageId;
                        if (imageId && senderImageIds.indexOf(imageId) === -1) {
                            senderImageIds.push(imageId);
                        }
                    }
                })
            }
            for (let index = 0; index < senderImageIds.length; index++) {
                const imageId = senderImageIds[index];
                await fetchImageById(imageId);
            }
        }
    }

    const addChild = async (name, dob, solanaAddress, imageId) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && name && dob) {
            const { data } = await axios.post(`${baseURL}/userApi/beneficiaries`,
                {
                    "fullName": name,
                    "birthDate": dob,
                    "imageId": imageId
                },
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
            await addSolanaWalletAddress(data.id, solanaAddress);
            await fetchAndUpdateBeneficiaryImageUrl(data.id, imageId);
        }
    }

    const addSolanaWalletAddress = async (benId, solanaAddress) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            const payload = [
                {
                    "vendorName": getSolanaVendorName(paymentOption),
                    "identifier": solanaAddress
                }
            ];
            const { data } = await axios.post(`${baseURL}/fundingApi/config/payments?beneficiaryId=${benId}`,
                payload,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
        }
    }

    const addContribution = async (eventId, contributeGiftobject) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            const { data } = await axios.post(`${baseURL}/fundingApi/payments/${eventId}/contribute`,
                contributeGiftobject,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
        }
    }

    const fetchUserDetail = async () => {
        dayjs.extend(utc)
        const token = await getFirebaseToken();
        const date = dayjs.utc().format();
        if (token) {
            const { data } = await axios.get(`${baseURL}/userApi/user/loggedin`, {
                headers: {
                    "x-firebase-auth": token
                }
            })
                .catch(err => {
                    const path = window.location.href;
                    // console.log("Location", window.location.href);
                    // console.log("Path signUp", path.includes('signUp'));
                    // console.log("Path signUp", path.includes('addUserDetails'));
                    // console.log("Path signUp", path.includes('addChild'));
                    if (path.includes('signUp') || path.includes('addUserDetails') || path.includes('addChild')) {
                        console.log("New user onboarding");
                    } else {
                        signOut(auth);
                        avictData();
                        notify(
                            "error", "Please login using signed-up email or Sign Up if you are a new user", ""
                        )
                    }
                });
            setLoggedInUserDetails(data);
            setUserId(data.id);
            await fetchUpcomingUserEvents(token, data.id);
            if (data.profile.imageId) {
                await fetchProfileImage(data.profile.imageId);
            }
        }
    };

    const fetchUpcomingUserEvents = async (token = firebaseToken, userID = userId) => {
        dayjs.extend(utc)
        const date = dayjs.utc().format();
        if (token && userID) {
            const { data } = await axios.get(`${baseURL}/userApi/users/${userID}/events?limit=50&page=1&since=${date}`, {
                headers: {
                    "x-firebase-auth": token
                }
            });
            setUpcomingEventData(data);
        }
    };

    const fetchChildren = async () => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            const { data } = await axios.get(`${baseURL}/userApi/beneficiaries`, {
                headers: {
                    "x-firebase-auth": token
                }
            });

            if (data && data.beneficiaries && data.beneficiaries.length) {
                data.beneficiaries.sort((a, b) => {
                    return new Date(a.createdOn) - new Date(b.createdOn);
                });
            }
            setChildData(data);
            setBeneficiariesData({ data: data, status: 'SUCCESS' });
            handleBeneficiaryImages(data);
        }
    }

    const handleBeneficiaryImages = async (data) => {


        if (data.beneficiaries && (!beneficiaryImageUrl || !beneficiaryImageUrl.length)) {
            const token = await getFirebaseToken();

            const beneficiaryImageUrlData = data.beneficiaries.map((obj, index) => {
                return {
                    beneficiaryId: obj.id,
                    imageId: obj.profile.imageId,
                    imageUrl: "",
                    index: index
                };
            });
            const withImageId = beneficiaryImageUrlData.filter(item => { return !!item.imageId });
            const withoutImageId = beneficiaryImageUrlData.filter(item => { return !item.imageId });

            await Promise.all(withImageId.map((beneObj) => {
                return axios.get(`${baseURL}/resourceApi/resources/${beneObj.imageId}`, {
                    headers: {
                        "x-firebase-auth": token
                    },
                    responseType: 'blob'
                })
            })).then(async (res) => {
                res.forEach((o, index) => {
                    let binaryData = [];
                    binaryData.push(o.data);
                    const urlCreator = window.URL || window.webkitURL;
                    const imageUrl = urlCreator.createObjectURL(new Blob(binaryData, { type: "image/jpg" }));
                    withImageId[index].imageUrl = imageUrl;
                })
            })
            const completeData = [...withImageId, ...withoutImageId];
            completeData.sort((a, b) => a.index - b.index);
            setBeneficiaryImageUrl(completeData);
        }
    }

    const fetchAndUpdateBeneficiaryImageUrl = async (benId, imageId) => {

        if (!imageId) {
            const newImgObj = {
                beneficiaryId: benId,
                imageId: imageId,
                imageUrl: ''
            };
            addOrUpdateChildImage(newImgObj, benId);
            return;
        }

        const token = await getFirebaseToken();
        let imageUrl = null;
        await axios.get(`${baseURL}/resourceApi/resources/${imageId}`, {
            headers: {
                "x-firebase-auth": token
            },
            responseType: 'blob'
        }).then(o => {

            let binaryData = [];
            binaryData.push(o.data);
            const urlCreator = window.URL || window.webkitURL;
            imageUrl = urlCreator.createObjectURL(new Blob(binaryData, { type: "image/jpg" }));
            const newObj = {
                beneficiaryId: benId,
                imageId: imageId,
                imageUrl: imageUrl,
            };
            storeResourceIdAndUrls(imageId, imageUrl);
            addOrUpdateChildImage(newObj, benId);
        }
        )
    }

    const addOrUpdateChildImage = (newObj, benId) => {
        let beneficiaryImageUrlData = [];
        // Add First Child
        if (!beneficiaryImageUrl.length) {
            beneficiaryImageUrlData = [];
            beneficiaryImageUrlData.push({ ...newObj, index: 0 });
        } else if (!beneficiaryImageUrl.some((d) => d.beneficiaryId === benId)) {
            // Add new Child
            beneficiaryImageUrlData = beneficiaryImageUrl;
            beneficiaryImageUrlData.push({ ...newObj, index: beneficiaryImageUrl.length });
        } else {
            // Update existing child photos
            beneficiaryImageUrlData = [];
            beneficiaryImageUrl.forEach(obj => {
                if (obj.beneficiaryId === benId) {
                    beneficiaryImageUrlData.push({
                        ...obj,
                        ...newObj
                    });
                } else {
                    beneficiaryImageUrlData.push(obj);
                }
            })
        }
        setBeneficiaryImageUrl(beneficiaryImageUrlData);
    }

    const fetchCategories = async () => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            const { data } = await axios.get(`${baseURL}/contentApi/posts/categories`, {
                headers: {
                    "x-firebase-auth": token
                }
            });
            setCategoriesData(data);
        }
    }

    const fetchChildEvents = async (beneficiaryId) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            const { data } = await axios.get(`${baseURL}/userApi/beneficiaries/${beneficiaryId}/events`, {
                headers: {
                    "x-firebase-auth": token
                }
            });
            setChildEventData(data);
            handleEventImages(data);
        }
    };

    const handleEventImages = (data) => {
        let eventImageObj = eventImageColorCode;

        if (data && data.length) {
            data.forEach((item, index) => {
                if (!eventImageObj[item.post._id]) {
                    eventImageObj[item.post._id] = getColorCode(index);
                }
            })
        }
        setEventImageColorCode(eventImageObj)
    }

    const fetchGifts = async (benId) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        const { data } = await axios.get(`${baseURL}/contentApi/posts/gifts`, {
            headers: {
                "x-firebase-auth": token
            }
        });
        setGiftsData(data);
    };


    const fetchEventContributorsData = async (beneficiaryId, postId) => {
        setEventContributorsData(null);
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            const { data } = await axios.get(`${baseURL}/userApi/beneficiaries/${beneficiaryId}/${postId}/contributions`, {
                headers: {
                    "x-firebase-auth": token
                }
            });
            const data1 = event_contributors_data_mock;
            setEventContributorsData(data);
            handleEventContributorImages(data);
        }
    };

    const handleEventContributorImages = async (data) => {
        let eventContriImageObj = eventContributorImageColorCode;
        let contributorImages = [];

        if (data && data.length) {
            data.forEach((item, index) => {
                if (item.contributor && item.contributor.profile
                    && !item.contributor.profile.imageId
                    && !eventContriImageObj[item.contributor.profile.emailId]) {
                    eventContriImageObj[item.contributor.profile.emailId] = getColorCode(index);
                } else if (item.contributor && item.contributor.profile
                    && item.contributor.profile.imageId
                    && contributorImages.indexOf(item.contributor.profile.imageId) === -1) {
                    contributorImages.push(item.contributor.profile.imageId);
                }
            })
        }

        if (contributorImages.length) {
            for (let index = 0; index < contributorImages.length; index++) {
                const imageId = contributorImages[index];
                await fetchImageById(imageId);
            }
        }

        setEventContributorImageColorCode(eventContriImageObj)
    }

    const fetchProfileImage = async (imageId) => {
        const imageBlobUrl = findBlobUrlForResourceId(imageId);
        if (imageBlobUrl) {
            setUserProfileImageUrl(imageBlobUrl);
            return imageBlobUrl;
        }
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        axios.get(`${baseURL}/resourceApi/resources/${imageId}`, {
            headers: {
                "x-firebase-auth": token
            },
            responseType: 'blob'
        }).then(o => {
            let binaryData = [];
            binaryData.push(o.data);
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(new Blob(binaryData, { type: "image/jpg" }));
            storeResourceIdAndUrls(imageId, imageUrl);
            setUserProfileImageUrl(imageUrl);
        }
        )
    }

    const fetchImageById = async (imageId) => {

        const imageBlobUrl = findBlobUrlForResourceId(imageId);
        if (imageBlobUrl) {
            return imageBlobUrl;
        }

        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        const imgResponse = await axios.get(`${baseURL}/resourceApi/resources/${imageId}`, {
            headers: {
                "x-firebase-auth": token
            },
            responseType: 'blob'
        })
        let binaryData = [];
        binaryData.push(imgResponse.data);
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(new Blob(binaryData, { type: "image/jpg" }));
        storeResourceIdAndUrls(imageId, imageUrl);
        return imageUrl;
    }

    const storeResourceIdAndUrls = (resourceId, blobUrl) => {
        let idAndUrlObj = resourceIdAndURL;
        if (resourceId && blobUrl && !idAndUrlObj[resourceId]) {
            idAndUrlObj[resourceId] = blobUrl;
        }
        setResourceIdAndURL(idAndUrlObj)
    }

    const findBlobUrlForResourceId = (resourceId) => {
        return resourceIdAndURL[resourceId] ? resourceIdAndURL[resourceId] : null;
    }

    const fetchVideoById = async (videoId) => {

        const videoBlobUrl = findBlobUrlForResourceId(videoId);
        if (videoBlobUrl) {
            return videoBlobUrl;
        }

        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        const videoResponse = await axios.get(`${baseURL}/resourceApi/resources/${videoId}`, {
            headers: {
                "x-firebase-auth": token
            },
            responseType: 'blob'
        })
        let binaryData = [];
        binaryData.push(videoResponse.data);
        const urlCreator = window.URL || window.webkitURL;
        const videoUrl = urlCreator.createObjectURL(new Blob(binaryData, { type: videoResponse.data.type }));
        storeResourceIdAndUrls(videoId, videoUrl);
        return videoUrl;
    }

    const deleteEvent = async (eventId) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            const { data } = await axios.delete(`${baseURL}/contentApi/posts/${eventId}`, {
                headers: {
                    "x-firebase-auth": token
                }
            });
        }
    };

    const deleteBeneficiary = async (beneficiaryId) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            const { data } = await axios.delete(`${baseURL}/userApi/beneficiaries/${beneficiaryId}`, {
                headers: {
                    "x-firebase-auth": token
                }
            });

            if (beneficiaryImageUrl && beneficiaryImageUrl.length) {
                const remainingBens = beneficiaryImageUrl.filter(ben => {
                    return ben.beneficiaryId !== beneficiaryId;
                })
                setBeneficiaryImageUrl(remainingBens);
            }
        }
    };

    const getEvent = async (eventId) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        let response = null;
        if (token) {
            response = await axios.get(`${baseURL}/contentApi/posts/${eventId}`,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
        }
        return response ? response.data : null;
    }

    const getUsersAllContributions = async () => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && userId) {
            const { data } = await axios.get(`${baseURL}/userApi/users/${userId}/contributions`,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
            setUsersAllContributions(data)
        }
    }

    const getInviteeForEvent = async (eventId) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && eventId) {
            const { data } = await axios.get(`${baseURL}/contentApi/posts/${eventId}/invitees`,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
            setEventInvitees(data);
            if (data && data.length) {
                for (let index = 0; index < data.length; index++) {
                    const inviteeData = data[index];
                    if (inviteeData.invitee.imageId) {
                        await fetchImageById(inviteeData.invitee.imageId);
                    }
                }
            }
        }
    }

    const editEvent = async (eventId, editEventDataObj) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && editEventDataObj) {
            await axios.put(`${baseURL}/contentApi/posts/${eventId}`, editEventDataObj,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
        }
    }

    const avictData = async () => {
        setUser(null);
        setInSession("user", null);
        setChildData([]);
        setBeneficiariesData(initialState);
        setUpcomingEventData([]);
        setAmountOptionsData(null);
        setChildEventData(null);
        setEventContributorsData(null);
        setFirebaseToken(null);
        setUserId(null);
        setNotificationData(null);
        setLoggedInUserDetails([]);
        setUserProfileImageUrl(null);
        setBeneficiaryImageUrl([]);
        setEventImageColorCode({});
        setEventContributorImageColorCode({});
        setIsGuestUser(null);
    }

    const resetChildPage = async () => {
        setChildEventData(null);
    }

    const setRegisterId = async (id) => {
        setRegisteredId(id);
    }

    const addEvent = async (createEventDataObj) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && createEventDataObj) {
            await axios.post(`${baseURL}/contentApi/posts`, createEventDataObj,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
        }
    }

    const addCustomEventTitle = async (customGift) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && customGift) {
            await axios.post(`${baseURL}/contentApi/posts/categories/${customGift}`, null,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
        }
    }

    const addCustomGift = async (customGift) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && customGift) {
            await axios.post(`${baseURL}/contentApi/posts/gifts/${customGift}`, null,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
        }
    }

    const sendInvite = async (eventId, payload) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && payload && eventId) {
            await axios.post(`${baseURL}/contentApi/posts/v2/${eventId}/invite`, payload,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                });
        }
    }

    const updateUserDetails = async (userDetail) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && userDetail) {
            await axios.put(`${baseURL}/userApi/users/${userId}`, userDetail,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
        }
    }

    const updateBeneficiaryDetails = async (beneficiaryId, childDetail, solanaAddress) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token && childDetail) {
            await axios.put(`${baseURL}/userApi/users/${beneficiaryId}`, childDetail,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
            await addSolanaWalletAddress(beneficiaryId, solanaAddress);
        }
    }

    const getPaymentOption = async () => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            const result = await axios.get(`${baseURL}/fundingApi/config/v3/payments`,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
            setPaymentOption(result.data);
        }
    }

    const updateSolanaAddressForUser = async (solanaObj) => {
        const token = await getFirebaseToken();
        await setFirebaseToken(token);
        if (token) {
            await axios.post(`${baseURL}/fundingApi/config/payments`, solanaObj,
                {
                    headers: {
                        "x-firebase-auth": token
                    }
                }
            );
        }
    }

    const context = {
        user,
        userId,
        loggedInUserDetails,
        userProfileImageUrl,
        beneficiaryImageUrl,
        resourceIdAndURL,
        eventImageColorCode,
        eventContributorImageColorCode,
        childData,
        beneficiariesData,
        upcomingEventData,
        amountOptionsData,
        childEventData,
        eventContributorsData,
        categoriesData,
        giftsData,
        paymentOption,
        usersAllContributions,
        notificationData,
        firebaseToken,
        eventInvitees,
        registeredId,
        guestUser,
        fetchGifts,
        fetchCategories,
        getEvent,
        addEvent,
        editEvent,
        addChild,
        getUsersAllContributions,
        addContribution,
        addCustomGift,
        addCustomEventTitle,
        fetchChildren,
        fetchImageById,
        fetchVideoById,
        addSolanaWalletAddress,
        updateSolanaAddressForUser,
        fetchNotifications,
        findBlobUrlForResourceId,
        fetchChildEvents,
        fetchUserDetail,
        fetchEventContributorsData,
        fetchAndUpdateBeneficiaryImageUrl,
        fetchUpcomingUserEvents,
        sendInvite,
        updateUserDetails,
        updateBeneficiaryDetails,
        getInviteeForEvent,
        avictData,
        fetchProfileImage,
        resetChildPage,
        deleteEvent,
        deleteBeneficiary,
        setRegisterId,
        setIsGuestUser,
        isGuestUser,
        authChecked
    };

    return <KidfolioContext.Provider value={context}>
        {props.children}
    </KidfolioContext.Provider>
}

export default KidfolioContext;
