import React from "react";
import { withStyles, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import LoadingButton from '@mui/lab/LoadingButton';
// import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import { teal, grey } from "@material-ui/core/colors";
import CloseIcon from '@mui/icons-material/Close';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from "@mui/lab/DatePicker";
import AddChildImage from "../assets/addChild.svg";
import KidfolioContext from "../store/KidfolioContext";
import { useContext, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import AddProfileImage from "../assets/AddProfileImage.svg";
import ImageCrop from "./Common/ImageCrop";
import axios from "axios";
import 'react-image-crop/dist/ReactCrop.css'
import { getSolanaVendorName } from "../utils/Util";

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    secondaryColor: {
        color: grey[700]
    },
    heading: {
        fontSize: 18,
        fontWeight: 600
    },
    photoMsg: {
        fontSize: 13,
        color: "#666666",
        marginBottom: 20
    },

    noPadding: {
        paddingTop: '0px !important',
        paddingLeft: 0,
        paddingRight: 0
    },
    padding: {
        padding: 0
    },
    mainHeader: {
        alignItems: "center"
    },
    mainContent: {
        padding: 40
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    },
    inputClass: {
        '& label': {
            lineHeight: 0.7,
        },
        '& input': {
            fontSize: '12px'
        },
    },
});

function EditChildDialog(props) {

    const { classes, open } = props;
    const [childName, setChildName] = React.useState(props.childData.profile.fullName);
    const [childDOB, setChildDOB] = React.useState(props.childData.profile.birthDate);
    const [childNameRequiredError, setChildNameRequiredError] = React.useState(false);
    const [childDOBRequiredError, setChildDOBRequiredError] = React.useState(false);
    const [addInProgress, setAddInProgress] = React.useState(false);
    const ctx = useContext(KidfolioContext);
    const { fetchChildren, updateBeneficiaryDetails, fetchAndUpdateBeneficiaryImageUrl, firebaseToken } = useContext(KidfolioContext);
    const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(props.childPhoto);
    const imageComponent = useRef(null);
    const [file, setFile] = useState('');
    const [oldImagePreviewUrl, setOldImagePreviewUrl] = useState(props.childPhoto);
    const [imageUpdated, setImageUpdated] = useState(false);
    const getSolanaAddress = () => {
        if (props.childData.paymentOptions) {
            const solanaOption = props.childData.paymentOptions.find(item => {
                return item.vendorName === getSolanaVendorName(ctx.paymentOption);
            })
            if (solanaOption) {
                return solanaOption.identifier;
            }
        }
        return "";
    }
    const [childSolAdd, setChildSolAdd] = React.useState(getSolanaAddress());
    const baseURL = process.env.REACT_APP_APP_BASE_URL;

    const handleImageCropClose = () => {
        setImageUploadDialogOpen(false);
    }

    const handleImageCropCancel = () => {
        setImageUploadDialogOpen(false);
        setImagePreviewUrl(oldImagePreviewUrl);
        setImageUpdated(false);
    }

    const photoUpload = e => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            setFile(file);
            setImagePreviewUrl(reader.result);
            setImageUpdated(true);
            setImageUploadDialogOpen(true);
        }
        reader.readAsDataURL(file);
    }

    async function editChildDetails() {

        let validationFailed = false;

        if (!childName || !childName.trim()) {
            setChildNameRequiredError(true);
            validationFailed = true;
        }
        if (!childDOB) {
            setChildDOBRequiredError(true);
            validationFailed = true;
        }
        if (validationFailed) {
            return;
        }
        setAddInProgress(true);

        let imageId = props.childData.profile.imageId || null;
        if (imageUpdated) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('content-type', 'multipart/form-data');
            const response = await axios.post(
                `${baseURL}/resourceApi/resources`, formData,
                {
                    headers: {
                        "x-firebase-auth": firebaseToken,
                        "content-type": 'multipart/form-data'
                    }
                }
            )
            imageId = response.data.id;
            setImageUpdated(false);
            fetchAndUpdateBeneficiaryImageUrl(props.childData.id, imageId);
        }

        const beneficiaryDetail = {
            fullName: childName,
            birthDate: childDOB,
            imageId: imageId
        }
        await updateBeneficiaryDetails(props.childData.id, beneficiaryDetail, childSolAdd);
        await fetchChildren();
        props.handleClose();
    }

    return (
        <React.Fragment>
            <Dialog
                disableEnforceFocus
                className={classes.root}
                maxWidth="xs"
                open={props.visible}
                onClose={() => props.handleClose}
            >
                <DialogContent className={classes.noPadding}>
                    <Grid container>
                        <Grid item xs={12} align="right" className={classes.padding}>
                            <IconButton
                                edge="start"
                                align="right"
                                color="inherit"
                                aria-label="Close"
                                style={{ padding: 8 }}
                            >
                                <CloseIcon onClick={props.handleClose} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" className={classes.mainHeader}>
                                <Grid item xs={12} align="center">
                                    <img src={AddChildImage} alt="Add Child" style={{ height: '60px' }} />
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Typography className={classes.heading} variant="h5">
                                        Edit Child Details
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Grid
                                container
                                direction="row"
                                className={classes.mainContent}
                                spacing={1}
                            >

                                <Grid item xs={12} align="center">
                                    <label htmlFor="photo-upload">
                                        <img height='90px' width='90px' htmlFor="photo-upload" src={imagePreviewUrl}
                                            style={{ borderRadius: '150px', cursor: 'pointer', width: '90px', height: '90px' }} ref={imageComponent} />
                                        <input id="photo-upload" type="file" onChange={photoUpload} hidden={true} />
                                    </label>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Typography className={classes.photoMsg}>
                                        Photo is optional
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.inputClass}
                                        error={childNameRequiredError}
                                        helperText={childNameRequiredError ? "Child's name is required" : ' '}
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        label={<span style={{ fontSize: 12 }}>
                                            Child's Name
                                        </span>}
                                        id="childName"
                                        value={childName}
                                        onChange={(event) => {
                                            setChildName(event.target.value);
                                            setChildNameRequiredError(false);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disableFuture
                                            label={<span style={{ fontSize: 12 }}>
                                                Birthday
                                            </span>}
                                            openTo="day"
                                            views={["year", "month", "day"]}
                                            value={childDOB}
                                            onChange={(dobVal) => {
                                                setChildDOB(dobVal);
                                                setChildDOBRequiredError(false);
                                            }}
                                            renderInput={(params) => <TextField
                                                className={classes.inputClass}
                                                {...params}
                                                error={childDOBRequiredError}
                                                helperText={childDOBRequiredError ? "Child's Birthday is required" : ' '}
                                                fullWidth
                                                margin="dense"
                                                variant="outlined" />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        className={classes.inputClass}
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                        label={<span style={{ fontSize: 12 }}>
                                            Solana Wallet Address (Optional)
                                        </span>}
                                        id="address"
                                        value={childSolAdd}
                                        onChange={(event) => {
                                            setChildSolAdd(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} align="center">
                                    {!addInProgress ? <Button type={"submit"} variant="contained" disableElevation
                                        style={{ width: '100%', background: '#0079D9', marginTop: 20 }}
                                        onClick={editChildDetails}>
                                        <Typography style={{ color: '#ffffff' }}>Save</Typography>
                                    </Button> :
                                        <LoadingButton loading loadingIndicator="Saving..." variant="contained"
                                            style={{ width: '100%', marginTop: 20 }}>
                                            Saving...
                                        </LoadingButton>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog open={imageUploadDialogOpen} maxWidth="xs" disableEnforceFocus>
                <ImageCrop img={imagePreviewUrl} handleClose={handleImageCropClose} handleCancel={handleImageCropCancel} setImageUrl={setImagePreviewUrl} setFile={setFile} />
            </Dialog>
        </React.Fragment>
    );
}

export default withStyles(styles)(EditChildDialog);
