import Paper from '@mui/material/Paper';
import { List, makeStyles } from "@material-ui/core";
import ChildCard from "../components/ChildCard";
import React, { useContext, useEffect, useState, useRef } from "react";
import KidfolioContext from "../store/KidfolioContext";
import AddChildCard from "../components/AddChildCard";
import HomeEventsComponent from "../components/HomeEventsComponent";
import WalletBalance from "../components/SideComponent/WalletBalance";
import { useNavigate } from "react-router-dom";
import NoChildImg from "../assets/NoChildImg.svg"
import Button from "@material-ui/core/Button";
import AddChildDialog from '../components/AddChildDialog';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from "@material-ui/core/Backdrop";
import { getChildCardBackgroundColor, getChildProfile, getChildCardTextColor } from '../utils/Util';
import ArrowRight from "../assets/ArrowRight.svg";
import ArrowLeft from "../assets/ArrowLeft.svg";

const useStyles = makeStyles({
    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingLeft: '0',
        width: '100% ',
        marginLeft: '0px',
        marginTop: '30px',
    },
    leftUpperDiv: {
        height: 240,
        position: 'relative',
        scrollBehavior: 'smooth',
        ['@media (min-width:780px)']: {
            marginRight: 20,
        }
    },
    mainInfo: {
        display: 'flex',
        flex: 1,
        minWidth: 300,
        marginRight: 0,
        ['@media (min-width:780px)']: {
            marginRight: 20,
        }
    },
    noChildPanel: {
        width: '100%',
        height: '90%',
        background: '#FFD290',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: 25,
    },
    noChildPanel_image: {
        flex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '100px',
    },
    noChildPanel_content: {
        flex: 4,
        display: 'flex',
        minWidth: '200px',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    noChildPanel_action: {
        flex: 2,
        display: 'flex',
        minWidth: '200px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    walletInfo: {
        display: 'flex',
        flex: 1,
        marginLeft: 0,
        marginBottom: 20,
        width: "100%",
        ['@media (min-width:780px)']: {
            maxWidth: 314,
        }
    },
    backDrop: {
        zIndex: 1000
    }
});

function HomePage() {

    const classes = useStyles();
    const ctx = useContext(KidfolioContext);
    const [addChild, setAddChild] = useState(false);
    const navigate = useNavigate();
    const childPanelRef = useRef(null);
    const [hideLeftScroll, setHideLeftScroll] = useState(true);
    const [hideRightScroll, setHideRightScroll] = useState(true);

    useEffect(async () => {
        ctx.fetchChildren();
        ctx.fetchUpcomingUserEvents();
        ctx.fetchNotifications();
        if (childPanelRef && childPanelRef.current) {
            handleScrollable();
        }
        window.addEventListener('resize', handleScrollable);
    }, []);

    useEffect(async () => {
        if (childPanelRef && childPanelRef.current) {
            handleScrollable();
        }
    }, [childPanelRef?.current?.scrollWidth]);

    const handleClose = () => {
        setAddChild(false);
    }

    function getBackgroundColor(benId) {
        return getChildCardBackgroundColor(ctx.beneficiaryImageUrl, benId);
    }

    function getTextColor(benId) {
        return getChildCardTextColor(ctx.beneficiaryImageUrl, benId);
    }

    const getChildPhoto = (benId) => {
        return getChildProfile(ctx.beneficiaryImageUrl, benId);
    }

    const addChildHandler = () => {
        setAddChild(true);
    }

    const scrollLeft = () => {
        const newScrollValue = childPanelRef.current.scrollLeft + 200;
        childPanelRef.current.scrollTo({
            left: newScrollValue,
            behavior: "smooth",
        })
        handleScrollable(newScrollValue);
    }

    const scrollRight = () => {
        const newScrollValue = childPanelRef.current.scrollLeft - 200;
        childPanelRef.current.scrollTo({
            left: newScrollValue,
            behavior: "smooth",
        })
        handleScrollable(newScrollValue);
    }

    const handleScrollable = (newScrollValue = childPanelRef?.current?.scrollLeft) => {
        if (childPanelRef && childPanelRef.current) {
            if ((newScrollValue + childPanelRef.current.clientWidth) >= childPanelRef.current.scrollWidth) {
                setHideRightScroll(true);
            } else {
                setHideRightScroll(false);
            }

            if (newScrollValue <= 0) {
                setHideLeftScroll(true);
            } else {
                setHideLeftScroll(false);
            }
        }
    }

    // if (!ctx.user) {
    //     console.log("BACK TO LOGIN")
    //     navigate({ pathname: `/login` });
    // }

    return (
        <div className={classes.innerContainer}>
            {(ctx.beneficiariesData.status !== 'SUCCESS' || !ctx.upcomingEventData || !ctx.notificationData) ?
                <Backdrop
                    className={classes.backDrop}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop> :
                <React.Fragment>
                    <div className={classes.mainInfo}>
                        <Paper sx={{
                            backgroundColor: 'transparent',
                            textAlign: 'center',
                            minHeight: '100%',
                            boxShadow: 'none',
                            padding: 0,
                            width: '100%'
                        }}
                            id={'leftUpper'}>

                            <div className={classes.leftUpperDiv}>
                                <>
                                    {!hideLeftScroll &&
                                        <div style={{
                                            height: '220px',
                                            width: '30px',
                                            position: 'absolute',
                                            background: 'transparent',
                                            zIndex: 99,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            left: -30
                                        }}>
                                            <img width="12px" style={{ cursor: 'pointer' }} src={ArrowLeft} onClick={scrollRight}></img>
                                        </div>
                                    }
                                    {!hideRightScroll && <div style={{
                                        height: '220px',
                                        width: '30px',
                                        position: 'absolute',
                                        background: 'transparent',
                                        zIndex: 99,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        right: -30
                                    }}>
                                        <img width="12px" style={{ cursor: 'pointer' }} src={ArrowRight} onClick={scrollLeft}></img>
                                    </div>
                                    }
                                </>

                                <Paper ref={childPanelRef} style={{
                                    overflow: 'hidden', height: 240,
                                    border: 'none', background: 'transparent', boxShadow: 'none'
                                }}>

                                    {(ctx.beneficiariesData.data.beneficiaries && ctx.beneficiariesData.data.beneficiaries.length) ?
                                        <div>
                                            <List style={{ display: 'inline-flex', paddingTop: 0, minWidth: '100%' }}>
                                                {
                                                    (ctx.beneficiariesData.data.beneficiaries) ? (
                                                        ctx.beneficiariesData.data.beneficiaries.map((child, index) => (
                                                            <ChildCard key={index} childDetail={child} childPhoto={getChildPhoto(child.id)} backgroundColor={getBackgroundColor(child.id)} textColor={getTextColor(child.id)} />
                                                        ))) : <div></div>
                                                }
                                                <AddChildCard />
                                            </List>
                                        </div> :
                                        <div className={classes.noChildPanel}>
                                            <div className={classes.noChildPanel_image}>
                                                <img src={NoChildImg} style={{ height: '110px', width: '80px' }} />
                                            </div>
                                            <div className={classes.noChildPanel_content}>
                                                <div style={{ textAlign: 'left', fontSize: 18, fontWeight: 600, paddingTop: 20 }}>Add Child</div>
                                                <div style={{ textAlign: 'left', fontSize: 18, lineHeight: '22px', paddingTop: 20 }}>Build your child's profile and start celebrating with friends & family.</div>
                                            </div>
                                            <div className={classes.noChildPanel_action}>
                                                <Button type={"submit"} variant="contained" disableElevation
                                                    style={{ width: '120px', background: '#0079D9', marginTop: 20, color: 'white' }}
                                                    onClick={addChildHandler}>
                                                    Add Child
                                                </Button>
                                            </div>
                                            {addChild ? <AddChildDialog visible={addChild} handleClose={handleClose} /> : <div />}
                                        </div>
                                    }
                                </Paper>
                            </div>
                            <div>
                                <HomeEventsComponent />
                            </div>
                        </Paper>
                    </div>
                    <div className={classes.walletInfo}>
                        <WalletBalance />
                    </div>
                </React.Fragment>
            }
        </div>
    );
}

export default HomePage;
