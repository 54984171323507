import { Button, Card, Dialog, makeStyles, TextField } from "@material-ui/core";
import AddIcon from '@mui/icons-material/Add';
import React, { useContext, useState } from "react";
import AddEventDialog from "./AddEventDialog";
import KidfolioContext from "../store/KidfolioContext";
import ChildCard from "./ChildCard";
import AddWalletDialog from "./AddWalletDialog";

const useStyles = makeStyles({
    card: {
        background: "#EBE9E9",
        width: '40px',
        height: '240px',
        margin: '0px 20px 0px 20px',
        borderRadius: '30px',
        boxShadow: "0px 4.478689670562744px 15.675413131713867px 6.718034744262695px rgba(255, 255, 255, 0.2) inset, -22.393447875976562px 105.24920654296875px 161.23281860351562px 0px rgba(128, 125, 125, 0.16);",
        transformStyle: "preserve-3d"
    }

});

function AddEventCard(props) {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const [showWallet, setShowWallet] = useState(false);
    const handleClose = () => {
        setVisible(false);
    }

    const actions = [
        <Button
            type="reset"
            label="Reset"
            secondary={true}
            style={{ float: 'left' }}
        />,
        <Button
            label="Cancel"
            primary={true}
            onClick={handleClose}
        />,
        <Button
            type="submit"
            label="Submit"
            primary={true}
        />,
    ];
    const ctx = useContext(KidfolioContext);





    const addEventHandler = () => {
        setVisible(true);
        const solanaWalletFromLoggedApi = ctx.loggedInUserDetails.paymentOptions ? ctx.loggedInUserDetails.paymentOptions?.find((paymentOption) => (
            (paymentOption.vendorName === 'Solana Pay' && paymentOption.identifier && paymentOption.identifier !== "")
        )) : null;

        const childDetail = ctx.beneficiariesData.data.beneficiaries.find((beneficiary) =>
            beneficiary.id === props.childId
        )
        const benPaymentOptions = childDetail ? childDetail.paymentOptions : ""
        const solanaWalletFromBens = benPaymentOptions ? benPaymentOptions.find((paymentOption) => (
            (paymentOption.vendorName === 'Solana Pay' && paymentOption.identifier && paymentOption.identifier !== "")
        )) : null;
        ((solanaWalletFromLoggedApi !== null || solanaWalletFromBens !== null) && (solanaWalletFromLoggedApi || solanaWalletFromBens)) ? setShowWallet(false) : setShowWallet(true);

    }
    // onClick={(e) => {addEventHandler(e.target)}}

    return (
        <div>
            <span onClick={addEventHandler} style={{ color: 'white' }}>New Event</span>
            {visible ? showWallet ? <AddWalletDialog childId={props.childId} handleShowWallet={setShowWallet} showWallet={showWallet} handleClose={handleClose} /> : <AddEventDialog childName={props.childName} childId={props.childId} visible={visible} handleClose={handleClose} /> : <div />}
        </div>

    )
}

export default AddEventCard;
