import { Container, makeStyles, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import * as React from "react";
import ForgotPasswordIcon from "../../assets/Forgot Password.svg"
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import { grey } from "@material-ui/core/colors";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    secondaryColor: {
        color: grey[700]
    },
    heading: {
        fontSize: 18,
        fontWeight: 600
    },
    noPadding: {
        paddingTop: '0px !important',
        paddingLeft: 0,
        paddingRight: 0
    },
    padding: {
        padding: 0
    },
    mainHeader: {
        alignItems: "center"
    },
    mainContent: {
        padding: 40
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    },
    inputClass: {
        '& label': {
            lineHeight: 0.7,
        },
        '& input': {
            fontSize: '12px'
        }
    }
});

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [emailTriggered, setEmailTriggered] = useState(false);
    const classes = useStyles();
    const url = window.location.href.split('/forgotPassword')[0];
    const actionCodeSettings = {
        url: `${url}/login`
    };

    function handlePasswordReset() {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email, actionCodeSettings)
            .then(() => {
                setEmailTriggered(true);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    }

    return (
        <div style={{
            padding: '8px',
            height: '100vh',
            background: 'transparent',
            backgroundColor: 'transparent',
            position: 'relative'
        }}>
            <Container maxWidth="sm" sx={{ border: 1, borderRadius: 2, gap: 2, margin: 4, mx: "auto", width: '30%', background: "#ffffff" }}>
                <Box sx={{ width: '90%', justifyContent: "center", padding: "auto", alignItems: "center", mx: "auto" }} >
                    <Stack spacing={2} direction="column"
                        justifyContent="center" alignItems="center">
                        <Paper sx={{
                            width: "347px",
                            background: "#FFFFFF",
                            border: "0.5px solid #D0D0D0",
                            boxSizing: "border-box",
                            padding: '20px',
                            boxShadow: 'none',
                            borderRadius: "6px"
                        }}>
                            <div style={{
                                display: 'flex',
                                minHeight: '6vh',
                                background: 'transparent',
                                backgroundColor: 'transparent',
                                position: 'relative',
                                justifyContent: "center"
                            }}>
                                <img src={ForgotPasswordIcon} />
                            </div>
                            <div style={{
                                padding: '12px',
                                display: 'flex',
                                minHeight: '10vh',
                                background: 'transparent',
                                backgroundColor: 'transparent',
                                position: 'relative',
                                justifyContent: "center"
                            }}>
                                <Grid Container spacing={2}>
                                    <Grid item xs={12} align="center" className={classes.padding}>
                                        <Typography variant={'h5'} style={{
                                            fontFamily: 'Montserrat',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '18px',
                                            lineHeight: '22px', margin: '1em 0 1em 0'
                                        }}>
                                            Forgot Password
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} align="center" className={classes.padding}>
                                        <Typography variant={'h5'} style={{
                                            fontFamily: 'Montserrat',
                                            fontStyle: 'normal',
                                            fontWeight: '310',
                                            fontSize: '14px',
                                            lineHeight: '22px', margin: '1em 0 1em 0'
                                        }}>
                                            Enter your email address to reset the password
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} align="center" className={classes.padding}>
                                        <div className="field">
                                            <TextField
                                                className={classes.inputClass}
                                                variant="outlined"
                                                type="text"
                                                label={<span style={{ fontSize: 12 }}>
                                                    Email Address
                                                </span>}
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}
                                                style={{ width: '100%', margin: '1em 0 1em 0' }}
                                                size="small"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} align="center" className={classes.padding} style={{ marginTop: 22 }}>
                                        <Button variant={'contained'} style={{ height: '36px', fontSize: '15px' }} fullWidth onClick={handlePasswordReset} disabled={emailTriggered}>
                                            Send Email
                                        </Button>
                                    </Grid>
                                    {emailTriggered ?
                                        <Grid item xs={12} align="center" className={classes.padding} style={{ marginTop: 30 }}>
                                            Password reset link has been sent to your email!
                                        </Grid> : <div></div>}
                                </Grid>
                            </div>
                        </Paper>
                    </Stack>
                </Box>
            </Container>
        </div>
    )
}

export default ForgotPassword;
