import {
    Box,
    Card,
    makeStyles,
    Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import React, { useContext, useEffect, useState } from "react";
import KidfolioContext from "../store/KidfolioContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useNotify } from "../utils/Notify";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import EditIcon from "../assets/EditIcon.svg";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import { getBackgroundColor, getColorCode } from "../utils/Util";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import ContributeGiftDialog from "../components/ContributeGiftDialog";
import CustomGiftDialog from "../components/CustomGiftDialog";
import AddIcon from '@mui/icons-material/Add';
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import VideocamIcon from '@mui/icons-material/Videocam';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Table from "@material-ui/core/Table";
import PaymentContent from "../components/ContributorPaymentContent";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ContributionDoneImage from "../assets/ContributionDoneImage.svg";
import InfoIcon from '@mui/icons-material/Info';
import axios from "axios";
import RemoveIcon from '@mui/icons-material/Remove';
import getFirebaseToken from "../utils/FirebaseToken";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../firebase";
import ContributorFlowDialog from "../components/Authentication/ContributorFlowDialog";

const useStyles = makeStyles({
    root: {
        padding: '8px',
        paddingTop: 20,
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        margin: 'auto'
    },
    giftCard: {
        width: '270px',
        height: '280px',
        margin: '16px',
        padding: 16,
        boxSizing: 'border-box'
    },
    card: {
        height: '60%',
    },
    giftAmount: {
        position: 'absolute',
        fontSize: 14,
        display: 'flex',
        background: '#3BFFD9',
        borderRadius: '4px',
        padding: '8px 25px',
        width: 80,
        boxSizing: 'border-box',
        justifyContent: 'center',
        marginLeft: 154,
        marginTop: 5
    },
    giftRemove: {
        position: 'absolute',
        fontSize: 10,
        display: 'flex',
        width: 10,
        height: 10,
        boxSizing: 'border-box',
        justifyContent: 'left'
    },
    videoInput: {
        display: 'none'
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    contriDone: {
        background: "#D0FFF1",
        boxShadow: "-10px 48.5967px 140px rgba(126, 123, 160, 0.2)",
        backdropFilter: "blur(19px)",
        borderRadius: "34px",
        height: '460px',
        padding: 60,
        display: 'flex',
        flexDirection: 'column',
        width: 420,
        margin: 20,
        marginRight: 0,
        boxSizing: 'border-box'
    },
})

function SendGift() {
    const classes = useStyles();
    const ctx = useContext(KidfolioContext);
    const [childData, setChildData] = useState(null);
    const [childEventData, setChildEventData] = useState(null);
    const [childEventInfo, setChildEventInfo] = useState(null);
    const [showAmountOptions, setShowAmountOptions] = useState(false);
    const [showAddCustomGift, setShowAddCustomGift] = useState(false);
    const [showPersonalizeGift, setShowPersonalizeGift] = useState(true);
    const [hideSummary, setHideSummary] = useState(true);
    const [giftName, setGiftName] = useState(null);
    const [colorIndex, setColorIndex] = useState(null);
    const [giftTargetAmt, setGiftTargetAmt] = useState(null);
    const [totalAmount, setTotalAmount] = useState(null);
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [message, setMessage] = useState(null);
    const [videoMessage, setVideoMessage] = useState(null);
    const [videoUploading, setVideoUploading] = useState(false);
    const [hideGifts, setHideGifts] = useState(false);
    const [guestContributionDone, setGuestContributionDone] = useState(false);

    const [showTryAgainDialog, setShowTryAgainDialog] = useState(false);
    const [showGuestLoginDialog, setShowGuestLoginDialog] = useState(false);
    const [giftsItemsList, setGiftsItemsList] = useState([]);
    const [customGiftsList, setCustomGiftsList] = useState([]);
    const { addCustomGift } = useContext(KidfolioContext);
    const [source, setSource] = useState();
    const [showFileSizeError, setShowFileSizeError] = useState(false);

    const [openPaymentModel, setOpenPaymentModel] = useState(false);
    const { addContribution, firebaseToken } = useContext(KidfolioContext);
    let params = useParams();
    const navigate = useNavigate();
    const [editChild, setEditChild] = useState(false);
    const [openChildDeleteConfirmModel, setOpenChildDeleteConfirmModel] = useState(false);
    const notify = useNotify();
    const inputRef = React.useRef();
    const baseURL = process.env.REACT_APP_APP_BASE_URL;
    const urlLocation = useLocation();

    useEffect(async () => {
        const response = await ctx.getEvent(params.eventId);
        const token = await getFirebaseToken();
        if (response && response.beneficiary) {
            setChildData(response.beneficiary);
            setChildEventInfo(response);
        }
    }, []);

    useEffect(async () => {
        if (ctx.authChecked) {
            const response = await ctx.getEvent(params.eventId);
            const token = await getFirebaseToken();
            if (response && response.beneficiary) {
                setChildData(response.beneficiary);
                setChildEventInfo(response);
            } else {
                const token = await getFirebaseToken();
                if (!token) {
                    // guestLogin();
                    setShowGuestLoginDialog(true);
                }
            }
        }
    }, [ctx.authChecked]);

    const guestLogin = async () => {
        try {
            const result = await signInWithEmailAndPassword(auth, 'guest@gifted.com', 'guest@gifted.com');
            ctx.setIsGuestUser(true);
            const response = await ctx.getEvent(params.eventId);
            if (response && response.beneficiary) {
                setChildData(response.beneficiary);
                setChildEventInfo(response);
                setShowGuestLoginDialog(false);
            }
        } catch (error) {
            return;
        }
    };

    const handleGuestLoginDialogClose = () => {
        setShowGuestLoginDialog(false);
    }

    // useEffect(async () => {
    //     if (ctx.childEventData) {
    //         console.log("ctx. childEventData: ", ctx.childEventData);
    //         const childEventData = ctx.childEventData.filter((event) => {
    //             return event.post ? event.post._id == params.eventId : null;
    //         });
    //         setChildEventData(childEventData);
    //         console.log("childEventData: ", childEventData);
    //     }
    // }, [ctx.childEventData]);

    // useEffect(() => {
    //     if (ctx.childEventData) {
    //         const eventId = params.eventId;
    //         const selectedEvent = ctx.childEventData.find((event) => {
    //             return event.post._id === eventId;
    //         });
    //         setChildEventInfo(selectedEvent);
    //     }
    // }, [ctx.childEventData]);

    // useEffect(async () => {
    //     if (!ctx.beneficiariesData || !ctx.beneficiariesData.data?.beneficiaries?.length) {
    //         await ctx.fetchChildren();
    //     } else {
    //         const childData = ctx.beneficiariesData.data?.beneficiaries?.filter((beneficiary) => {
    //             return beneficiary.id ? beneficiary.id == params.beneficiaryId : null;
    //         });
    //         setChildData(childData);
    //     }
    // }, [ctx.beneficiariesData]);


    useEffect(() => {
        return () => {
            setChildData(null);
            setChildEventData(null);
            ctx.resetChildPage();
        };
    }, []);

    const capitalizeFirstLetter = (string) => {
        if (!string) {
            return "";
        }
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function setAmountAndGifts(gift, index) {
        setGiftName(gift);
        // find in array and populate amt in selectedAmt
        const selectedGift = giftsItemsList?.find((giftItem) => {
            return giftItem.title === gift;
        });

        const customSelectedGift = customGiftsList?.find((giftItem) => {
            return giftItem.title === gift;
        });
        if (customSelectedGift) {
            setSelectedAmount(customSelectedGift ? customSelectedGift.amount : 0);
            setGiftTargetAmt(customSelectedGift ? customSelectedGift.targetAmount : 0);
        } else {
            setSelectedAmount(selectedGift ? selectedGift.amount : 0);
            setGiftTargetAmt(childEventInfo.targetAmounts[index]);
        }

        setColorIndex(index);
        setShowAmountOptions(true);
        calculateTotalAmount();
    }

    function getGiftImage(gift, index) {
        const colorCode = getColorCode(index);
        let name = null;
        if (gift) {
            name = gift;
        }
        return `sprite-${colorCode}-medium ${colorCode}_${name.substring(0, 1).toUpperCase()}`;
    }

    const getGiftImageBgColor = (index) => {
        const colorCode = getColorCode(index);
        return getBackgroundColor(colorCode);
    }

    const handleSelectedAmount = (amt) => {
        setSelectedAmount(amt);
        setShowAmountOptions(false);
        const temp = {
            "title": giftName,
            "amount": parseFloat(amt)
        };

        const selectedGift = giftsItemsList?.find((giftItem) => {
            return giftItem.title === giftName;
        });

        let replacementGiftsList = null
        let replacementCustomGiftsList = null
        if (selectedGift) {

            replacementGiftsList = giftsItemsList.map((giftItem) => {
                if (giftItem.title === giftName) {
                    return { ...giftItem, amount: parseFloat(amt) };
                } else {
                    return giftItem;
                }
            });
            setGiftsItemsList(replacementGiftsList);

            replacementCustomGiftsList = customGiftsList.map((giftItem) => {
                if (giftItem.title === giftName) {
                    return { ...giftItem, amount: parseFloat(amt), targetAmount: parseFloat(amt) };
                } else {
                    return giftItem;
                }
            });
            setCustomGiftsList(replacementCustomGiftsList);

        } else {
            replacementGiftsList = [...giftsItemsList, temp];
            setGiftsItemsList(replacementGiftsList);

        }



        let totalAmt = 0;
        replacementGiftsList.map((gift) => (
            totalAmt = totalAmt + gift.amount
        ));
        setTotalAmount(totalAmt);
    }

    const closeContriGiftDialog = () => {
        setShowAmountOptions(false);
    }

    const closeCustomGiftDialog = () => {
        setShowAddCustomGift(false);
    }

    const closePersonalizeGiftDialog = () => {
        setShowPersonalizeGift(false);
    }

    function addCustomGifts() {
        setShowAddCustomGift(true);
    }

    let videoId = null;
    const videoUpload = async e => {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];
        if (file.size > 25000000) {
            setShowFileSizeError(true);
        } else {
            setVideoUploading(true);
            setShowFileSizeError(false);
            reader.onloadend = () => {
                const url = URL.createObjectURL(file);
                setSource(url);
                /*
                setFile(file);
                setImagePreviewUrl(reader.result);
                setImageUpdated(true);
                setImageUploadDialogOpen(true);*/
            }
            reader.readAsDataURL(file);


            const formData = new FormData();
            formData.append('file', file);
            formData.append('content-type', 'multipart/form-data');

            await axios.post(
                `${baseURL}/resourceApi/resources`, formData,
                {
                    headers: {
                        "x-firebase-auth": firebaseToken,
                        "content-type": 'multipart/form-data'
                    }
                }
            ).then((res) => {
                videoId = res.data.id;
                setVideoMessage(videoId);
                setVideoUploading(false);
            });
        }
    }

    const handleChoose = (event) => {
        inputRef.current.click();
    };

    function getSelectedAmt(gift) {
        const selectedGift = giftsItemsList?.find((giftItem) => {
            return giftItem.title === gift;
        });
        return selectedGift ? selectedGift.amount : 0;
    }

    function displayAmt(gift) {
        const selectedGift = giftsItemsList?.find((giftItem) => {
            return giftItem.title === gift;
        });
        return selectedGift;
    }

    function handleCustomGift(gift, amt) {
        //set selected gift
        setSelectedAmount(amt);
        setShowAmountOptions(false);
        setShowAddCustomGift(false);
        const giftItemList = {
            "title": gift,
            "amount": parseFloat(amt)
        }
        const temp = {
            "title": gift,
            "targetAmount": parseFloat(amt),
            "amount": parseFloat(amt)

        };
        // set giftItem list
        const replacementGiftsList = [...giftsItemsList, giftItemList];
        setGiftsItemsList(replacementGiftsList);
        const replacementCustomGiftsList = [...customGiftsList, temp];
        setCustomGiftsList(replacementCustomGiftsList);
        // create custom gift

        let totalAmt = 0;
        replacementGiftsList.map((gift) => (
            totalAmt = totalAmt + gift.amount
        ));
        setTotalAmount(totalAmt);

    }

    function getProgressPercent(selectedAmt, targetAmount) {
        return ((selectedAmt / targetAmount) * 100).toFixed(0);
    }

    function calculateTotalAmount() {
        let totalAmt = 0;
        giftsItemsList.map((gift) => (
            totalAmt = totalAmt + gift.amount
        ));
        //setTotalAmount(totalAmt);
        return totalAmt;
    }

    function openPaymentModelHandler() {
        setOpenPaymentModel(true);
    }

    async function submitPersonalizeDetails() {
        //construct object
        // post to db
        // post to this params.EventId
        const contributeGiftObjectWithoutPayment = {
            "contributorMessage": {
                "personalMessage": message ? message : null,
                "videoMessageId": videoMessage ? videoMessage : null
            },
            "paymentOption": {
                "vendorName": childEventInfo.paymentOptions ? childEventInfo.paymentOptions[0].vendorName : null,
                "identifier": childEventInfo.paymentOptions ? childEventInfo.paymentOptions[0].identifier : null
            }
        }

        await addContribution(params.eventId, contributeGiftObjectWithoutPayment);
        setOpenPaymentModel(false);
        if (ctx.isGuestUser()) {
            setGuestContributionDone(true);
            await signOut(auth);
            ctx.avictData();
            return;
        }
        navigate({
            pathname: '/home'
        });
    }

    function closeModelHandler() {
        setOpenPaymentModel(false);
    }

    async function closePaymentModelHandler() {
        setOpenPaymentModel(false);
        const contributeGiftobject = {
            "amount": parseFloat(totalAmount),
            "contributorMessage": {
                "personalMessage": message ? message : null,
                "videoMessageId": videoMessage ? videoMessage : null
            },
            "giftItems": giftsItemsList,
            "paymentOption": {
                "vendorName": childEventInfo.paymentOptions ? childEventInfo.paymentOptions[0].vendorName : null,
                "identifier": childEventInfo.paymentOptions ? childEventInfo.paymentOptions[0].identifier : null
            }
        };
        await addContribution(params.eventId, contributeGiftobject);
        if (ctx.isGuestUser()) {
            setGuestContributionDone(true);
            await signOut(auth);
            ctx.avictData();
            return;
        }
        navigate({
            pathname: '/home'
        });
    }

    function showPersonalizeSection() {
        setShowPersonalizeGift(false);
        setHideSummary(true);
    }

    function showSummarySection() {
        setShowPersonalizeGift(true);
        setHideSummary(false);
    }

    function sendPersonalizeData() {
        setOpenPaymentModel(false);
        setShowTryAgainDialog(true);
    }

    function closeModalAndRemoveGifts() {
        setHideGifts(true);
        setShowTryAgainDialog(false);
    }

    function getTargetAmt(giftTitle) {
        let targetAmt;
        const selectedGift = childEventInfo.giftItems?.find((giftItem) => {
            return (giftItem === giftTitle);
        });
        targetAmt = childEventInfo.giftItems?.find((giftItem, index) => {
            return (giftItem === giftTitle ? childEventInfo.targetAmounts[index] : "");
        });

        const targetAmtCustom = customGiftsList?.find((giftItem) => {
            return (giftItem.title === giftTitle);
        });

        if (targetAmt > 0) {
            return targetAmt;
        } else if (targetAmtCustom) {
            return targetAmtCustom.targetAmount;
        } else "";


        //return (targetAmt ? targetAmt : targetAmtCustomList);
        return 10;
    }

    function backToGiftsHandler() {
        setShowPersonalizeGift(true);
        setHideSummary(true);
    }

    function backToPersonalizeHandler() {
        setShowPersonalizeGift(false);
        setHideSummary(true);
    }

    function removeVideo() {
        setSource(null);
    }

    function removeGiftItem(giftItem) {
        console.log("giftItem", giftItem);
        const foundIndex = giftsItemsList.findIndex(gift => gift.title === giftItem);

        let replacementGiftsList = [...giftsItemsList];
        console.log("before replacementGiftsList: ", replacementGiftsList);
        if (foundIndex > -1) {
            replacementGiftsList.splice(foundIndex, 1);
            setGiftsItemsList(replacementGiftsList);

            console.log("replacementGiftsList: ", replacementGiftsList);
        }

        const foundIndexCustomList = customGiftsList.findIndex(gift => gift.title === giftItem);

        if (foundIndexCustomList > -1) {
            let replacementCustomGiftList = [...customGiftsList];
            console.log("before replacementCustomGiftList: ", replacementCustomGiftList);
            replacementCustomGiftList.splice(foundIndexCustomList, 1);
            setCustomGiftsList(replacementCustomGiftList);
            console.log("replacementCustomGiftList: ", replacementCustomGiftList);
        }

        let totalAmt = 0;
        replacementGiftsList.map((gift) => (
            totalAmt = totalAmt + gift.amount
        ));
        setTotalAmount(totalAmt);

    }

    function gotoSignup() {
        navigate({
            pathname: `/signUp`,
        })
    }

    return (
        <div className={classes.root}>
            {(childData && childEventInfo && !guestContributionDone) ?
                <Grid container>
                    <Grid item xs={12}>
                        <Paper style={{
                            borderRadius: 16,
                            background: '#CCE4F7',
                            minHeight: '10em',
                            alignItems: "center", justifyContent: "center", display: "flex"
                        }}>
                            <div style={{ width: '100%' }}>
                                <div>
                                    <Typography style={{
                                        verticalAlign: 'middle', fontFamily: "Montserrat",
                                        fontSize: "32px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "22px",
                                        letterSpacing: "0px",
                                        marginBottom: "0",
                                        marginTop: "12px",
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}> {capitalizeFirstLetter(childData.profile.fullName)}
                                    </Typography>
                                    <Typography style={{
                                        verticalAlign: 'middle', fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "22px",
                                        letterSpacing: "0px",
                                        marginBottom: "0",
                                        marginTop: "12px",
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}> {childEventInfo.title} ({dayjs(childData.profile.birthDate).format("MMM DD, YYYY")})
                                    </Typography>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginRight: '25px'
                                }} hidden={hideGifts}>
                                    {totalAmount > 0 ? <div style={{
                                        padding: '8px 25px',
                                        boxSizing: 'border-box',
                                        marginTop: 10,
                                        height: '35px',
                                        backgroundColor: '#3BFFD9',
                                        fontSize: '13px',
                                        borderRadius: 4
                                    }}>  ${totalAmount} <span style={{ fontSize: '10px' }}>Total</span></div> : <div></div>}
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} hidden={!showPersonalizeGift || !hideSummary}>
                        <div>
                            <Typography style={{
                                verticalAlign: 'middle', fontFamily: "Montserrat",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "22px",
                                letterSpacing: "0px",
                                marginBottom: "0",
                                marginTop: "40px",
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#000000'
                            }}>Gift List
                            </Typography><br />
                            <div style={{ display: 'flex', flexWrap: 'wrap', width: '80%', margin: 'auto' }}>
                                {(childEventInfo) ? childEventInfo.giftItems ? childEventInfo.giftItems.map((gift, index) => (
                                    <Paper className={classes.giftCard}  >
                                        {displayAmt(gift) && <div className={classes.giftRemove}  > <CloseIcon
                                            onClick={e => removeGiftItem(gift)}
                                            style={{
                                                background: '#c1c6c5',
                                                borderRadius: '50px',
                                                fontSize: 19,
                                                cursor: 'pointer'
                                            }} /></div>}
                                        <Card variant="elevation" elevation="0" className={classes.card} onClick={() => setAmountAndGifts(gift, index)} style={{ backgroundColor: `${getGiftImageBgColor(index)}` }}>
                                            {displayAmt(gift) ? <div className={classes.giftAmount}> ${getSelectedAmt(gift)}</div> : <div></div>}
                                            {
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                    cursor: 'pointer'
                                                }}>
                                                    <div className={getGiftImage(gift, index)}></div>
                                                </div>
                                            }
                                        </Card>

                                        <Typography style={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '20px',
                                            letterSpacing: '0em',
                                            marginTop: 25,
                                            textAlign: 'left'
                                        }}>{capitalizeFirstLetter(gift)}</Typography>
                                        <Typography style={{
                                            fontWeight: 400,
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                            letterSpacing: '0em',
                                            marginTop: 10,
                                            textAlign: 'left',
                                            color: '#666666'
                                        }}>Goal ${childEventInfo.targetAmounts[index]} ({getProgressPercent(getSelectedAmt(gift), childEventInfo.targetAmounts[index])}% Target Reached)</Typography>
                                    </Paper>
                                )) : <div></div> : <div></div>}

                                {(customGiftsList.length > 0) ? customGiftsList.map((gift, index) => (
                                    <Paper className={classes.giftCard}  >
                                        {displayAmt(gift.title) && <div className={classes.giftRemove}  > <CloseIcon
                                            onClick={e => removeGiftItem(gift.title)}
                                            style={{
                                                background: '#c1c6c5',
                                                borderRadius: '50px',
                                                fontSize: 19,
                                                cursor: 'pointer'
                                            }} /></div>}
                                        <Card variant="elevation" elevation="0" className={classes.card} onClick={() => setAmountAndGifts(gift.title, index)} style={{ backgroundColor: `${getGiftImageBgColor(index)}` }}>
                                            {displayAmt(gift.title) ? <div className={classes.giftAmount}> ${getSelectedAmt(gift.title)}</div> : <div></div>}
                                            {
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                    cursor: 'pointer'
                                                }}>
                                                    <div className={getGiftImage(gift.title, index)}></div>
                                                </div>
                                            }
                                        </Card>

                                        <Typography style={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '20px',
                                            letterSpacing: '0em',
                                            marginTop: 25,
                                            textAlign: 'left'
                                        }}>{capitalizeFirstLetter(gift.title)}</Typography>
                                        <Typography style={{
                                            fontFamily: "Montserrat",
                                            fontWeight: 400,
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                            letterSpacing: '0em',
                                            marginTop: 10,
                                            textAlign: 'left'
                                        }}>Goal ${gift.targetAmount} ({getProgressPercent(getSelectedAmt(gift.title), gift.targetAmount)}% Target Reached)</Typography>
                                    </Paper>
                                )) : <div></div>}

                                <Paper className={classes.giftCard} onClick={() => addCustomGifts()}>
                                    <Card variant="elevation" elevation="0" className={classes.card} style={{ backgroundColor: `${getGiftImageBgColor(1)}` }}>
                                        {
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                cursor: 'pointer'
                                            }}>
                                                <AddIcon />
                                            </div>
                                        }
                                    </Card>
                                    <div style={{
                                        height: '40%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <Typography style={{
                                            fontWeight: 400,
                                            fontSize: '16px',
                                        }}>Add your own gift</Typography>
                                    </div>
                                </Paper>

                            </div>
                            <Grid item xs={12} align="center">
                                <Button type={"submit"} variant="contained" disableElevation
                                    style={{ background: '#0079D9', marginTop: 30, height: 36, width: 120 }}
                                    onClick={() => showPersonalizeSection()}
                                >
                                    <Typography style={{ color: '#ffffff' }}>Next</Typography>
                                </Button>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid item xs={12} hidden={showPersonalizeGift} >
                        <Grid container style={{ justifyContent: 'center' }}>
                            <Grid item xs={12} align="center" >
                                <div style={{ fontSize: 20, fontWeight: 300, color: "#000000", marginTop: 40 }}> Personalize the Gift</div>
                            </Grid>


                            <Grid item xs={12} style={{ marginTop: 40, maxWidth: '80%' }}>
                                <div style={{ fontWeight: 400, paddingTop: 5, color: "#000000", textAlign: "left" }}>
                                    <MessageRoundedIcon style={{ color: "#0079D9" }} /> Message
                                </div>

                                <Grid item xs={12} style={{ marginTop: 10, height: 100, color: classes.editAmountColor }}>
                                    <TextField
                                        required
                                        multiline
                                        rows={4}
                                        style={{ height: '100px', width: '100%' }}
                                        color="#3BFFD9"
                                        margin="dense"
                                        variant="outlined"
                                        label="Add your message here"
                                        id="message"
                                        onChange={(event) => {
                                            setMessage(event.target.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: 60, maxWidth: '80%' }}>
                                <div style={{ fontWeight: 400, paddingTop: 5, color: "#000000", textAlign: "left", marginBottom: 20 }}>
                                    <VideocamIcon style={{ color: "#0079D9" }} /> Video (Optional - less than 25MB)
                                </div>

                                <Grid item xs={12} align="center">
                                    <input
                                        ref={inputRef}
                                        className={classes.videoInput}
                                        type="file"
                                        onChange={videoUpload}
                                        accept=".mov,.mp4"
                                    />
                                    {!source && !videoUploading && <div
                                        onClick={handleChoose}
                                        style={{
                                            color: 'black',
                                            width: '100%',
                                            border: 'solid 1px gray',
                                            height: 150,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <div>
                                            <div align="center"><AddIcon /></div>
                                            <div> Upload your video</div>
                                        </div>
                                    </div>
                                    }
                                    {videoUploading &&
                                        <div style={{
                                            color: 'black',
                                            fontWeight: 400,
                                            width: '100%',
                                            border: 'solid 1px gray',
                                            height: 150,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                            Uploading video...
                                        </div>
                                    }
                                    {
                                        showFileSizeError ? <div style={{ color: "#e21212", marginTop: 20 }}>The file size is greater than the accepted limit. Video size should be less than 25MB</div> :
                                            (!videoUploading && source && source !== null) && (
                                                <div>
                                                    <video
                                                        className="VideoInput_video"
                                                        //style={{height:'100px', width: '300px'}}
                                                        controls
                                                        width="700px"
                                                        height="310px"
                                                        src={source} />
                                                    <div>
                                                        <IconButton style={{ color: '#000000' }}>
                                                            <CloseIcon onClick={removeVideo} />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            )

                                    }
                                </Grid>
                            </Grid>


                            <Grid item xs={12} align="center" >
                                <div item xs={12} align="center" >
                                    <Button variant="text" style={{ width: '20%', marginLeft: 10, marginTop: 60 }}
                                        onClick={backToGiftsHandler}>&lt; Back</Button>
                                    <Button type={"submit"} variant="contained" disabled={!message}
                                        style={{ width: '120px', background: '#0079D9', marginTop: 60 }}
                                        onClick={() => showSummarySection()}>
                                        <Typography style={{ color: '#ffffff' }}>Next</Typography>
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} hidden={hideSummary} >
                        <Grid container style={{ marginTop: 20 }}>
                            <Grid item xs={12} align="center" >
                                <div style={{ fontSize: 20, fontWeight: 300, color: "#000000", marginTop: 20 }}> Summary</div>
                            </Grid>

                            {giftsItemsList && giftsItemsList.length > 0 && <Grid hidden={hideGifts} xs={12} style={{
                                fontSize: 14,
                                marginTop: 20,
                                display: 'flex',
                                marginLeft: 'auto',
                                borderRadius: '4px',
                                padding: '8px 25px',
                                width: '20px',
                                height: '80px',
                                boxSizing: 'border-box',
                                justifyContent: 'center'
                            }}>
                                <div hidden={!(giftsItemsList.length > 0)}>
                                    <Paper hidden={hideGifts} style={{ width: '535px', height: '110px', margin: '1em 1em 1em 1em', background: '#3BFFD9', borderRadius: '10px' }} >
                                        <CardGiftcardIcon style={{ color: "#0079D9", fontWeight: 400, marginTop: '3px' }} />
                                        <Typography style={{ marginLeft: '14px', marginBottom: '6px' }}> Total Gift Amount</Typography>
                                        <Typography>{totalAmount > 0 ? <div align="center" >  ${totalAmount}</div> : <div></div>}</Typography>

                                    </Paper>
                                </div>

                            </Grid>}


                            {giftsItemsList && giftsItemsList.length > 0 && <Grid hidden={hideGifts} xs={12}>
                                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '80%', margin: 'auto', marginTop: 80 }}>
                                    {(giftsItemsList.length > 0) ? giftsItemsList.map((gift, index) => (
                                        <Paper className={classes.giftCard} >
                                            <Card variant="elevation" elevation="0" className={classes.card} style={{ backgroundColor: `${getGiftImageBgColor(index)}` }}>
                                                {displayAmt(gift.title) ? <div className={classes.giftAmount}> ${getSelectedAmt(gift.title)}</div> : <div></div>}
                                                {
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        height: '100%'
                                                    }}>
                                                        <div className={getGiftImage(gift.title, index)}></div>
                                                    </div>
                                                }
                                            </Card>

                                            <Typography style={{
                                                fontFamily: "Montserrat",
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                                letterSpacing: '0em',
                                                marginTop: 25,
                                                textAlign: 'left'
                                            }}>{capitalizeFirstLetter(gift.title)}</Typography>
                                            <Typography style={{
                                                fontFamily: "Montserrat",
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                                letterSpacing: '0em',
                                                marginTop: 10,
                                                textAlign: 'left',
                                                color: '#666666'
                                            }}>Goal ${getTargetAmt(gift.title)} ({getProgressPercent(getSelectedAmt(gift.title), getTargetAmt(gift.title))}% Target Reached)</Typography>
                                        </Paper>
                                    )) : <div></div>}
                                </div>
                            </Grid>
                            }

                            <Grid item xs={12} style={{ marginTop: 30, alignItems: 'center', marginBottom: 50 }}>
                                <div style={{ fontWeight: 400, paddingTop: 5, color: "#000000" }}>
                                    <MessageRoundedIcon style={{ color: "#0079D9", position: 'relative' }} /> Message
                                </div>
                                <div xs={12} style={{ alignItems: 'center', marginTop: 30, marginBottom: 5, color: "#000000" }}>{message}</div>
                            </Grid>

                            {source && <Grid item xs={12} align="center" style={{ alignItems: "center" }}>
                                (<Grid item xs={12}>
                                    <div style={{
                                        fontWeight: 400,
                                        paddingTop: 5,
                                        color: "#000000",
                                        marginBottom: 20
                                    }}>
                                        <VideocamIcon style={{ color: "#0079D9" }} /> Video
                                    </div>

                                    <Grid item xs={12} align="center" style={{ marginTop: 30 }}>
                                        {source && (
                                            <video
                                                className="VideoInput_video"
                                                //style={{height:'100px', width: '300px'}}
                                                controls
                                                width="700px"
                                                height="310px"
                                                src={source}
                                            />
                                        )}
                                        <div className="VideoInput_footer">{source || ""}</div>
                                    </Grid>
                                </Grid>)
                            </Grid>
                            }



                            <Grid item xs={12} align="center">
                                <Grid item xs={12} align="center" hidden={hideGifts || giftsItemsList.length === 0}>
                                    <div item xs={12} align="center" >
                                        <Button variant="text" style={{ width: '20%', marginLeft: 10, marginTop: 60 }}
                                            onClick={backToPersonalizeHandler}>&lt; Back</Button>
                                        <Button type={"submit"} variant="contained"
                                            style={{ width: '150px', background: '#0079D9', marginTop: 60 }}
                                            onClick={() => openPaymentModelHandler()}>
                                            <Typography style={{ color: '#ffffff' }}>Checkout</Typography>
                                        </Button>
                                        <div style={{ marginLeft: 250, marginTop: 25, width: '234px', fontWeight: 400, fontSize: '14px', color: '#9D9C9C' }}>Payment will be made via Solanapay</div>

                                    </div>
                                </Grid>
                                <Grid item xs={12} align="center" hidden={!hideGifts && (giftsItemsList.length > 0)}>
                                    <div item xs={12} align="center" >
                                        <Button variant="text" style={{ width: '20%', marginLeft: 10, marginTop: 60 }}
                                            onClick={backToPersonalizeHandler}>&lt; Back</Button>
                                        <Button type={"submit"} variant="contained"
                                            style={{ width: '150px', background: '#0079D9', marginTop: 60 }}
                                            onClick={() => submitPersonalizeDetails()}>
                                            <Typography style={{ color: '#ffffff' }}>Submit</Typography>
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>

                        {openPaymentModel ? <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={openPaymentModel}
                            onClose={closeModelHandler}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}>
                            <Box style={{ width: '40%', background: '#ffffff' }}>
                                <PaymentContent childName={capitalizeFirstLetter(childData.profile.fullName)} receiverWalletAddress={childEventInfo.paymentOptions[0].identifier} amount={totalAmount} onSuccess={closePaymentModelHandler} onFailure={sendPersonalizeData} onClose={closeModelHandler} />
                            </Box>
                        </Modal> : <div />}
                    </Grid>

                </Grid>
                :
                (!guestContributionDone && !showGuestLoginDialog) &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }

            {showAmountOptions ? <ContributeGiftDialog giftName={giftName} currentAmount={selectedAmount} targetAmount={giftTargetAmt} index={colorIndex} handleSelectedAmount={handleSelectedAmount} visible={showAmountOptions} handleClose={closeContriGiftDialog} /> : <div />}

            {showAddCustomGift ? <CustomGiftDialog handleCustomGift={handleCustomGift} visible={showAddCustomGift} handleClose={closeCustomGiftDialog} /> : <div />}

            {
                showTryAgainDialog ? <Dialog
                    disableEnforceFocus

                    open={showTryAgainDialog}
                    onClose={closeModalAndRemoveGifts}
                >
                    <DialogContent style={{ height: '100px' }}>
                        <Grid container>
                            <Grid item xs={12} style={{ height: '70px' }}>
                                <Grid item >
                                    <InfoIcon style={{ color: '#f1cf69' }} /> <span style={{ width: '900px' }}>The transaction is failed. Do you want to send the gift without money or try again later?</span>
                                </Grid>
                            </Grid>
                            <div style={{ alignItems: 'right', marginLeft: 400, background: '#0079D9', borderRadius: '5px', color: '#ffffff' }}>
                                <Button style={{ color: '#ffffff' }} contained onClick={closeModalAndRemoveGifts}>Try again later</Button>
                            </div>
                        </Grid>
                    </DialogContent>
                </Dialog> : <div />
            }
            {
                guestContributionDone &&
                <div className={classes.contriDone}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={ContributionDoneImage} style={{
                            width: '100px'
                        }} />
                        <div style={{
                            fontSize: 18, lineHeight: '26px', marginBottom: 40, width: 175, color: '#1A1A1A',
                            fontWeight: 400, marginTop: 30
                        }}>Thanks for your contribution</div>
                        <div style={{ width: 270, marginBottom: 40, fontSize: 16, color: '#666666', fontWeight: 325 }}>You can signup for Gifted and start exploring</div>
                        <div>
                            <Button
                                variant="contained"
                                style={{
                                    height: 32,
                                    backgroundColor: "#0096FF",
                                    color: 'white'
                                }}
                                onClick={gotoSignup}
                            >
                                Sign Up
                            </Button>
                        </div>
                    </div>
                </div>
            }

            {
                showGuestLoginDialog ?
                    <ContributorFlowDialog
                        guestLogin={guestLogin}
                        eventId={params.eventId}
                        toPath={urlLocation.pathname}
                        setChildData={setChildData}
                        setChildEventInfo={setChildEventInfo}
                        handleGuestLoginDialogClose={handleGuestLoginDialogClose} /> : <div />
            }

        </div >
    )
};

export default SendGift;
