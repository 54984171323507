import React, { useContext, useRef, useState } from "react";
import { Dialog, makeStyles, Typography } from "@material-ui/core";
import DefaultProfile from "../../assets/no-profile-picture.svg";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@material-ui/core/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import axios from "axios";
import KidfolioContext from "../../store/KidfolioContext";
import AddChildDialog from "../AddChildDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadIcon from "../../assets/icons8-upload (1).gif";
import ImageCrop from "../Common/ImageCrop";
import { useNavigate } from "react-router-dom";
import AddProfileImage from "../../assets/AddProfileImage.svg";

// import classes from "./ProfileCard.module.css";
const useStyles = makeStyles({
    heading: {
        fontSize: 18,
        fontWeight: 600
    },
    card: {
        width: '310px',
        height: '627px',
        padding: '15px',
        marginTop: '40px',
        borderRadius: '25px',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '15px 10px 25px 0px  #3fa1a9',
        backgroundSize: '40%, 80%',
        animation: 'open .5s'
    },
    form: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    field: {
        margin: '5px',
        display: 'flex',
        flexDirection: 'column'
    },
    // input[type="file"]: {
    //     display: 'none';
    // }
    customFileUpload: {
        borderRadius: '50%',
        display: 'inline-block',
        position: 'relative',
        padding: '6px',
        cursor: 'pointer',
        background: 'linear-gradient(270deg, #3fa1a9, #79f1a4)',
        marginBottom: '25px'
    },
    imgWrap: {
        position: 'relative',
        width: '200px',
        height: '200px',
        overflow: 'hidden',
        borderRadius: '50%',
    },
    imgUpload: {
        "&.before": {
            content: "\f093",
            fontSize: '90px',
            position: 'absolute',
            paddingTop: '80px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: '#63d3a6',
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            opacity: '0',
            transition: '.5s ease',
            backgroundColor: '#fff',
        }
    },
    // .imgUpload:hover:before{
    //     opacity: 1,
    // }
    img: {
        width: 'auto',
        height: '100%',
    },
    photoMsg: {
        fontSize: 13,
        color: "#666666",
        marginBottom: 10,
        marginTop: 10,
    },
    label: {
        textTransform: 'uppercase',
        fontWeight: '700',
        color: '#676767',
    },
    input: {
        borderRadius: '15px',
        border: '1px solid #b7b7b7',
        padding: '5px 5px 5px 10px',
        fontSize: '18px',
        transition: '0.2s',

        "&.focus": {
            outline: 'none',
            border: '1px solid #64d488',
        }
    },
    name: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: '700',
        color: '#676767',
        maxWidth: '220px',
        overflowWrap: 'break-word',
    },
    button: {
        position: 'relative',
        color: '#054231',
        letterSpacing: '1px',
        margin: '20px',
        fontSize: '18px',
        padding: '10px',
        textAlign: 'center',
        transition: '0.5s',
        borderRadius: '10px',
        cursor: 'pointer',
        border: 'none',
        background: '#64d488',
    },
    save: {
        fontWeight: '600',
        left: '-20px',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        width: '20px',
        boxShadow: '20px 0px 40px 0px  #63d3a6',
    },
    edit: {
        color: '#fff',
        width: '180px',
    },
    // .ldsRipple {
    //     display: inline-block,
    //     position: relative,
    //     width: 64px,
    //     height: 64px,
    // }
    // .ldsRipple div {
    //     position: absolute,
    //     border: 4px solid #fff,
    //     opacity: 1,
    //     border-radius: 50%,
    //     animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite,
    // }
    // .ldsRipple div:nth-child(2) {
    //     animation-delay: -0.5s,
    // }
    // .faInstagram{
    //     position: absolute,
    //     color: #79f1a4,
    //     top: 3%,
    //     right: 2%,
    //     font-size: 38px,
    // }
    // .faInstagram:hover{
    //     font-size: 42px,
    //     color: #caff81,
    //     transition: all .1s linear,
    //     cursor: pointer,
    // }
})

const ImgUpload = ({
    onChange,
    src
}) =>
    <label htmlFor="photo-upload">
        <div style={{
            width: '250px', height: '250px'
        }}>
            <img width='250px' height='250px' for="photo-upload" src={src} style={{ borderRadius: '150px' }} />
        </div>
        <input id="photo-upload" type="file" onChange={onChange} hidden={true} />
    </label>


const BirthDate = ({
    setUserDOB, value, userDOBRequiredError, setUserDOBRequiredError
}) =>
    <div className={"field"} style={{ justifyContent: "center", textAlign: "center", paddingTop: '10px' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                disableFuture
                label="Birth Date"
                openTo="day"
                views={["year", "month", "day"]}
                value={value}
                onChange={(newValue) => {
                    setUserDOB(newValue);
                    setUserDOBRequiredError(false);
                }}
                renderInput={(params) => <TextField
                    {...params}
                    error={userDOBRequiredError}
                    helperText={userDOBRequiredError ? "User's Birthday is required" : ' '}
                    fullWidth
                    margin="dense"
                    variant="outlined" />}
            />
        </LocalizationProvider>
    </div>


const SolanaWalletAddress = ({
    setSolanaWalletAddress,
    value
}) =>
    <div className="field">
        <TextField
            variant="outlined"
            type="email"
            label="Solana Wallet Address"
            value={value}
            onChange={setSolanaWalletAddress}
            style={{ width: '100%' }}
            size="small"
        />
    </div>

// const Profile =({
//                     onSubmit,
//                     src,
//                     date
//                 })=>
//     <div className={"card"} style={{
//         width: "310px",
//         height: "627px",
//         padding: "15px",
//         marginTop: "40px",
//         borderRadius: "25px",
//         display: "flex",
//         justifyContent: "center",
//         boxShadow: "15px 10px 25px 0px  #3fa1a9",
//         background: "#fff url('https://github.com/OlgaKoplik/CodePen/blob/master/leaf2.png?raw=true'), url('https://github.com/OlgaKoplik/CodePen/blob/master/leaf.png?raw=true') no-repeat, no-repeat 120% -5%, 200% -40%",
//         backgroundSize: "40%, 80%",
//         animation: "open .5s"
//     }}>
//         <form onSubmit={onSubmit}>
//             <h1>Profile Card</h1>
//             <label className={"custom-file-upload fas"}>
//                 <div style={{
//                     width: '250px', height: '250px'
//                 }} >
//                     <img width='250px' height='250px' for="photo-upload" src={src}/>
//                 </div>
//             </label>
//             <div className={"date"}>{date}</div>
//             <Button type="submit" className={"edit"}>Edit Profile </Button>
//         </form>
//     </div>


const Edit = ({
    onSubmit, isProcessing,
    children,
}) =>
    <div className={"card"}>
        <form onSubmit={onSubmit}>
            <h1>Profile Card</h1>
            {children}
            <br />
            {!isProcessing ? <Button type="submit" className={"save"} fullWidth>Save</Button> :
                <LoadingButton loading loadingIndicator="Processing..." variant="contained"
                    style={{ width: '100%', marginTop: 20 }}>
                    Processing...
                </LoadingButton>}
        </form>
    </div>

function ProfileCard(props) {
    const [file, setFile] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState(AddProfileImage);
    const [visible, setVisible] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [userDOB, setUserDOB] = useState(new Date());
    const [userDOBRequiredError, setUserDOBRequiredError] = useState(false);
    const [solanaWalletAddress, setSolanaWalletAddress] = useState('');
    const [solanaWalletAddressError, setSolanaWalletAddressError] = useState(false);
    const [imageUploaded, setImageUploaded] = useState(false);
    const ctx = useContext(KidfolioContext);
    const classes = useStyles();
    const { fetchUserDetail } = useContext(KidfolioContext);
    const imageComponent = useRef(null);
    const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
    const baseURL = process.env.REACT_APP_APP_BASE_URL;
    const navigate = useNavigate();

    // const [oldImagePreviewUrl, setOldImagePreviewUrl] = useState(DefaultProfile);

    // const addUploadPreviewIcon = value => {
    //     setOldImagePreviewUrl(imagePreviewUrl);
    //     setImagePreviewUrl(UploadIcon);
    // }
    //
    // const removeUploadPreviewIcon = value => {
    //     // setOldImagePreviewUrl(imagePreviewUrl);
    //     setImagePreviewUrl(oldImagePreviewUrl);
    // }

    const photoUpload = e => {
        e.preventDefault();
        const reader = new FileReader();
        console.log("File");
        console.log(e.target.files);
        console.log(e.target.files[0]);
        const file = e.target.files[0];
        reader.onloadend = () => {
            setFile(file);
            setImagePreviewUrl(reader.result);
            setImageUploaded(true);
            setImageUploadDialogOpen(true);
        }
        reader.readAsDataURL(file);
    }

    const handleDialogClose = () => {
        setImageUploadDialogOpen(false);
    }

    const editUserDOB = value => {
        console.log("Date");
        console.log(value);
        setUserDOB(value);
    }

    const addSolanaWalletAddress = value => {
        console.log("Value");
        console.log(value)
        setSolanaWalletAddress(value);
    }

    const editUserDOBRequiredError = () => {
        setUserDOBRequiredError(false);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setIsProcessing(true);
        let validationFailed = false;
        if (!userDOB) {
            setUserDOBRequiredError(true);
            validationFailed = true;
        }
        if (validationFailed) {
            return;
        }
        let imageId = null;
        // const userResult = await axios.post(
        //     `${baseURL}/userApi/users`, {
        //     "fullName": props.fullName,
        //     "phoneNumber": '',
        //     "emailId": props.email,
        //     "location": {
        //         "city": "",
        //         "state": ""
        //     }
        // },
        //     {
        //         headers: {
        //             "x-firebase-auth": ctx.firebaseToken
        //         }
        //     }
        // ).then(async response => {
        //     const formData = new FormData();
        //     formData.append('file', file);
        //     console.log("file");
        //     console.log(file);
        //     console.log(file.size);
        //     formData.append('content-type', 'multipart/form-data');
        //     if (file.size > 0) {
        //         const res = await axios.post(
        //             `${baseURL}/resourceApi/resources`, formData,
        //             {
        //                 headers: {
        //                     "x-firebase-auth": ctx.firebaseToken,
        //                     "content-type": 'multipart/form-data'
        //                 }
        //             }
        //         )
        //         imageId = res.data.id;
        //     }
        //     setImageUploaded(false);
        //     await axios.put(
        //         `${baseURL}/userApi/users/${response.data.id}`, {
        //         "fullName": props.fullName,
        //         "phoneNumber": '',
        //         "emailId": props.email,
        //         "birthDate": userDOB,
        //         "location": {
        //             "city": "",
        //             "state": ""
        //         },
        //         "imageId": imageId
        //     },
        //         {
        //             headers: {
        //                 "x-firebase-auth": ctx.firebaseToken
        //             }
        //         }
        //     ).then(async res => {
        //         await fetchUserDetail();
        //     })
        // }
        // ).then(async response => {
        //     setIsProcessing(false);
        //     setVisible(true);
        // })
        const userResult = await updateUserDetails().then(async response => {
            setIsProcessing(false);
            setVisible(true);
        })
    }

    const updateUserDetails = async () => {
        let imageId = null;
        const userId = ctx.registeredId;
        // console.log("Register User ID", userId);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('content-type', 'multipart/form-data');
        // console.log("Firebase", ctx.firebaseToken);
        if (file.size > 0) {
            const res = await axios.post(
                `${baseURL}/resourceApi/resources`, formData,
                {
                    headers: {
                        "x-firebase-auth": ctx.firebaseToken ? ctx.firebaseToken : props.token,
                        "content-type": 'multipart/form-data'
                    }
                }
            )
            imageId = res.data.id;
        }
        setImageUploaded(false);
        await axios.put(
            `${baseURL}/userApi/users/${userId}`, {
            "fullName": props.fullName,
            "phoneNumber": '',
            "emailId": props.email,
            "birthDate": userDOB,
            "location": {
                "city": "",
                "state": ""
            },
            "imageId": imageId
        },
            {
                headers: {
                    "x-firebase-auth": ctx.firebaseToken
                }
            }
        ).then(async res => {
            let paymentObj = [
                {
                    "vendorName": 'Solana Pay', // Ideally this should be fetched from payments config
                    "identifier": solanaWalletAddress
                }
            ]
            await ctx.updateSolanaAddressForUser(paymentObj);
            await fetchUserDetail();
        }).then(async response => {
            setIsProcessing(false);
            setVisible(true);
        })
    }

    const closeChildDialog = () => {
        setVisible(false);
        navigate({
            pathname: `/home`
        })
    }

    return (
        <div>
            <div className={"card"}>
                <form onSubmit={handleSubmit}>
                    <Typography className={classes.heading} variant="h5" style={{ textAlign: "center" }}>
                        Add a profile picture
                    </Typography>
                    <label htmlFor="photo-upload">
                        <div style={{
                            width: '100%', height: '250px', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                            <img height='90px' width='90px' htmlFor="photo-upload" src={imagePreviewUrl}
                                style={{ borderRadius: '150px', cursor: 'pointer' }} ref={imageComponent} />
                            {/*</ReactCrop>*/}
                            {/*// <img height= '90px' width='90px' htmlFor="photo-upload" src={AddProfileImage}*/}
                            {/*//      style={{borderRadius: '150px', cursor:'pointer', width: '90px', height: '90px'}}/>}*/}
                            {/*<input id="photo-upload" type="file" onChange={photoUpload} hidden={true}/>*/}
                            <input id="photo-upload" type="file" onChange={photoUpload} hidden={true} />
                            <Typography className={classes.photoMsg}>
                                Photo is optional
                            </Typography>
                        </div>
                    </label>
                    {/*<ImgUpload onChange={photoUpload} src={imagePreviewUrl}/>*/}
                    <BirthDate
                        setUserDOB={editUserDOB}
                        value={userDOB}
                        style={{ fontSize: '12px' }}
                        userDOBRequiredError={userDOBRequiredError}
                        setUserDOBRequiredError={editUserDOBRequiredError}
                    />
                    <div className="field">
                        <TextField
                            variant="outlined"
                            type="text"
                            label={<Typography style={{ fontSize: 12 }}>
                                Solana Wallet Address (Optional)
                            </Typography>}
                            value={solanaWalletAddress}
                            onChange={(e) => {
                                console.log(e.target.value)
                                setSolanaWalletAddress(e.target.value)
                            }}
                            style={{ width: '100%', fontSize: '12px' }}
                            size="small"
                        />
                    </div>
                    {/*<SolanaWalletAddress*/}
                    {/*    value={solanaWalletAddress}*/}
                    {/*    setSolanaWalletAddress={addSolanaWalletAddress}/>*/}
                    {visible ? <AddChildDialog visible={visible} handleClose={closeChildDialog} /> : <div />}
                    <br />
                    {!isProcessing ? <Button variant="contained" type="submit" className={"save"} fullWidth style={{ height: '36px', fontSize: 15 }}>Save</Button> :
                        <LoadingButton loading loadingIndicator="Processing..." variant="contained"
                            style={{ width: '100%', height: '36px', fontSize: 15 }}>
                            Processing...
                        </LoadingButton>}
                </form>
            </div>
            <Dialog open={imageUploadDialogOpen} maxWidth="xs" disableEnforceFocus>
                <ImageCrop img={imagePreviewUrl} handleClose={handleDialogClose} handleCancel={handleDialogClose} setImageUrl={setImagePreviewUrl} setFile={setFile} />
            </Dialog>
        </div>
    );
}

export default ProfileCard;