import { Divider, Typography } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import ProgressBar from "../../Common/ProgressBar";
import GiftIconWallet from "../../../assets/GiftIconWallet.svg";
import Link from "@mui/material/Link";
import React, { useContext, useEffect, useState } from "react";
import KidfolioContext from "../../../store/KidfolioContext";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { Grid } from "@mui/material";
import { getBackgroundColor, getColorCode } from "../../../utils/Util";

function EventPageGiftList(props) {
    const ctx = useContext(KidfolioContext);
    const beneficiaryWalletAddress = props.beneficiaryWalletAddress;
    const [giftList, setGiftList] = useState(null);
    const [totalGiftAmount, setTotalGiftAmount] = useState(0);
    let gf = [];
    let temp = [];

    const extractGiftItems = async () => {
        gf = ctx.eventContributorsData.flatMap((gift, index) => {
            return [gift.contributions.map((item, index) => {
                if (item.giftItems) {
                    return item.giftItems
                }
                return []
            })]
        });
    }

    const extractAllGiftItems = async () => {
        if (props.childEvent && props.childEvent?.post?.giftItems) {
            props.childEvent?.post?.giftItems?.forEach((it, index) => {
                temp.push({ amount: 0, title: it })
            });
        }
    }

    useEffect(async () => {
        if (ctx.eventContributorsData) {
            await extractGiftItems();
            await extractAllGiftItems();
            const val = [].concat.apply([], gf).filter(e => e.length);
            val ? val.flatMap((it, index) => {
                return it.forEach((innerIt, index) => {
                    temp.push(innerIt);
                })
            }) : [];
            const finList = temp ? temp.reduce((finList, item) => (
                {
                    ...finList,
                    [item.title]: [...(finList[item.title] || []), item]
                }), {}) : [];
            const giftsList = [];
            for (let title in finList) {
                if (finList.hasOwnProperty(title)) {
                    let total = 0;
                    finList[title].forEach((value, index) => {
                        total = total + Math.round((value.amount + Number.EPSILON) * 100) / 100;
                    })
                    giftsList.push({ item: title, amount: total });
                }
            }
            setGiftList(giftsList);
            let totalGift = 0;
            giftsList.forEach((gift) => {
                totalGift += gift.amount
            });
            setTotalGiftAmount(totalGift);
        }
    }, [])

    function getGiftImage(gift, index) {
        const colorCode = getColorCode(index);
        return `sprite-${colorCode}-extraSmall ${colorCode}_${gift.substring(0, 1).toUpperCase()}`;
    }

    const getEventBgColor = (index) => {
        const colorCode = getColorCode(index);
        return getBackgroundColor(colorCode);
    }

    return (
        <Paper sx={{
            width: '314px',
            background: "white",
            padding: '15px',
            textAlign: 'center',
            minHeight: '75vh',
            height: '75vh',
            boxShadow: 'none',
            borderRadius: '25px',
            overflow: 'scroll'
        }}>
            {(!ctx.eventContributorsData) ?
                <Backdrop
                    open={true}
                    style={{ zIndex: 1000 }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <div>
                    <div style={{ minHeight: '35vh' }}>
                        <Typography variant={'h6'} style={{
                            fontFamily: "Montserrat",
                            fontStyle: "bold",
                            fontWeight: "700",
                            lineHeight: "22px",
                            letterSpacing: "0px",
                            textAlign: "center",
                            color: "#000000",
                            paddingTop: "20px",
                            paddingBottom: "20px"
                        }}>
                            Balance
                        </Typography>
                        <div
                            style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '1em' }}>
                            <Paper style={{ width: '210px', height: '110px', alignItems: 'center', borderRadius: '1em', backgroundColor: '#4BFFD9' }}>
                                <img src={GiftIconWallet} width={'20px'} height={'24px'} style={{ marginTop: '12px' }} />
                                <div style={{
                                    fontStyle: "normal",
                                    fontWeight: "325",
                                    fontSize: 14,
                                    padding: 10,
                                    color: "#000000"
                                }}>Total Gift Amount</div>
                                <div style={{
                                    fontStyle: "bold",
                                    fontWeight: "350",
                                    fontSize: 18,
                                    color: "#000000"
                                }}>${totalGiftAmount}</div>
                            </Paper>
                        </div>
                        <div style={{ justifyContent: 'center', marginTop: 30, marginBottom: 20 }}>
                            <Typography variant={'h6'} style={{
                                fontStyle: "bold",
                                fontWeight: "700",
                                fontSize: 14,
                                lineHeight: "22px",
                                letterSpacing: "0px",
                                color: "#000000",
                                paddingTop: "1em"
                            }}>
                                Wallet Address
                            </Typography>
                            <Typography variant={'h6'} style={{
                                fontWeight: "400",
                                fontSize: '14px',
                                color: "#000000",
                                paddingTop: "1em"
                            }}>
                                {(beneficiaryWalletAddress !== 'NA') ? beneficiaryWalletAddress.slice(0, 2) + '*****************' + beneficiaryWalletAddress.slice(beneficiaryWalletAddress.length - 2) : 'NA'}
                                {/*6s1MuhvF8udKHSvxCoUrkA52fFTeXhKvknnr7ooCG8qK*/}
                            </Typography>
                            {/*<Typography variant={'h6'} style={{*/}
                            {/*    fontFamily: "Montserrat",*/}
                            {/*    fontStyle: "bold",*/}
                            {/*    fontWeight: "500",*/}
                            {/*    lineHeight: "22px",*/}
                            {/*    fontSize: '1em',*/}
                            {/*    color: "#000000",*/}
                            {/*    paddingTop: "1em"*/}
                            {/*}}>*/}
                            {/*    /!*TODO: Connect to appropriate component/create component for below functionalities  *!/*/}
                            {/*    <Link>Edit Address</Link>&nbsp;/&nbsp;*/}
                            {/*    <Link>Add Fund</Link>*/}
                            {/*</Typography>*/}
                        </div>
                    </div>
                    <Divider />
                    <div style={{ minHeight: '35vh', marginLeft: '1em', overflow: "scroll" }}>
                        <Typography variant={'h6'} style={{
                            fontStyle: "bold",
                            fontSize: 18,
                            fontWeight: "700",
                            letterSpacing: "0px",
                            textAlign: "left",
                            color: "#000000",
                            paddingTop: 20,
                            paddingBottom: 20
                        }}>
                            Gift List
                        </Typography>
                        <div style={{ overflow: "scroll" }}>
                            {giftList ? giftList.map((gift, index) => (
                                <div style={{ marginTop: 20 }}>
                                    <Grid container >
                                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{
                                                float: 'left',
                                                width: 50,
                                                height: 50,
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderRadius: 12,
                                                justifyContent: 'center',
                                                backgroundColor: `${getEventBgColor(index)}`,
                                            }}>
                                                <div className={getGiftImage(gift.item, index)} style={{ zoom: '0.9' }}></div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }} >
                                            <Typography variant={'h6'} style={{
                                                fontWeight: "500",
                                                fontSize: 13,
                                                color: "#273240",
                                                paddingLeft: "1em"
                                            }}>
                                                {gift.item}
                                            </Typography>
                                            <br />
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            {/*<div style={{ justifyContent:'center', height: '100%', display: "flex", alignItems: "center" }}>*/}
                                            <Typography variant={'h6'} style={{
                                                fontWeight: "600",
                                                textAlign: "right",
                                                color: "#273240",
                                                fontSize: 13,
                                            }}>
                                                ${`${Math.round((gift.amount + Number.EPSILON) * 100) / 100}`}
                                            </Typography>
                                            {/*</div>*/}
                                        </Grid>
                                    </Grid>
                                </div>
                            )) : <div></div>}
                        </div>
                    </div>
                </div>
            }
        </Paper>
    );
}

export default EventPageGiftList;