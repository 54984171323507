import { Card, makeStyles } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CakeImage from "../assets/cakeFigma.svg"
import dayjs from "dayjs";
import KidfolioContext from "../store/KidfolioContext";
import { getBackgroundColor } from "../utils/Util";

const useStyles = makeStyles({
    card: {
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        width: '250px',
        height: '250px',
        transformStyle: "preserve-3d",
        boxShadow: "0px 4.478689670562744px 15.675413131713867px 6.718034744262695px #FFFFFF33 inset, -22.393447875976562px 105.24920654296875px 161.23281860351562px 0px #FFFFFF33",
    },
    eventImg: {
        height: '100%',
        width: '100%',
        borderRadius: '15px',
        position: 'absolute',
        left: 0
    },
    eventDtl: {
        position: "absolute",
        left: "0px",
        right: "0px",
        padding: 20,
    }
})
export default function EventDetailsCard(props) {

    const classes = useStyles();
    const navigate = useNavigate();
    const ctx = useContext(KidfolioContext);

    const handleClickEvent = (x) => {
        navigate({
            pathname: `/child/${props.childDetails.post.beneficiaryId}/events/${props.childDetails.post._id}`,
        });
    }

    const capitalizeFirstLetter = (string) => {
        if (!string) {
            return string;
        }
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const getEventImage = () => {
        return props.childDetails.post.defaultImage;
    }

    const getEventImageColorClass = () => {
        const eventId = props.childDetails.post._id;
        const eventName = props.childDetails.post.title;
        const colorCode = ctx.eventImageColorCode[eventId];
        return `sprite-${colorCode}-large ${colorCode}_${eventName.substring(0, 1).toUpperCase()}`;
    }

    const getEventBgColor = () => {
        let bgColor = 'none';
        if (!getEventImage()) {
            const eventId = props.childDetails.post._id;
            const colorCode = ctx.eventImageColorCode[eventId];
            bgColor = getBackgroundColor(colorCode);
        }
        return bgColor
    }

    const getContentStyles = () => {
        if (getEventImage()) {
            return { bottom: 0, opacity: 0.8, background: 'white' };
        }
        return { bottom: 0 };
    }

    return (
        <Card border="none" className={classes.card} style={{ backgroundColor: `${getEventBgColor()}` }} onClick={(e) => { handleClickEvent(e.target) }}>
            <> {getEventImage() ?
                <img src={getEventImage()} className={classes.eventImg} />
                : <div className={getEventImageColorClass()} style={{ zoom: '0.70', paddingBottom: 25 }}></div>
            }
            </>
            <div className={classes.eventDtl} style={getContentStyles()}>
                <div style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    textAlign: "left",
                }}>{capitalizeFirstLetter(props.childDetails.post.title)}</div>
                <div style={{
                    paddingTop: 5,
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "left",
                }}>{dayjs(props.childDetails.post.dateTime).format("MMM DD, YYYY")}</div>
            </div>
        </Card >
    )
}

