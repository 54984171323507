import React, { useContext, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { teal, grey } from "@material-ui/core/colors";
import CloseIcon from '@mui/icons-material/Close';
import KidfolioContext from "../store/KidfolioContext";
import { getBackgroundColor, getColorCode } from "../utils/Util";
import dayjs from "dayjs";
import CircularProgress from '@mui/material/CircularProgress';


const styles = theme => ({
    root: {
        flexGrow: 1
    },
    secondaryColor: {
        color: grey[700]
    },
    heading: {
        fontSize: 18,
        fontWeight: 600
    },
    dialogPadding: {
        padding: 16
    },
    padding: {
        padding: 0
    },
    mainContent: {
        padding: 40
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    },
    topSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    msgHeader: {
        fontSize: 16,
        fontWeight: 600,
        paddingBottom: 15
    },
    msgText: {
        fontSize: 16,
        fontWeight: 400,
        paddingBottom: 10
    },
    contributorImg: {
        borderRadius: '15px',
        width: 60
    },
    contributorDtl: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        alignItems: 'center'
    },
    profile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '15px',
    },
    name: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: 15
    },
    gift: {
        fontSize: 14,
        display: 'flex',
        marginLeft: 'auto',
        background: '#3BFFD9',
        borderRadius: '4px',
        padding: '8px',
        boxSizing: 'border-box',
        justifyContent: 'center'
    },
    divider: {
        marginBottom: '15px',
        marginTop: '15px'
    },
    giftPanel: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        padding: 10,
        background: '#FFFFFF',
        boxShadow: '0px 0px 10px rgb(0 0 0 / 12%)',
        borderRadius: '16px'
    },
    giftImage: {
        width: "80px",
        height: "80px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        float: "left",
        marginRight: 20,
        borderRadius: '20px'
    },
    giftName: {
        fontSize: 14,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    giftAmount: {
        fontSize: 14,
        display: 'flex',
        marginLeft: 'auto',
        background: '#3BFFD9',
        borderRadius: '4px',
        padding: '8px 25px',
        width: 80,
        boxSizing: 'border-box',
        justifyContent: 'center',
    }
});

function ContributionDialog(props) {

    const { classes, open } = props;
    const ctx = useContext(KidfolioContext);
    const [contributorImage, setContributorImage] = useState(null);
    const [videoUrl, setVideoUrl] = useState([]);

    useEffect(async () => {
        let videoUrl = [];
        if (props.contributions && props.contributions.length) {
            props.contributions.forEach(contri => {
                if (contri.contributorMessage && contri.contributorMessage.videoMessageId) {
                    videoUrl.push({
                        videoMessageId: contri.contributorMessage.videoMessageId,
                        videoBlobUrl: '',
                        videoLoading: true,
                        isError: false
                    });
                }
            })
        }
        if (videoUrl.length) {
            let promises = [];
            for (let index = 0; index < videoUrl.length; index++) {
                const videoObj = videoUrl[index];
                promises.push(ctx.fetchVideoById(videoObj.videoMessageId));
            }
            const result = await Promise.allSettled(promises);
            result.map((promise, indx) => {
                if (promise.status === 'fulfilled') {
                    videoUrl[indx].videoBlobUrl = promise.value;
                    videoUrl[indx].videoLoading = false;
                    videoUrl[indx].isError = false;
                } else if (promise.status === 'rejected') {
                    videoUrl[indx].videoBlobUrl = "";
                    videoUrl[indx].videoLoading = false;
                    videoUrl[indx].isError = true;
                }
            })
            setVideoUrl(videoUrl);
        }
    }, []);

    useEffect(async () => {
        if (props.contributor.profile.imageId) {
            const imageUrl = await ctx.fetchImageById(props.contributor.profile.imageId);
            setContributorImage(imageUrl);
        }
    }, []);

    const isVideoLoading = (videoId) => {
        let loading = true;
        const video = videoUrl.find(item => {
            return item.videoMessageId === videoId;
        })
        if (video) {
            loading = video.videoLoading;
        }
        return loading;
    }

    const getVideoUrl = (videoId) => {
        const video = videoUrl.find(item => {
            return item.videoMessageId === videoId;
        })
        if (video) {
            return video.videoBlobUrl;
        }
        return "";
    }

    const videoError = (videoId) => {
        const video = videoUrl.find(item => {
            return item.videoMessageId === videoId;
        })
        if (video) {
            return video.isError;
        }
        return true;
    }

    const getEventContriImageColorClass = () => {
        const emailId = props.contributor.profile.emailId;
        const userName = props.contributor.profile.fullName;
        const colorCode = ctx.eventContributorImageColorCode[emailId];
        return `sprite-${colorCode}-small ${colorCode}_${userName.substring(0, 1).toUpperCase()}`;
    }

    const getEventContriBgColor = () => {
        let bgColor = '';
        if (!contributorImage) {
            const emailId = props.contributor.profile.emailId;
            const colorCode = ctx.eventContributorImageColorCode[emailId];
            bgColor = getBackgroundColor(colorCode);
        }
        return bgColor
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function getGiftImage(gift, index) {
        const colorCode = getColorCode(index);
        return `sprite-${colorCode}-small ${colorCode}_${gift.substring(0, 1).toUpperCase()}`;
    }

    const getGiftImageBgColor = (index) => {
        const colorCode = getColorCode(index);
        return getBackgroundColor(colorCode);
    }

    const getTotalAmount = () => {
        let totalAmount = 0;
        props.contributions.map(item => {
            if (item.amount) {
                totalAmount = totalAmount + item.amount;
            }
        })
        return totalAmount;
    }

    return (
        <React.Fragment>
            <Dialog
                disableEnforceFocus
                className={classes.root}
                maxWidth="xs"
                open={props.visible}
                onClose={() => props.handleClose}
            >
                <DialogContent className={classes.dialogPadding}>
                    <Grid container>
                        <Grid item xs={12} align="right" className={classes.topSection}>
                            <div style={{ fontSize: 20, fontWeight: 600 }}> Gift Contributor</div>
                            <IconButton
                                edge="start"
                                align="right"
                                color="inherit"
                                aria-label="Close"
                                style={{ padding: 8 }}
                            >
                                <CloseIcon onClick={props.handleClose} />
                            </IconButton>
                        </Grid>

                        <Grid item xs={12} className={classes.contributorDtl} >
                            <div className={classes.profile}
                                style={{ backgroundColor: `${getEventContriBgColor()}`, padding: `${contributorImage ? '' : '15px'}` }}>
                                <> {contributorImage ?
                                    <img src={contributorImage} className={classes.contributorImg} />
                                    : <div className={getEventContriImageColorClass()} style={{ zoom: 0.9 }} ></div>
                                }
                                </>
                            </div>
                            <div className={classes.name}>
                                <div>
                                    {capitalizeFirstLetter(props.contributor.profile.fullName)}
                                </div>
                                <div style={{ fontSize: 14, fontWeight: 400, paddingTop: 10 }}>
                                    {(props.contributions.length > 1) ? 'Multiple Gifts' : dayjs(props.contributions[0].createdOn).format("DD MMM, YYYY")}
                                </div>
                            </div>
                            <div className={classes.gift}>
                                Total Gift: ${getTotalAmount()}
                            </div>
                        </Grid>
                        {props.contributions.map((item, index) => (
                            <div key={index} style={{ width: '100%' }}>
                                <Divider className={classes.divider} />
                                <div style={{ fontSize: 14, paddingBottom: 20 }}>
                                    {(props.contributions.length > 1) && `Date: ${dayjs(item.createdOn).format("DD MMM, YYYY")}`}
                                </div>


                                {item.giftItems && item.giftItems.length &&
                                    item.giftItems.map((giftItem, ind) => (
                                        <Grid key={ind} item xs={12}>
                                            <div className={classes.giftPanel}>
                                                <div className={classes.giftImage} style={{
                                                    backgroundColor: `${getGiftImageBgColor(ind)}`
                                                }}>
                                                    <div className={getGiftImage(giftItem.title, ind)}></div>
                                                </div>
                                                <div className={classes.giftName} >{giftItem.title}</div>
                                                <div className={classes.giftAmount}>${giftItem.amount}</div>
                                            </div>
                                        </Grid>
                                    ))
                                }


                                {item.contributorMessage
                                    && (item.contributorMessage.personalMessage || item.contributorMessage.videoMessageId) &&
                                    <Grid item xs={12}>
                                        <Grid container direction="row">
                                            <div className={classes.msgHeader}>Wishes</div>
                                        </Grid>
                                    </Grid>
                                }
                                {item.contributorMessage && item.contributorMessage.personalMessage &&
                                    <Grid item xs={12}>
                                        <Grid container direction="row">
                                            <div className={classes.msgText}>{item.contributorMessage.personalMessage}</div>
                                        </Grid>
                                    </Grid>
                                }
                                {item.contributorMessage && item.contributorMessage.videoMessageId &&
                                    !videoError(item.contributorMessage.videoMessageId) &&
                                    !isVideoLoading(item.contributorMessage.videoMessageId) &&
                                    <Grid item xs={12}>
                                        <video width="100%" height="310px" controls>
                                            <source src={getVideoUrl(item.contributorMessage.videoMessageId)} type="video/mp4" />
                                        </video>
                                    </Grid>
                                }
                                {item.contributorMessage && item.contributorMessage.videoMessageId
                                    && isVideoLoading(item.contributorMessage.videoMessageId) &&
                                    <Grid item xs={12}>
                                        <div style={{
                                            width: '100%', height: 300, display: 'flex', alignItems: 'center',
                                            justifyContent: 'center', fontSize: 14
                                        }}>
                                            Loading video...
                                            <CircularProgress size={20} color="inherit" />
                                        </div>
                                    </Grid>
                                }
                                {item.contributorMessage && item.contributorMessage.videoMessageId &&
                                    !isVideoLoading(item.contributorMessage.videoMessageId) &&
                                    videoError(item.contributorMessage.videoMessageId) &&
                                    <Grid item xs={12}>
                                        <div style={{ fontSize: 14, color: 'red' }}>Error occurred while loading video</div>
                                    </Grid>
                                }
                            </div>
                        ))
                        }
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
}

export default withStyles(styles)(ContributionDialog);
