import { Button, Container, makeStyles, Typography } from "@material-ui/core";
import video from '../../assets/Kidfolio-video.mp4';
import Grid from "@material-ui/core/Grid";
import { TextField } from "@mui/material";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
    bannerContent: {
        display: "flex",
        flexDirection: "column",
        width: '85%',
        height: "auto",
        marginBottom: "100px"
    },
    tagline: {
        display: "flex",
        height: "40%",
        flexDirection: "column",
        justifyContent: "left",
        textAlign: "left",
        float: 'left'
    },
    carousel: {
        height: "50%",
        display: "flex",
        alignItems: "center",
    },
}));

function Banner() {
    const classes = useStyles();

    return (
        <div className={classes.bannerContent}>
            <Grid container spacing={1} style={{ background: 'transparent', width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                <Grid item xs={6} style={{ minWidth: 300 }}>
                    <div className={classes.tagline} style={{ float: 'left' }}>
                        <br /><br />
                        <Typography
                            variant="h2"
                            style={{
                                fontWeight: "200 Regular",
                                marginBottom: 15,
                                fontFamily: "Montserrat",
                                color: '#000000',
                                float: 'left'
                            }}
                        >
                            Gifting for <br /><strong> the future. </strong>
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            style={{
                                color: "grey",
                                textTransform: "capitalize",
                                fontFamily: "Montserrat",
                            }}
                        >
                            How about contributing to an experience, helping a child save for college or teaching financial literacy with an investment?
                            <br /><br />
                            A new way to give more meaningful gifts. <strong>Gifted <i>(by Kidfolio)</i> is gifting re-imagined!</strong>
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <video src={video} width="100%" height="400" controls>
                    </video>
                </Grid>
            </Grid>
        </div>
    );
}

export default Banner;
