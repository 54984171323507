import React, { useContext, useState, useEffect } from "react";
import { Card, Typography, withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import { grey } from "@material-ui/core/colors";
import CloseIcon from '@mui/icons-material/Close';
import KidfolioContext from "../store/KidfolioContext";
import { getBackgroundColor, getColorCode } from "../utils/Util";
import Button from "@mui/material/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import GiftIcon from "../assets/gift.svg";


const styles = theme => ({
    card: {
        width: '100%',
        height: '100%',
    },
    editAmountColor: {
        color: '#3BFFD9'
    },
    secondaryColor: {
        color: grey[700]
    },
    heading: {
        fontSize: 18,
        fontWeight: 600
    },
    dialogPadding: {
        padding: '0px !important',
        background: '#FAFAFA',
        width: 400
    },
    padding: {
        padding: 0
    },
    mainContent: {
        padding: 40
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    },
    topSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 20
    },
    msgHeader: {
        fontSize: 16,
        fontWeight: 600,
        paddingBottom: 15
    },
    msgText: {
        fontSize: 16,
        fontWeight: 400,
        paddingBottom: 10
    },
    contributorImg: {
        borderRadius: '50px',
        width: 60
    },
    contributorDtl: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        alignItems: 'center'
    },
    profile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50px',
    },
    name: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: 15
    },
    gift: {
        fontSize: 14,
        display: 'flex',
        marginLeft: 'auto',
        background: '#3BFFD9',
        borderRadius: '4px',
        padding: '8px',
        boxSizing: 'border-box',
        justifyContent: 'center'
    },
    giftPanel: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        padding: 10,
        background: '#FFFFFF',
        boxShadow: '0px 0px 10px rgb(0 0 0 / 12%)',
        borderRadius: '16px'
    },
    giftImage: {
        width: "80px",
        height: "80px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        float: "left",
        marginRight: 20,
        borderRadius: '20px'
    },
    giftName: {
        fontSize: 14,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    giftAmount: {
        fontSize: 14,
        display: 'flex',
        marginLeft: 'auto',
        background: '#3BFFD9',
        borderRadius: '4px',
        padding: '8px 25px',
        width: 80,
        boxSizing: 'border-box',
        justifyContent: 'center',
    }
});

function CustomGiftDialog(props) {

    const { classes, open } = props;
    const ctx = useContext(KidfolioContext);
    const [contributorImage, setContributorImage] = useState(null);
    const [videoUrl, setVideoUrl] = useState([]);
    const [selectedGiftName, setSelectedGiftName] = useState(null);
    const [selectedAmt, setSelectedAmt] = useState(null);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function getGiftImage(gift, index) {
        const colorCode = getColorCode(index);
        return `sprite-${colorCode}-medium ${colorCode}_${gift.substring(0, 1).toUpperCase()}`;
    }

    const getGiftImageBgColor = (index) => {
        const colorCode = getColorCode(index);
        return getBackgroundColor(colorCode);
    }

    const calculateProgress = () => {
        return (selectedAmt / props.targetAmount) * 100;
    }

    return (
        <React.Fragment>
            <Dialog
                disableEnforceFocus
                maxWidth="xs"
                open={props.visible}
                onClose={() => props.handleClose}
            >
                <DialogContent className={classes.dialogPadding}>
                    <Grid>
                        <Grid item xs={12} align="right" className={classes.topSection}>
                            <div style={{ fontSize: 20, fontWeight: 600 }}> Add your own gift</div>
                            <IconButton
                                edge="start"
                                align="right"
                                color="inherit"
                                aria-label="Close"
                                style={{ padding: 0 }}
                            >
                                <CloseIcon onClick={props.handleClose} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '0px 20px 30px 20px' }} >
                            <Card variant="elevation" elevation="0" className={classes.card} >
                                {
                                    <div>
                                        <img src={GiftIcon} style={{
                                            width: '100%'
                                        }} />
                                    </div>
                                }
                            </Card>
                        </Grid>

                        <Grid item xs={12} style={{ padding: '30px 20px 0px 20px ', background: 'white' }}>
                            <div style={{ fontWeight: 400, paddingTop: 5 }}>
                                Name of the gift
                            </div>

                            <Grid item xs={12} style={{ marginTop: 15, height: 80, color: classes.editAmountColor }}>
                                <TextField
                                    fullWidth
                                    color="#3BFFD9"
                                    margin="dense"
                                    variant="outlined"
                                    label="Enter Name"
                                    id="amount"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EditIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(event) => {
                                        setSelectedGiftName(event.target.value);
                                    }}
                                />
                            </Grid>


                        </Grid>

                        <Grid item xs={12} style={{ padding: '0px 20px 20px 20px ', background: 'white' }}>
                            <div style={{ fontWeight: 400, paddingTop: 5 }}>
                                Choose an amount for the gift
                            </div>

                            {
                                (ctx.amountOptionsData.contributionOptions.USD) ?
                                    ctx.amountOptionsData.contributionOptions.USD.map((amtConfig) => (
                                        <Button
                                            value={amtConfig}
                                            onClick={e => setSelectedAmt(amtConfig)}
                                            variant="text"
                                            style={{
                                                height: 40,
                                                width: 50,
                                                marginRight: 15,
                                                marginTop: 15,
                                                float: "center",
                                                color: "#000000",
                                                backgroundColor: selectedAmt === amtConfig ? "#3BFFD9" : "#ffffff",
                                                border: "0.5px solid #3BFFD9"
                                            }}>
                                            ${amtConfig}
                                        </Button>
                                    )) : <div>Nope</div>
                            }

                            <Grid item xs={12} style={{ marginTop: 30, height: 80, color: classes.editAmountColor }}>
                                <TextField
                                    fullWidth
                                    color="#3BFFD9"
                                    margin="dense"
                                    variant="outlined"
                                    label="Enter Amount"
                                    id="amount"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EditIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(event) => {
                                        setSelectedAmt(event.target.value);
                                    }}
                                />
                            </Grid>


                        </Grid>

                        <Grid item xs={12} align="right" style={{ padding: '0px 20px 20px', background: 'white' }} >
                            <Button disabled={!selectedAmt || !selectedGiftName}
                                type={"submit"} variant="contained" disableElevation
                                style={{ height: '36', background: '#0079D9', marginTop: 15 }}
                                onClick={() => props.handleCustomGift(selectedGiftName, selectedAmt)}
                            >
                                <Typography style={{ color: '#ffffff' }}>Add to Cart</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
}

export default withStyles(styles)(CustomGiftDialog);

