import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Link,
    TextField,
    withStyles
} from "@material-ui/core";
// import * as React from "react";
import {useContext, useState} from "react";
import {useNotify} from "../../utils/Notify";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../firebase";
import {wait} from "@testing-library/user-event/dist/utils";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import GiftedLogo from "../../assets/Gifted_Logo.svg";
import {setInSession} from "../../utils/Util";
import {grey, teal} from "@material-ui/core/colors";
import KidfolioContext from "../../store/KidfolioContext";
import LoadingButton from "@mui/lab/LoadingButton";

const styles = theme => ({
    root: {
        //  flexGrow: 1,
        width: '495px',
        height: '600px',
        // marginBottom:  250,
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative',
        margin: 'auto',
        // display: 'flex'

    },
    cardLayout: {
        paddingBottom: 15,
        //height: "700px",
        //width: "500px",

    },
    primaryColor: {
        color: teal[500]
    },
    secondaryColor: {
        color: grey[700]
    },
    contributorDtl: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        alignItems: 'center'
    },
    padding: {
        padding: 0
    },
    mainHeader: {
        alignItems: "center"
    },
    mainContent: {
        padding: 40
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    },
    topSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    dialogPadding: {
        padding: 16,
    },
    inputClass: {
        '& label': {
            lineHeight: 0.7,
        },
        '& input': {
            fontSize: '12px'
        },
    },
});

function ContributorSignUp(props) {
    const {classes} = props;
    const notify = useNotify();
    const navigate = useNavigate();
    const ctx = useContext(KidfolioContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginInProgress, setLoginInProgress] = useState(false);
    const [guestLoginInProgress, setGuestLoginInProgress] = useState(false);

    const handleSubmit = async () => {
        if (!email || !password) {
            notify(
                "error", "Please fill all the Fields", ""
            );
            return;
        }

        try {
            setLoginInProgress(true);
            const result = await signInWithEmailAndPassword(auth, email, password).then((res) => {
                onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        setInSession("user", user);
                    }
                });
            })
            const response = await ctx.getEvent(props.eventId);
            if (response && response.beneficiary) {
                props.setChildData(response.beneficiary);
                props.setChildEventInfo(response);
            }
            props.handleGuestLoginDialogClose();
            navigate({
                pathname: `${props.toPath}`,
            });
            await wait(3000);
            setLoginInProgress(false);
        } catch (error) {
            let message = "";

            if (error.message.indexOf("auth/wrong-password") !== -1) {
                message = "Password is wrong, Please provide correct password."
            }
            if (error.message.indexOf("auth/user-not-found") !== -1) {
                message = "User is not registered. Please contact administrator."
            }
            navigate({
                pathname: `${props.toPath}`,
            });
            notify(
                "error", message || error.message, ""
            );
            setLoginInProgress(false);
            // props.handleGuestLoginDialogClose();
            return;
        }
    };

    const handleGuestLogin = async () => {
        props.guestLogin();
        setGuestLoginInProgress(true);
    };

    return (
        <Dialog
            className={classes.card}
            open={true}
            disableEnforceFocus
        >
            <DialogContent>
                <Box sx={{width: '90%', justifyContent: "center", padding: "auto", alignItems: "center", mx: "auto"}}>
                    <Stack spacing={2} direction="column"
                           justifyContent="center" alignItems="center">
                        <Paper sx={{
                            width: "347px",
                            background: "#FFFFFF",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            padding: '20px',
                            boxShadow: 'none',
                            border: '0.5px solid #D0D0D0'
                        }}>
                            <div style={{
                                marginBottom: '20px',
                                display: 'flex',
                                minHeight: '6vh',
                                background: 'transparent',
                                backgroundColor: 'transparent',
                                position: 'relative',
                                justifyContent: "center"
                            }}>
                                <img style={{width: "110px"}} src={GiftedLogo}/>
                            </div>
                            <div style={{
                                display: 'flex',
                                minHeight: '10vh',
                                background: 'transparent',
                                backgroundColor: 'transparent',
                                position: 'relative'
                            }}>
                                <Box
                                    p={2}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "20px",
                                        width: '100%'
                                    }}
                                >
                                    <TextField
                                        className={classes.inputClass}
                                        variant="outlined"
                                        type="email"
                                        label={<span style={{fontSize: 12}}>
                                            Email Address
                                        </span>}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={{width: '100%', fontSize: '12px !important'}}
                                        size="small"
                                    />
                                    <TextField
                                        className={classes.inputClass}
                                        variant="outlined"
                                        label={<span style={{fontSize: 12}}>
                                            Password
                                        </span>}
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        style={{width: '100%', fontSize: '12px'}}
                                        size="small"
                                    />
                                    <Typography variant={'h6'} style={{fontSize: '12px', cursor: 'pointer'}}>
                                        <Link onClick={() => navigate({pathname: `/forgotPassword`})}>Forgot
                                            Password</Link>
                                    </Typography>
                                    {!loginInProgress ?
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                                backgroundColor: "#0079D9",
                                                width: '100%',
                                                color: "#ffffff",
                                                height: '36px',
                                                fontSize: 15
                                            }}
                                            onClick={handleSubmit}
                                            disabled={guestLoginInProgress}
                                        >
                                            Sign In
                                        </Button>
                                        : <LoadingButton loading loadingIndicator="Signing In..." variant="contained"
                                                         style={{width: '100%'}}>
                                            Signing In...
                                        </LoadingButton>}
                                    <div style={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <Divider style={{width: '43%'}}/> <span style={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        width: '14%',
                                        textAlign: 'center',
                                        color: '#666666',
                                    }}>OR</span><Divider style={{width: '43%'}}/>
                                    </div>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                                backgroundColor: "#0079D9",
                                                width: '100%',
                                                color: "#ffffff",
                                                height: '36px',
                                                fontSize: 15
                                            }}
                                            onClick={handleGuestLogin}
                                            disabled={loginInProgress || guestLoginInProgress}
                                        >
                                            Continue as Guest
                                        </Button>
                                </Box>
                            </div>
                        </Paper>
                        <Paper sx={{
                            width: "347px",
                            background: "#FFFFFF",
                            border: "0.5px solid #D0D0D0",
                            boxSizing: "border-box",
                            borderRadius: "6px", alignItems: "center",
                            height: "83px",
                            display: "flex",
                            justifyContent: "center",
                            boxShadow: 'none',
                            fontSize: 14
                        }}>
                            <div style={{
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                margin: "auto", padding: "auto"
                            }}>Dont have an account? <Link style={{cursor: 'pointer'}} onClick={() => navigate({
                                pathname: `/signUp`,
                            })}>Sign up</Link></div>
                        </Paper>
                    </Stack>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default withStyles(styles)(ContributorSignUp);