import React, { useContext, useState } from "react";
import { withStyles, MenuItem, makeStyles, Card, List, Avatar } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from '@mui/icons-material/Add';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
// import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import { teal, grey } from "@material-ui/core/colors";
import CloseIcon from '@mui/icons-material/Close';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from "@mui/lab/DatePicker";
import CakeImage from "../assets/cakeFigma.svg";
import dayjs from "dayjs";
import KidfolioContext from "../store/KidfolioContext";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { Axios as axios } from "axios";
import getFirebaseToken from "../utils/FirebaseToken";
import AddGift from "./AddGift";
import { getChildProfile, getSolanaVendorName } from '../utils/Util';
import AddCardIcon from '@mui/icons-material/AddCard';
import Paper from "@mui/material/Paper";
import ChildCard from "./ChildCard";
import AddChildCard from "./AddChildCard";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@mui/icons-material/Edit";
import AddProfileImage from "../assets/AddProfileImage.svg";
const styles = theme => ({
    root: {
        flexGrow: 1
    },
    cardLayout: {
        paddingBottom: 15
    },
    primaryColor: {
        color: teal[500]
    },
    secondaryColor: {
        color: grey[700]
    },

    padding: {
        padding: 0
    },
    mainHeader: {
        alignItems: "center"
    },
    mainContent: {
        padding: 40
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    }
});
const countries = [
    {
        value: "Male",
        label: "Male"
    },
    {
        value: "Female",
        label: "Female"
    },
    {
        value: "Prefer Not To Say",
        label: "Prefer Not To Say"
    }
];

function AddWalletDialog(props) {
    const { classes, open } = props;
    const ctx = useContext(KidfolioContext);
    const userDetails = ctx.loggedInUserDetails;
    const [showWallet, setShowWallet] = useState(true);
    const [showChildIds, setShowChildIds] = useState(false);
    const [hideCreateEvent, setHideCreateEvent] = useState(true);
    const [childSolAdd, setChildSolAdd] = React.useState("");
    const [selectedProfile, setSelectedProfile] = React.useState("");
    const [eventName, setEventName] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(ctx.userProfileImageUrl || AddProfileImage);
    const { fetchGifts, addCustomEventTitle, fetchEventTitles, fetchChildren, addSolanaWalletAddress } = useContext(KidfolioContext);
    function chooseGifts() {
        setShowGifts(true);
        setShowWallet(false);
        /*axios.post('https://reqres.in/api/articles', article)
            .then(response => this.setState({ articleId: response.data.id }));*/
    }

    const borderClass = '2px solid #3eb9f3';
    const getChildPhoto = (benId) => {
        return getChildProfile(ctx.beneficiaryImageUrl, benId);
    }

    function handleGetDate() {
        setShowWallet(false);
        setShowGifts(false);
        setHideCreateEvent(true);
    }

    function createCustomEvent() {
        setShowWallet(true);
        setShowGifts(false);
        setHideCreateEvent(false);
    }

    const handleGetEvent = (eventName) => {
        fetchGifts();
        setEventName(eventName)
    }

    function backToEventScreen() {
        setShowGifts(false);
        setHideCreateEvent(true);
        setShowWallet(true);
    }

    async function addCustomEvent(eventName) {
        await addCustomEventTitle(eventName);
        fetchEventTitles();
        handleCloseInternalModal();
    }

    function handleCloseInternalModal() {
        setShowGifts(false);
        setHideCreateEvent(true);
        setShowWallet(true);
    }

    function showChildren() {
        //show children
        setShowChildIds(true)
    }

    function getChildName(childId) {
        const childDetail = ctx.beneficiariesData.data.beneficiaries.find((beneficiary) =>
            beneficiary.id === childId
        )
        return childDetail.profile.fullName;
    }

    async function saveWalletShowCreateEvent() {
        props.handleShowWallet(false);
        //saveWalletAddress
        if (selectedProfile === props.childId) {
            await addSolanaWalletAddress(props.childId, childSolAdd);
            await fetchChildren();
        } else {
            const solanaVendorName = getSolanaVendorName(ctx.paymentOption);
            let paymentObj = [
                {
                    "vendorName": solanaVendorName,
                    "identifier": childSolAdd
                }
            ]
            const userPaymentOptions = JSON.parse(JSON.stringify(userDetails.paymentOptions || []));
            if (userPaymentOptions && userPaymentOptions.length) {

                const isPresent = userPaymentOptions.some(item => {
                    return (item.vendorName === solanaVendorName);
                })
                if (isPresent) {
                    paymentObj = userPaymentOptions.map(item => {
                        if (item.vendorName === solanaVendorName) {
                            item['identifier'] = childSolAdd;
                        }
                        return item;
                    })
                } else {
                    paymentObj = paymentObj.concat(userPaymentOptions);
                }
            }

            await ctx.updateSolanaAddressForUser(paymentObj);

            await ctx.fetchUserDetail();
        }

    }

    function handleProfileClick(id) {
        setSelectedProfile(id);
    }

    return (
        <Dialog
            className={classes.card}
            open={props.showWallet}
            onClose={() => props.handleClose}
            disableEnforceFocus
        >
            <DialogContent>

                <Grid container className={classes.cardLayout}>
                    {!showChildIds ?
                        <Grid item xs={12} style={{ width: '500px', height: '500px' }}>
                            <Grid container direction="row" className={classes.mainHeader}>
                                <Grid item xs={10}>
                                    <Typography className={classes.primaryColor} variant="h5" style={{
                                        fontFamily: "Montserrat",
                                        float: "left",
                                        color: "#000202"
                                    }}>
                                        New Event
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} align="right" className={classes.padding}>
                                    <IconButton
                                        edge="start"
                                        align="right"
                                        color="inherit"
                                        aria-label="Close"
                                        style={{ padding: 8 }}
                                    //className={classes.padding}
                                    >
                                        <CloseIcon onClick={props.handleClose} />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <div style={{ textAlign: "center", marginTop: 40 }}>

                                <Grid item xs={12}>
                                    <div>

                                        <AddCardIcon style={{
                                            height: '70px',
                                            width: '70px',
                                            marginTop: "25px",
                                            color: '#25ebf3'
                                        }} />
                                    </div>
                                </Grid>
                                <br /><br />


                                <Typography
                                    style={{
                                        fontFamily: "Montserrat",
                                        textAlign: "center",
                                        fontSize: "20px"
                                    }}>
                                    You don’t have any wallet added to create a new event. Please add a wallet to start
                                    creating an event?
                                </Typography>


                                <Grid item xs={12}>
                                    <div>
                                        <Button
                                            style={{
                                                float: 'center',
                                                backgroundColor: "#0078D9",
                                                width: 250,
                                                color: 'white',
                                                marginTop: 50
                                            }}
                                            onClick={showChildren}>Add Wallet</Button>
                                    </div>
                                </Grid>
                            </div>

                        </Grid> :
                        <Grid item xs={12} style={{ width: '500px', height: '500px' }}>
                            <Grid container direction="row" className={classes.mainHeader}>
                                <Grid item xs={10}>
                                    <Typography className={classes.primaryColor} variant="h5" style={{
                                        fontFamily: "Montserrat",
                                        float: "left",
                                        color: "#000202"
                                    }}>
                                        Add Wallet
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} align="right" className={classes.padding}>
                                    <IconButton
                                        edge="start"
                                        align="right"
                                        color="inherit"
                                        aria-label="Close"
                                        style={{ padding: 8 }}
                                    //className={classes.padding}
                                    >
                                        <CloseIcon onClick={props.handleClose} />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <div style={{ textAlign: "left" }}>
                                <Typography
                                    style={{
                                        fontFamily: "Montserrat",
                                        fontSize: "14px",
                                        marginTop: 20
                                    }}>
                                    Select the profile
                                </Typography>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Paper style={{
                                    overflow: 'auto', height: 140,
                                    border: 'none', background: 'transparent', boxShadow: 'none'
                                }}>
                                    <List style={{ display: 'inline-flex', paddingTop: 0, minWidth: '100%' }}>
                                        <Card onClick={e => handleProfileClick(props.childId)}
                                            style={{
                                                width: '150px',
                                                border: selectedProfile === props.childId ? borderClass : 'none',
                                                cursor: "pointer",
                                                marginRight: 40,
                                                borderRadius: '15px',
                                                transformStyle: "preserve-3d",
                                                boxShadow: "0px 4.478689670562744px 15.675413131713867px 6.718034744262695px #FFFFFF33 inset, -22.393447875976562px 105.24920654296875px 161.23281860351562px 0px #FFFFFF33"
                                            }} >
                                            <div>
                                                <div className="col-sm-3">
                                                    <img src={getChildPhoto(props.childId)} style={{
                                                        height: '70px',
                                                        width: '70px',
                                                        float: "center",
                                                        marginTop: "25px",
                                                        borderRadius: "50px"
                                                    }} />
                                                </div>
                                                <Typography style={{
                                                    fontFamily: "Montserrat",
                                                    textAlign: "center",
                                                    fontSize: "20px",
                                                    fontWeight: '50px'
                                                }}>{getChildName(props.childId)}</Typography>
                                            </div>
                                        </Card>

                                        <Card onClick={e => handleProfileClick(userDetails.id)}
                                            style={{
                                                border: selectedProfile === userDetails.id ? borderClass : 'none',
                                                marginRight: 40,
                                                transformStyle: "preserve-3d",
                                                borderRadius: '15px',
                                                boxShadow: "0px 4.478689670562744px 15.675413131713867px 6.718034744262695px #FFFFFF33 inset, -22.393447875976562px 105.24920654296875px 161.23281860351562px 0px #FFFFFF33"
                                            }} >
                                            <div style={{ cursor: "pointer" }}>
                                                <div className="col-sm-3">
                                                    <Avatar
                                                        style={{
                                                            height: '70px',
                                                            width: '70px',
                                                            float: "center",
                                                            marginTop: "25px",
                                                            borderRadius: "50px",
                                                            backgroundColor: "#EEBC1D"
                                                        }}
                                                        src={ctx.userProfileImageUrl}
                                                    />
                                                </div>
                                                <Typography style={{
                                                    fontFamily: "Montserrat",
                                                    textAlign: "center",
                                                    fontSize: "20px",
                                                    fontWeight: '50px'
                                                }}>{userDetails.profile.fullName}</Typography>
                                            </div>
                                        </Card>


                                    </List>
                                </Paper>

                                <Grid item xs={12} style={{ marginTop: 30, height: 100, color: classes.editAmountColor }}>
                                    <Typography
                                        style={{
                                            fontFamily: "Montserrat",
                                            textAlign: "left",
                                            fontSize: "14px"
                                        }}>
                                        Add Solana Name or Address
                                    </Typography>
                                    <br />
                                    <TextField
                                        fullWidth
                                        style={{ height: 100 }}
                                        color="#3BFFD9"
                                        margin="dense"
                                        variant="outlined"
                                        label="Solana Name or address"
                                        id="amount"
                                        onChange={(event) => {
                                            setChildSolAdd(event.target.value);
                                        }}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <div>
                                        <Button
                                            style={{
                                                float: 'center',
                                                backgroundColor: "#0078D9",
                                                width: 250,
                                                marginTop: 50
                                            }}
                                            onClick={saveWalletShowCreateEvent}>Save</Button>
                                    </div>
                                </Grid>
                            </div>

                        </Grid>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(styles)(AddWalletDialog);
