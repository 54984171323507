import { Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ChildPage from "./pages/ChildPage";
import EventsPage from "./pages/EventsPage";
import { Container, Typography } from "@material-ui/core";
import { useContext, useState, useEffect } from "react";
import KidfolioContext from "./store/KidfolioContext";
import AuthModal from "./components/Authentication/AuthModal";
import Login from "./components/Authentication/Login";
// import firebase from "firebase";
import { auth } from "./firebase";
import getFirebaseToken from "./utils/FirebaseToken";
import Banner from "./components/Banner/Banner";
import LoginFlow from "./components/Authentication/LoginFlow";
import SignUpFlow from "./components/Authentication/SignUpFlow";
import SignUpWithEmail from "./components/Authentication/SignUpWithEmail";
import LaunchPage from "./pages/LaunchPage";
import AddUserDetails from "./components/User/AddUserDetails";
import AddChildCard from "./components/AddChildCard";
import AddChildDialog from "./components/AddChildDialog";
import { getFromSession } from "./utils/Util";
import EventNotification from "./pages/EventNotification";
import SendGift from "./pages/SendGift";
import ForgotPassword from "./components/Authentication/ForgotPassword";
import EmailVerification from "./components/Authentication/EmailVerification";

function AppContainer() {
    const kidfolioCtx = useContext(KidfolioContext);
    const user = getFromSession('user') || kidfolioCtx.user;

    const accessRoute = () => {
        const result = user && !((kidfolioCtx.loggedInUserDetails
            && kidfolioCtx.loggedInUserDetails.profile
            && kidfolioCtx.loggedInUserDetails.profile.emailId === 'guest@gifted.com') ||
            kidfolioCtx.isGuestUser());
        return result;
    }

    return (
        <div style={{ width: '100%', margin: 'auto', align: 'center' }} fixed={"true"} >
            <div>
                <Routes>
                    <Route path="/" element={<LaunchPage />} exact />
                    <Route path="/login" element={<LoginFlow />} exact />
                    <Route path="/signUp" element={<SignUpFlow />} exact />
                    <Route path="/signUpWithEmail" element={<SignUpWithEmail />} exact />
                    <Route path="/addUserDetails" element={<AddUserDetails />} exact />
                    <Route path="/addChild" element={<AddChildDialog />} exact />
                    <Route path="/forgotPassword" element={<ForgotPassword />} exact />
                    <Route path="/verifyEmail" element={<EmailVerification />} exact />
                    {/* TODO: Move all these to Restricted Route Component */}
                    {accessRoute() ?
                        <Route path="/home" element={<HomePage />} exact /> : <Route path="/home" element={<Navigate to={`/login`} />} />}
                    {accessRoute() ?
                        <Route path="/child/:id" element={<ChildPage />} exact /> : <Route path="/home" element={<Navigate to={`/login`} />} />}
                    {accessRoute() ?
                        <Route path="/child/:beneficiaryId/events/:eventId" element={<EventsPage />} exact /> : <Route path="/home" element={<Navigate to={`/login`} />} />}
                    {accessRoute() ?
                        <Route path="/eventNotification" element={<EventNotification />} exact /> : <Route path="/home" element={<Navigate to={`/login`} />} />}
                    <Route path="/sendGift/event/:eventId" element={<SendGift />} exact />
                </Routes>
            </div>
        </div>
    )
}

export default AppContainer;
