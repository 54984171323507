import React, { useContext, useRef, useState } from "react";
import { withStyles, MenuItem, makeStyles, Card } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from '@mui/icons-material/Add';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
// import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import { teal, grey } from "@material-ui/core/colors";
import CloseIcon from '@mui/icons-material/Close';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from "@mui/lab/DatePicker";
import CakeImage from "../assets/cakeFigma.svg";
import dayjs from "dayjs";
import KidfolioContext from "../store/KidfolioContext";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { Axios as axios } from "axios";
import getFirebaseToken from "../utils/FirebaseToken";
import AddGift from "./AddGift";
import { getChildProfile } from '../utils/Util';
import CircularProgress from "@mui/material/CircularProgress";

const styles = theme => ({
    root: {
        //  flexGrow: 1,
        width: '495px',
        height: '600px',
        // marginBottom:  250,
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative',
        margin: 'auto',
        // display: 'flex'

    },
    cardLayout: {
        paddingBottom: 15,
        //height: "700px",
        //width: "500px",

    },
    primaryColor: {
        color: teal[500]
    },
    secondaryColor: {
        color: grey[700]
    },
    contributorDtl: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        alignItems: 'center'
    },
    padding: {
        padding: 0
    },
    mainHeader: {
        alignItems: "center"
    },
    mainContent: {
        padding: 40
    },
    secondaryContainer: {
        padding: "20px 25px",
        backgroundColor: grey[200]
    },
    topSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    dialogPadding: {
        padding: 16,
    },
    inputClass: {
        '& label': {
            lineHeight: 0.7,
        },
        '& input': {
            fontSize: '12px'
        },
    },
});


function AddEventDialog(props) {
    const { classes, open } = props;
    const ctx = useContext(KidfolioContext);
    const [visible, setVisible] = useState(true);
    const [showGifts, setShowGifts] = useState(false);
    const [hideCreateEvent, setHideCreateEvent] = useState(true);
    const [eventName, setEventName] = useState();
    const [eventDate, setEventDate] = React.useState(new Date());
    const { fetchGifts, addCustomEventTitle, fetchCategories } = useContext(KidfolioContext);

    const textInput = useRef(null);

    function chooseGifts() {
        setShowGifts(true);
        setVisible(false);
        /*axios.post('https://reqres.in/api/articles', article)
            .then(response => this.setState({ articleId: response.data.id }));*/
    }

    const getChildPhoto = (benId) => {
        return getChildProfile(ctx.beneficiaryImageUrl, benId);
    }

    function handleGetDate() {
        setVisible(false);
        setShowGifts(false);
        setHideCreateEvent(true);
    }

    function createCustomEvent() {
        setVisible(true);
        setShowGifts(false);
        setHideCreateEvent(false);
    }

    const handleGetEvent = (eventName) => {
        fetchGifts();
        setEventName(eventName)
    }

    function backToEventScreen() {
        setShowGifts(false);
        setHideCreateEvent(true);
        setVisible(true);
    }

    async function addCustomEvent(eventName) {
        textInput.current.value = "";
        await addCustomEventTitle(eventName);
        fetchCategories();
        handleCloseInternalModal();
    }

    function handleCloseInternalModal() {
        setShowGifts(false);
        setHideCreateEvent(true);
        setVisible(true);
    }

    return (
        <Dialog
            className={classes.card}
            open={props.visible}
            onClose={() => props.handleClose}
            disableEnforceFocus
        >
            <DialogContent>

                <Grid container className={classes.cardLayout}>
                    <Grid item xs={12}>
                        <Grid container direction="row" className={classes.mainHeader}>
                            <Grid item xs={10}>
                                <Typography className={classes.primaryColor} variant="h5" style={{
                                    fontFamily: "Montserrat",
                                    float: "left",
                                    color: "#000202"
                                }}>
                                    New Event
                                </Typography>
                            </Grid>
                            <Grid item xs={2} align="right" className={classes.padding}>
                                <IconButton
                                    edge="start"
                                    align="right"
                                    color="inherit"
                                    aria-label="Close"
                                    style={{ padding: 8 }}
                                //className={classes.padding}
                                >
                                    <CloseIcon onClick={props.handleClose} />
                                </IconButton>
                            </Grid>
                        </Grid>

                        {

                            !showGifts ?
                                visible ?

                                    <div style={{
                                        textAlign: "center"
                                    }}>
                                        <Typography style={{
                                            float: "center",
                                            marginTop: 40,
                                            marginBottom: 20,
                                            textAlign: "center",
                                            fontFamily: "Montserrat",
                                            fontSize: "22px"
                                        }}>
                                            Let's add an event  <br /> for {props.childName}
                                        </Typography>

                                        <Grid item xs={12}>
                                            <div className="col-sm-3">
                                                <img src={getChildPhoto(props.childId)} style={{
                                                    height: '70px',
                                                    width: '70px',
                                                    float: "center",
                                                    marginTop: "25px",
                                                    borderRadius: "50px"
                                                }} />
                                                {/*{*/}
                                                {/*    (props.childDetail.profile.imageId) ?*/}
                                                {/*    <FetchImage imageId={props.childDetail.profile.imageId} /> :*/}
                                                {/*        <img src={ChildImage} alt={props.childDetail.profile.imageId} style={{height: '80px', width: '80px', float: "left", marginLeft: "25px", marginTop: "25px", borderRadius: "15px"}}/>*/}
                                                {/*}*/}
                                            </div>
                                        </Grid>

                                        <div >
                                            <br /><br />
                                            <Grid item xs={12}>
                                                <Typography style={{
                                                    fontSize: "12px",
                                                    color: "#666666"
                                                }}>
                                                    Here are some ideas
                                                </Typography>
                                                {
                                                    (ctx.categoriesData) ?
                                                        ctx.categoriesData.map((category) => (
                                                            (category.items) ? category.items.map((item) => (
                                                                <Button
                                                                    value={item.title}
                                                                    onClick={e => handleGetEvent(item.title)}
                                                                    variant="text"
                                                                    style={{
                                                                        padding: 18,
                                                                        height: 20,
                                                                        marginLeft: 8,
                                                                        marginRight: 8,
                                                                        marginTop: 15,
                                                                        float: "center",
                                                                        backgroundColor: eventName === item.title ? "#0079D9" : "#3BFFD9",
                                                                        color: eventName === item.title ? "#FFFFFF" : "#000000",
                                                                    }}>
                                                                    {item.title}
                                                                </Button>
                                                            )) : <div>No Data</div>
                                                        )) : <div>Nope</div>
                                                }
                                            </Grid>
                                            <br />
                                            <Grid item xs={12} style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: 20,
                                            }}>
                                                <Button
                                                    variant="text"
                                                    style={{
                                                        height: 36,
                                                        color: "#0078D9",
                                                        border: 'solid 0.5px #0078D9'
                                                    }}
                                                    onClick={createCustomEvent}>
                                                    <AddIcon /> Create your own event
                                                </Button>
                                            </Grid>

                                            <br /><br />
                                            <Grid item xs={12}>
                                                <div style={{ float: 'right' }}>
                                                    <Button style={{
                                                        backgroundColor: "#0078D9",
                                                        width: 250,
                                                        color: 'white',
                                                        marginTop: 25
                                                    }}
                                                        disabled={!eventName}
                                                        onClick={handleGetDate}>Next</Button>
                                                </div>
                                            </Grid>
                                        </div>

                                        <div hidden={hideCreateEvent}>
                                            <br /><br />
                                            <Dialog
                                                disableEnforceFocus
                                                className={classes.root}

                                                open={!hideCreateEvent}
                                                onClose={handleCloseInternalModal}>
                                                <DialogContent className={classes.dialogPadding}>
                                                    <Grid container>
                                                        <Grid item xs={12} align="right" className={classes.topSection}>
                                                            <div style={{ alignItems: "center", width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{ float: "center", fontSize: 20, fontWeight: 400, }}>Add custom event</span><br />
                                                                <div className="row"
                                                                    style={{
                                                                        height: 10,
                                                                        fontSize: "13px",
                                                                        fontWeight: "1px",
                                                                        color: "#666666",
                                                                        marginLeft: "40px"
                                                                    }}>Describe the event you are creating</div>
                                                            </div>

                                                            <IconButton
                                                                edge="start"
                                                                align="right"
                                                                color="inherit"
                                                                aria-label="Close"
                                                                style={{ padding: 8, width: 30 }}
                                                            >
                                                                <CloseIcon onClick={handleCloseInternalModal} />
                                                            </IconButton>
                                                        </Grid>

                                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <TextField
                                                                className={classes.inputClass}
                                                                style={{
                                                                    width: 300,
                                                                    marginTop: 40,
                                                                    marginBottom: 30,
                                                                }}
                                                                inputRef={textInput}
                                                                variant="outlined"
                                                                margin="dense"
                                                                label={<span style={{ fontSize: 12 }}>
                                                                    Name of the event
                                                                </span>}
                                                                id="eventName"

                                                                onChange={(event) => {

                                                                    setEventName(event.target.value);
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.contributorDtl} >
                                                            <Button
                                                                onClick={e => addCustomEvent(eventName)}
                                                                variant="contained"
                                                                style={{
                                                                    height: 40,
                                                                    width: 100,
                                                                    float: "center",
                                                                    marginTop: 25,
                                                                    backgroundColor: "#0079D9",
                                                                    marginLeft: 140,
                                                                    color: '#FFFFFF'
                                                                }}>
                                                                Add
                                                            </Button>
                                                        </Grid>


                                                    </Grid>
                                                </DialogContent>
                                            </Dialog>



                                        </div>
                                    </div>
                                    :
                                    <div style={{ textAlign: "center" }}>

                                        <Grid item xs={12}>
                                            <div>
                                                <img src={getChildPhoto(props.childId)} style={{
                                                    height: '70px',
                                                    width: '70px',
                                                    marginTop: "25px",
                                                    borderRadius: "50px"
                                                }} />
                                                {/*{*/}
                                                {/*    (props.childDetail.profile.imageId) ?*/}
                                                {/*    <FetchImage imageId={props.childDetail.profile.imageId} /> :*/}
                                                {/*        <img src={ChildImage} alt={props.childDetail.profile.imageId} style={{height: '80px', width: '80px', float: "left", marginLeft: "25px", marginTop: "25px", borderRadius: "15px"}}/>*/}
                                                {/*}*/}
                                            </div>
                                        </Grid>
                                        <br /><br />
                                        <Typography
                                            style={{
                                                fontFamily: "Montserrat",
                                                textAlign: "center",
                                                fontWeight: 600
                                            }}>
                                            {props.childName}
                                        </Typography>

                                        <Typography
                                            style={{
                                                fontFamily: "Montserrat",
                                                textAlign: "center",
                                                fontSize: "20px"
                                            }}>
                                            {eventName}
                                        </Typography>

                                        <br /><br />
                                        <Grid item xs={12} >
                                            <Typography style={{
                                                fontSize: "18px"
                                            }}>
                                                When do you want to add {props.childName}'s {eventName} to calendar?
                                            </Typography>
                                        </Grid>
                                        <br /><br />
                                        <Grid item xs={12} >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    openTo="day"
                                                    views={["year", "month", "day"]}
                                                    value={eventDate}
                                                    onChange={(newValue) => {
                                                        setEventDate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                            <br /><br />
                                        </Grid>
                                        <br /><br />
                                        <Grid item xs={12}>
                                            <div style={{ marginTop: 50 }}>
                                                <Button style={{ float: 'left' }} type={"reset"} onClick={backToEventScreen}>Back</Button>
                                                <Button
                                                    style={{
                                                        float: 'right',
                                                        backgroundColor: "#0078D9",
                                                        width: 150,
                                                        color: 'white'
                                                    }}
                                                    onClick={chooseGifts}>Next</Button>
                                            </div>
                                        </Grid>
                                    </div>
                                :

                                <AddGift benName={props.childName} benPhoto={getChildPhoto(props.childId)} eventName={eventName} eventDate={eventDate} beneficiaryId={props.childId} handleClose={props.handleClose} handleBack={handleGetDate} handleChooseGift={chooseGifts}></AddGift>


                        }

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(styles)(AddEventDialog);
