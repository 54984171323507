import { Card, CardMedia, makeStyles } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import KidfolioContext from "../store/KidfolioContext";
import { getBackgroundColor } from "../utils/Util";
import ContributionDialog from "./ContributionDialog";
import VideoIcon from "../assets/VideoIcon.svg";

const useStyles = makeStyles({
    card: {
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        cursor: 'pointer',
        width: '250px',
        height: '250px',
        transformStyle: "preserve-3d",
        boxShadow: "0px 4.478689670562744px 15.675413131713867px 6.718034744262695px #FFFFFF33 inset, -22.393447875976562px 105.24920654296875px 161.23281860351562px 0px #FFFFFF33",
    },
    contributorImg: {
        height: '100%',
        width: '100%',
        borderRadius: '15px',
        position: 'absolute',
        left: 0
    },
    contributorDtl: {
        position: "absolute",
        left: "0px",
        right: "0px",
        padding: 20,
    },
    videoIcon: {
        position: 'absolute',
        top: 10,
        left: 10,
        background: 'white',
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
})
export default function ContributionCard(props) {
    const classes = useStyles();
    const ctx = useContext(KidfolioContext);
    const [contributorImage, setContributorImage] = useState(null);
    const [showContribution, setShowContribution] = useState(false);
    const [hasVideo, setHasVideo] = useState(false);

    useEffect(async () => {
        let videoPresent = false;
        if (props.eventDetails.contributions && props.eventDetails.contributions.length) {
            videoPresent = props.eventDetails.contributions.some(contri => {
                return contri.contributorMessage && contri.contributorMessage.videoMessageId;
            })
        }
        setHasVideo(videoPresent);
    }, []);


    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    useEffect(async () => {
        if (props.eventDetails.contributor.profile.imageId) {
            const imageUrl = await ctx.fetchImageById(props.eventDetails.contributor.profile.imageId);
            setContributorImage(imageUrl);
        }
    }, []);

    const getEventContriImageColorClass = () => {
        const emailId = props.eventDetails.contributor.profile.emailId;
        const userName = props.eventDetails.contributor.profile.fullName;
        const colorCode = ctx.eventContributorImageColorCode[emailId];
        return `sprite-${colorCode}-large ${colorCode}_${userName.substring(0, 1).toUpperCase()}`;
    }

    const getEventContriBgColor = () => {
        let bgColor = 'none';
        if (!contributorImage) {
            const emailId = props.eventDetails.contributor.profile.emailId;
            const colorCode = ctx.eventContributorImageColorCode[emailId];
            bgColor = getBackgroundColor(colorCode);
        }
        return bgColor
    }

    const showContributionDtl = () => {
        setShowContribution(true);
    }

    const closeContriDialog = () => {
        setShowContribution(false);
    }

    const getContentStyles = () => {
        if (contributorImage) {
            return { bottom: 0, opacity: 0.8, background: 'white' };
        }
        return { bottom: 0 };
    }

    return (
        <Card border="none" className={classes.card} style={{ backgroundColor: `${getEventContriBgColor()}` }}
        >
            <> {contributorImage ?
                <img src={contributorImage} className={classes.contributorImg} onClick={showContributionDtl} />
                : <div className={getEventContriImageColorClass()} onClick={showContributionDtl} style={{ zoom: '0.70', paddingBottom: 25 }}></div>
            }
            </>
            <div className={classes.contributorDtl} style={getContentStyles()}>
                <span style={{
                    display: 'flex',
                    color: `${contributorImage ? 'black' : 'inherit'}`,
                    fontSize: "16px",
                    fontWeight: "400",
                    textAlign: "left",
                }}>{capitalizeFirstLetter(props.eventDetails.contributor.profile.fullName)}</span>
            </div>
            {hasVideo && <div className={classes.videoIcon}>
                <img src={VideoIcon} style={{ width: 16, height: 16, padding: 6 }} />
            </div>}
            {showContribution ? <ContributionDialog contributor={props.eventDetails.contributor} contributions={props.eventDetails.contributions} visible={showContribution} handleClose={closeContriDialog} /> : <div />}
        </Card>
    )
}

