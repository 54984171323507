import { auth } from "../firebase";
import { getTokenFromSession } from "../utils/Util";

const getFirebaseToken = async () => {
    if (!auth.currentUser) {
        const token = getTokenFromSession();
        if (token) {
            return token;
        }
        return null;
    }
    return await auth.currentUser.getIdTokenResult().then(function (result) {
        return result.token
    });
};

export default getFirebaseToken;