import { Box, Button, makeStyles, Link, TextField } from "@material-ui/core";
import { useState } from "react";
import { auth } from "../../firebase";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup
} from "firebase/auth";
import { useNotify } from "../../utils/Notify";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { wait } from "@testing-library/user-event/dist/utils";
import { googleProvider } from "../../config/authGoogleProvider";
import { facebookProvider } from "../../config/authFacebookProvider";
import { appleProvider } from "../../config/authAppleProvider";
import GoogleIcon from "../../assets/GoogleIcon.svg"
import AppleIcon from "../../assets/AppleIcon.svg"
import FacebookIcon from "../../assets/FacebookIcon.svg"


const useStyles = makeStyles({
  inputClass: {
    '& label': {
      lineHeight: 0.7,
    },
    '& input': {
      fontSize: '12px'
    },
  },
});

const Login = ({ handleClose }) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const notify = useNotify();

  const handleSubmit = async () => {
    if (!email || !password) {
      notify(
        "error", "Please fill all the Fields", ""
      );
      return;
    }

    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      // notify(
      //     "success", `Sign Up Successful. Welcome ${result.user.email}`, ""
      // );
      // await wait(3000);
      console.log("result", result)
      setTimeout(() => {
        navigate({
          pathname: `/home`,
        });
      }, 100);

    } catch (error) {
      let message = "";

      if (error.message.indexOf("auth/wrong-password") !== -1) {
        message = "Password is wrong, Please provide correct password."
      }
      if (error.message.indexOf("auth/user-not-found") !== -1) {
        message = "User is not registered. Please contact administrator."
      }
      notify(
        "error", message || error.message, ""
      );
      return;
    }
  };

  const handleGoogleLogin = async () => {
    try {
      signInWithPopup(auth, googleProvider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // notify(
          //     "success", `Sign Up Successful. Welcome ${result.user.email}`, ""
          // );
          // ...
          setTimeout(() => {
            navigate({
              pathname: `/home`,
            });
          }, 100);
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    } catch (error) {
      notify(
        "error", error.message, ""
      );
      return;
    }
  }

  const handleFacebookLogin = async () => {
    try {
      signInWithPopup(auth, facebookProvider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const credential = FacebookAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          setTimeout(() => {
            navigate({
              pathname: `/home`,
            });
          }, 100);
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = FacebookAuthProvider.credentialFromError(error);
          notify(
            "error", error.message, ""
          );

          // ...
        });
    } catch (error) {
      notify(
        "error", error.message, ""
      );
      return;
    }
  }

  const handleAppleLogin = async () => {
    try {
      signInWithPopup(auth, appleProvider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;
          setTimeout(() => {
            navigate({
              pathname: `/home`,
            });
          }, 100);
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);
          notify(
            "error", error.message, ""
          );
          return;

          // ...
        });
    } catch (error) {
      notify(
        "error", error.message, ""
      );
      return;
    }
  }

  return (
    <Box
      p={2}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: '100%'
      }}
    >
      <TextField
        className={classes.inputClass}
        variant="outlined"
        type="email"
        label={<span style={{ fontSize: 12 }}>
          Email Address
        </span>}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ width: '100%', fontSize: '12px !important' }}
        size="small"
      />
      <TextField
        className={classes.inputClass}
        variant="outlined"
        label={<span style={{ fontSize: 12 }}>
          Password
        </span>}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ width: '100%', fontSize: '12px' }}
        size="small"
      />
      <Typography variant={'h6'} style={{ fontSize: '12px', cursor: 'pointer' }}>
        <Link onClick={() => navigate({ pathname: `/forgotPassword` })}>Forgot Password</Link>
      </Typography>
      <Button
        variant="contained"
        size="small"
        onClick={handleSubmit}
        style={{ backgroundColor: "#0079D9", width: '100%', color: "#ffffff", height: '36px', fontSize: 15 }}
      >
        Sign In
      </Button>
      <div style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Divider style={{ width: '43%' }} /> <span style={{
          fontSize: '12px',
          fontWeight: '400',
          width: '14%',
          textAlign: 'center',
          color: '#666666',
        }}>OR</span><Divider style={{ width: '43%' }} />
      </div>

      <div style={{
        display: 'flex', alignItems: 'center',
        height: '36px',
        border: 'solid 1px gray',
        padding: '10px',
        boxSizing: 'border-box',
        borderRadius: '6px',
        cursor: 'pointer',
      }}
        onClick={handleGoogleLogin}>
        <div style={{
          width: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>  <img style={{ width: "18px" }} src={GoogleIcon} /> </div>
        <div style={{ fontSize: 15 }}> Sign in with Google </div>
      </div>

      <div style={{
        display: 'flex', alignItems: 'center',
        height: '36px',
        border: 'solid 1px gray',
        padding: '10px',
        boxSizing: 'border-box',
        borderRadius: '6px',
        cursor: 'pointer',
        background: 'black',
        color: 'white'
      }}
        onClick={handleAppleLogin}>
        <div style={{
          width: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'black'
        }}>  <img style={{ width: "16px" }} src={AppleIcon} /> </div>
        <div style={{ fontSize: 15 }}> Sign in with Apple </div>
      </div>

      <div style={{
        display: 'flex', alignItems: 'center',
        height: '36px',
        border: 'solid 1px gray',
        padding: '10px',
        boxSizing: 'border-box',
        borderRadius: '6px',
        cursor: 'pointer',
        background: '#4F7CBF',
        color: 'white'
      }}
        onClick={handleFacebookLogin}>
        <div style={{
          width: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>  <img style={{ width: "14px" }} src={FacebookIcon} /> </div>
        <div style={{ fontSize: 15 }}> Sign in with Facebook </div>
      </div>
    </Box >
  );
};

export default Login;
