import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useState, useEffect } from "react";
import { grey, teal } from "@material-ui/core/colors";
import { List, Typography, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import EmailChip from "./Common/EmailChip";
import Button from "@mui/material/Button";
import KidfolioContext from "../store/KidfolioContext";
import { useNotify } from "../utils/Notify";
import Box from "@mui/material/Box";
import ClearIcon from '@mui/icons-material/Clear';
import Link from "@mui/material/Link";
import { childProfilePhoto } from "../utils/Util";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useParams } from "react-router-dom";


const styles = theme => ({
    root: {
        flexGrow: 1
    },
    primaryColor: {
        color: teal[500]
    },
    secondaryColor: {
        color: grey[700]
    },
    padding: {
        padding: 0
    },
    mainHeader: {
        alignItems: "center"
    },
    mainContent: {
        padding: 40
    },
    secondaryContainer: {
        padding: "20px 25px"
    },
});

function ShareEventDialog(props) {
    const params = useParams();
    const { classes, handleClose } = props;
    const [emailList, setEmailList] = useState([]);
    const [userImages, setUserImages] = useState([]);
    const [linkCopied, setLinkCopied] = useState(false);
    const notify = useNotify();
    const {
        sendInvite, eventInvitees, getInviteeForEvent,
        eventContributorsData, fetchImageById
    } = useContext(KidfolioContext);


    const isContributionMade = (userId) => {
        const contributed = eventContributorsData.some(item => {
            return item.contributor && item.contributor.profile.emailId === userId;
        })
        return contributed
    }

    const sendEventInvite = async (emailList) => {
        const payload = {
            "subscriptions": emailList,
            "unsubscriptions": []
        }
        await sendInvite(props.eventId, payload);
        notify('info', 'Invitation has been sent.');
        props.handleClose();
        await getInviteeForEvent(props.eventId);
    }

    const handleDelete = async (emailId) => {
        const payload = {
            "subscriptions": [],
            "unsubscriptions": [emailId]
        }
        await sendInvite(props.eventId, payload);
        notify('info', 'Invitee has been removed.');
        props.handleClose();
        await getInviteeForEvent(props.eventId);
    };

    useEffect(async () => {
        if (eventInvitees && eventInvitees.length) {
            const list = [];
            for (let index = 0; index < eventInvitees.length; index++) {
                const user = eventInvitees[index];
                if (user.invitee.imageId) {
                    const imageUrl = await fetchImageById(user.invitee.imageId);
                    list.push({ imageUrl: imageUrl, imageId: user.invitee.imageId });
                }
            }
            setUserImages(list)
        }
    }, []);


    const getInviteeImage = (invitee) => {
        let imageUrl = childProfilePhoto[0];
        if (invitee.imageId && userImages && userImages.length) {
            const user = userImages.find(item => {
                return item.imageId === invitee.imageId;
            })
            if (user) {
                imageUrl = user.imageUrl;
            }
        }
        return imageUrl;
    }

    const copyEventLink = () => {
        const origin = window.location.origin;
        const eventId = params.eventId;
        const shareUrl = `${origin}/sendGift/event/${eventId}`;
        copyToClipboard(shareUrl);
        setLinkCopied(true);
    }

    const copyToClipboard = (textToCopy) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy);
        } else {
            const dummyElement = document.createElement('span');
            dummyElement.style.whiteSpace = 'pre'
            dummyElement.textContent = textToCopy;
            document.body.appendChild(dummyElement)

            const selection = window.getSelection();
            selection?.removeAllRanges()
            const range = document.createRange()
            range.selectNode(dummyElement)
            selection?.addRange(range)
            document.execCommand('copy');
            selection?.removeAllRanges()
            document.body.removeChild(dummyElement)
        }
    }

    return (
        <Dialog
            className={classes.card}
            open={props.visible}
            onClose={() => props.handleClose}
            disableEnforceFocus
        >
            <DialogTitle><Grid container direction="row" className={classes.mainHeader}>
                <Grid item xs={10}>
                    <Typography className={classes.primaryColor} variant="h5" style={{
                        height: 20,
                        fontFamily: "Montserrat",
                        float: "left",
                        color: "#000202"
                    }}>
                        Share Event
                    </Typography>
                </Grid>
                <Grid item xs={2} align="right" className={classes.padding}>
                    <IconButton
                        edge="start"
                        align="right"
                        color="inherit"
                        aria-label="Close"
                    >
                        <CloseIcon style={{ alignItems: 'center' }} onClick={props.handleClose} />
                    </IconButton>
                </Grid>
            </Grid></DialogTitle>
            <DialogContent style={{ minHeight: '25em', minWidth: '400px', paddingBottom: 70 }}>
                <div style={{ width: '100%' }}>
                    <EmailChip setEmailList={setEmailList} sendInvite={sendEventInvite} eventData={props.eventData} />
                </div>
                <div style={{ marginTop: 10, display: 'flex', borderBottom: 'solid 1px #e8dfdf' }}>
                    {eventInvitees && eventInvitees.length > 0 && <Typography variant="h6" style={{
                        fontFamily: "Montserrat",
                        float: "left",
                        color: "#000202",
                        fontSize: 18,
                        marginBottom: 20
                    }}>
                        Invited ({props.eventData.subscribersCount})
                    </Typography>
                    }
                </div>
                <div>
                    {
                        eventInvitees.map((item, index) => (
                            <List key={index} style={{ borderBottom: 'solid 1px #e8dfdf' }}>
                                <Box key={index} style={{ minHeight: '15px', marginTop: '5px', marginBottom: '5px' }}>
                                    <Grid container>
                                        <Grid item xs={2} >
                                            <img src={getInviteeImage(item.invitee)} style={{
                                                height: '50px', width: '50px', borderRadius: "15px"
                                            }} />
                                        </Grid>
                                        <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }}>
                                            {item.invitee.fullName}
                                        </Grid>
                                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            {!isContributionMade(item.invitee.emailId) && <Link underline="none"
                                                component="button"
                                                style={{
                                                    fontSize: 14,
                                                    color: '#0078D9',
                                                    fontWeight: 'normal',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                                onClick={() => handleDelete(item.invitee.emailId)}>
                                                Remove
                                            </Link>}
                                            {isContributionMade(item.invitee.emailId) &&
                                                <div
                                                    style={{
                                                        fontSize: 14,
                                                        color: '#5F6368',
                                                        fontWeight: 'normal',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >Contributed</div>
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>
                            </List>
                        ))
                    }
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    padding: 15,
                    background: '#E0E0E0',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Button
                        style={{
                            fontSize: 16,
                        }}
                        onClick={copyEventLink}
                        startIcon={<InsertDriveFileOutlinedIcon />}>
                        Copy Link
                    </Button>
                    {linkCopied && <div style={{
                        fontSize: 12,
                        color: 'gray',
                        marginLeft: 10
                    }}
                    >(Link Copied to clipboard) </div>}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default withStyles(styles)(ShareEventDialog);