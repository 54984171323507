import { Container, Link, makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import KidfolioLogo from "../../assets/logokidfoliohorizontal.svg";
import Login from "./Login";
import Button from "@mui/material/Button";
import * as React from "react";
import Signup from "./Signup";
import { useNavigate } from "react-router-dom";
import GiftedLogo from "../../assets/Gifted_Logo.svg";

const useStyles = makeStyles({
    root: {
        padding: '8px',
        height: '100vh',
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative'
    }
});

function SignUpFlow() {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.root}>
            <Container maxWidth="sm" sx={{ border: 1, borderRadius: 2, gap: 2, margin: 4, mx: "auto", width: '30%', background: "#ffffff" }}>
                <Box sx={{ width: '90%', justifyContent: "center", padding: "auto", alignItems: "center", mx: "auto" }} >
                    <Stack spacing={2} direction="column"
                        justifyContent="center" alignItems="center">
                        <Paper sx={{
                            padding: '20px',
                            width: "347px",
                            background: "#FFFFFF",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            boxShadow: 'none',
                            border: '0.5px solid #D0D0D0'
                        }}>
                            <div style={{
                                marginBottom: '20px',
                                display: 'flex',
                                minHeight: '6vh',
                                background: 'transparent',
                                backgroundColor: 'transparent',
                                position: 'relative',
                                justifyContent: "center"
                            }}>
                                <img style={{ width: "110px" }} src={GiftedLogo} />
                            </div>
                            <div style={{
                                display: 'flex',
                                minHeight: '10vh',
                                background: 'transparent',
                                backgroundColor: 'transparent',
                                position: 'relative'
                            }}>
                                <Signup />
                            </div>
                        </Paper>
                        <Paper sx={{
                            width: "347px",
                            background: "#FFFFFF",
                            border: "0.5px solid #D0D0D0",
                            boxSizing: "border-box",
                            borderRadius: "6px", alignItems: "center",
                            height: "83px",
                            display: "flex",
                            justifyContent: "center",
                            boxShadow: 'none',
                            fontSize: 14
                        }}><div style={{
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            margin: "auto", padding: "auto"
                        }}>Already have an account? <Link style={{ cursor: 'pointer' }}
                            onClick={() => navigate({ pathname: `/Login` })}>Sign in</Link></div></Paper>
                    </Stack>
                </Box>
            </Container>
        </div>
    );
}

export default SignUpFlow;