// const firebaseConfig = {
//     apiKey: "AIzaSyAXYYgFOnSHLJqDVonMhGswbLUERyyimVg",
//     authDomain: "test-sol-payment.firebaseapp.com",
//     databaseURL: "https://test-sol-payment-default-rtdb.firebaseio.com",
//     projectId: "test-sol-payment",
//     storageBucket: "test-sol-payment.appspot.com",
//     messagingSenderId: "570520942976",
//     appId: "1:570520942976:web:9bdd4ca710e74a38ff6965"
// };
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};
export default firebaseConfig;
