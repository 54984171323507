import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Link, makeStyles } from "@material-ui/core";
import Stack from '@mui/material/Stack';
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import KidfolioLogo from "../../assets/logokidfoliohorizontal.svg"
import Login from "./Login";
import { useContext, useEffect } from "react";
import KidfolioContext from "../../store/KidfolioContext";
import { useNavigate } from "react-router-dom";
import GiftedLogo from "../../assets/Gifted_Logo.svg";
import { getFromSession } from "../../utils/Util";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

// const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

const useStyles = makeStyles({
    root: {
        padding: '8px',
        height: '100vh',
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative'
    }
});

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));

function LoginFlow() {
    const classes = useStyles();
    const kidfolioCtx = useContext(KidfolioContext);
    const user = getFromSession('user') || kidfolioCtx.user;
    const navigate = useNavigate();

    useEffect(async () => {
        if (!shouldHaveAccess()) {
            await signOut(auth);
            await kidfolioCtx.avictData();
        } else if (user) {
            navigate({
                pathname: `/home`
            })
        }
    }, []);

    const shouldHaveAccess = () => {
        const shouldHaveAccess = user && !((kidfolioCtx.loggedInUserDetails
            && kidfolioCtx.loggedInUserDetails.profile
            && kidfolioCtx.loggedInUserDetails.profile.emailId === 'guest@gifted.com') ||
            kidfolioCtx.isGuestUser());
        return shouldHaveAccess;
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="sm" sx={{ border: 1, borderRadius: 2, gap: 2, margin: 4, mx: "auto", width: '30%', background: "#ffffff" }}>
                <Box sx={{ width: '90%', justifyContent: "center", padding: "auto", alignItems: "center", mx: "auto" }} >
                    <Stack spacing={2} direction="column"
                        justifyContent="center" alignItems="center">
                        <Paper sx={{
                            width: "347px",
                            background: "#FFFFFF",
                            boxSizing: "border-box",
                            borderRadius: "6px",
                            padding: '20px',
                            boxShadow: 'none',
                            border: '0.5px solid #D0D0D0'
                        }}>
                            <div style={{
                                marginBottom: '20px',
                                display: 'flex',
                                minHeight: '6vh',
                                background: 'transparent',
                                backgroundColor: 'transparent',
                                position: 'relative',
                                justifyContent: "center"
                            }}>
                                <img style={{ width: "110px" }} src={GiftedLogo} />
                            </div>
                            <div style={{
                                display: 'flex',
                                minHeight: '10vh',
                                background: 'transparent',
                                backgroundColor: 'transparent',
                                position: 'relative'
                            }}>
                                <Login />
                            </div>
                        </Paper>
                        <Paper sx={{
                            width: "347px",
                            background: "#FFFFFF",
                            border: "0.5px solid #D0D0D0",
                            boxSizing: "border-box",
                            borderRadius: "6px", alignItems: "center",
                            height: "83px",
                            display: "flex",
                            justifyContent: "center",
                            boxShadow: 'none',
                            fontSize: 14
                        }}><div style={{
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            margin: "auto", padding: "auto"
                        }}>Dont have an account? <Link style={{ cursor: 'pointer' }} onClick={() => navigate({
                            pathname: `/signUp`,
                        })}>Sign up</Link></div></Paper>
                    </Stack>
                </Box>
            </Container>
        </div>
    );
}

export default LoginFlow;
