import {
    AppBar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GiftedLogo from "../assets/Gifted_Logo.svg";
import KidfolioContext from "../store/KidfolioContext";
import { useState, useContext, useEffect } from "react";
import UserSidebar from "./Authentication/UserSidebar";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { getFromSession } from "../utils/Util";
import { Avatar } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    title: {
        flex: 1,
        color: "gold",
        fontFamily: "Montserrat",
        fontWeight: "bold",
        cursor: "pointer",
    },
    header: {
        marginTop: '25px',
        marginBottom: '20px',
        border: 'none',
        boxShadow: 'none',
        width: '100%',
        alignItems: 'center',
        display: 'flex'

    },
    logo: {
        width: '165px',
        height: '49px',
        left: '20px'
    },
    loginBtn: {
        width: 85,
        height: 40,
        marginLeft: 15,
        marginRight: 15,
        float: "right",
        backgroundColor: "transparent",
        color: "#0078D9"
    }
}));

function Header() {
    const classes = useStyles();
    const kidfolioCtx = useContext(KidfolioContext);
    const user = getFromSession('user') || kidfolioCtx.user;
    const navigate = useNavigate();
    const urlLocation = useLocation();
    const [showLoginBtn, setShowLoginBtn] = useState(true);
    const [isGuestUser, setIsGuestUser] = useState(false);


    useEffect(() => {
        const path = urlLocation.pathname;
        if (path.indexOf('/login') === -1) {
            setShowLoginBtn(true);
        } else {
            setShowLoginBtn(false);
        }
    }, [urlLocation]);

    useEffect(() => {
        setIsGuestUser(isUserIsGuest());
    }, [kidfolioCtx.loggedInUserDetails]);

    const handleClickEvent = (x) => {
        if (kidfolioCtx.isGuestUser()) {
            return;
        }
        if (user) {
            navigate({
                pathname: `/home`,
            });
        } else {
            navigate({
                pathname: `/`,
            });
        }
    }

    const gotoHome = () => {
        navigate({
            pathname: `/login`,
        });
    }

    const shouldHaveAccess = () => {
        const shouldHaveAccess = user && !((kidfolioCtx.loggedInUserDetails
            && kidfolioCtx.loggedInUserDetails.profile
            && kidfolioCtx.loggedInUserDetails.profile.emailId === 'guest@gifted.com') ||
            kidfolioCtx.isGuestUser());
        return shouldHaveAccess;
    }

    const isUserIsGuest = () => {
        const isGuestUser = (kidfolioCtx.loggedInUserDetails
            && kidfolioCtx.loggedInUserDetails.profile
            && kidfolioCtx.loggedInUserDetails.profile.emailId === 'guest@gifted.com') ||
            kidfolioCtx.isGuestUser();
        return isGuestUser;
    }

    return (
        <AppBar position="static" style={{ boxShadow: 'none', alignItems: 'center', background: 'transparent' }}>
            <div className={classes.header}>
                <img className={classes.logo} style={{ cursor: kidfolioCtx.isGuestUser() ? 'unset' : 'pointer' }} src={GiftedLogo} onClick={handleClickEvent} />
                {
                    (shouldHaveAccess())
                        ? <UserSidebar />
                        : (showLoginBtn && !kidfolioCtx.isGuestUser())
                            ? <div style={{ float: 'right', width: 'calc(100% - 165px)' }}><Button
                                className={classes.loginBtn} onClick={gotoHome}><PersonOutlineIcon /> Login </Button> </div> : null
                }
                {isGuestUser &&
                    <div style={{
                        float: 'right', width: 'calc(100% - 165px)', color: 'black',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}>
                        Hello, Guest
                        <Avatar
                            style={{
                                height: 38,
                                width: 38,
                                marginLeft: 15,
                                backgroundColor: "#EEBC1D",
                                float: 'right'
                            }}
                        />
                    </div>
                }
            </div>
        </AppBar>
    );
}

export default Header;
