import React from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import NoChildImg from "../assets/NoChildImg.svg";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    heading: {
        display: 'flex',
        fontWeight: 700,
        fontSize: 18,
    },
    closeBtn: {
        position: 'absolute',
        right: 5,
        top: 5,
    },
    noEvents: {
        height: '420px',
        width: 350,
        flex:1 ,
        display: 'flex',
        flexDirection:'column',
        minWidth: 250,
    }
});

function NoChildDialog(props) {

    const { open } = props;
    const classes = useStyles();

    return (
        <Dialog
            className={classes.root}
            maxWidth="xs"
            open={props.visible}
        >
            <DialogContent>
                <div className={classes.noEvents}>
                    <div className={classes.closeBtn} >
                        <IconButton
                            edge="start"
                            align="right"
                            color="inherit"
                            aria-label="Close"
                            style={{ padding: 8 }}
                        >
                            <CloseIcon onClick={props.handleClose} />
                        </IconButton>
                    </div>
                    <div className={classes.heading}>New Event</div>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop: 40}}>
                        <img src={NoChildImg} style={{height: '110px', width: '80px'}}/>
                        <div style={{ textAlign : 'center',
                            fontSize: 18, lineHeight: '26px', marginTop: 20, marginBottom:40, width:275
                        }}>You dont have any child to create a new event. Do you want to add a new child now?</div>
                        <div>
                            <Button
                                variant="contained"
                                style={{
                                    height: 32,
                                    backgroundColor: "#0096FF",
                                    color: 'white'
                                }}
                                onClick={props.addNewChild}
                                >
                                Add Child
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default NoChildDialog;
