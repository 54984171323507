import React, {useCallback, useState} from 'react';
import getCroppedImg from "./CropImage";
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { styles } from './Styles';

const ImageCrop = (props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    // console.log("props.imagePreviewUrl");
    // console.log(props.img)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async (e) => {
        try {
            const croppedImage = await getCroppedImg(
                props.img,
                croppedAreaPixels,
                rotation
            )
            setCroppedImage(croppedImage)
            e.preventDefault();
            const file = dataURLtoFile(croppedImage);
            await props.setImageUrl(croppedImage);
            await props.setFile(file);
            props.handleClose();
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    const onClose = useCallback(() => {
        setCroppedImage(null)
    }, [])

    return (
        <div style={{minWidth: '30em', minHeight: '50em', display: 'flex'}}>
            <div style={{padding: '1em', overflow: 'hidden'}}>
                <Cropper
                    image={props.img}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={40 / 40}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <div>
                <div>
                    <Typography
                        variant="overline"
                    >
                        Zoom
                    </Typography>
                    <Slider
                        value={zoom}
                        min={0}
                        max={1}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => setZoom(zoom)}
                        style={{marginRight: '1em'}}
                    />
                </div>
                <div>
                    <Typography
                        variant="overline"
                    >
                        Rotation
                    </Typography>
                    <Slider
                        value={rotation}
                        min={0}
                        max={360}
                        step={1}
                        aria-labelledby="Rotation"
                        onChange={(e, rotation) => setRotation(rotation)}
                    />
                </div>
                <Button
                    onClick={showCroppedImage}
                    variant="contained"
                    color="primary"
                >
                    Upload Photo
                </Button>
                <Button
                    onClick={props.handleCancel}
                    variant="contained"
                    color="primary"
                >
                    Cancel
                </Button>
            </div>
            {/*<ImgDialog img={croppedImage} onClose={onClose} />*/}
        </div>
    )
}

// const StyledImageDemo = withStyles(styles)(ImageCrop)
export default withStyles(styles)(ImageCrop);