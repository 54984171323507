import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Box, Button, Card, Divider, List, makeStyles, Typography } from "@material-ui/core";
import ContributionCard from "../components/ContributionCard";
import React, { useContext, useState, useEffect } from "react";
import KidfolioContext from "../store/KidfolioContext";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import PaymentContent from "../components/PaymentContent";
import CircularProgress from '@mui/material/CircularProgress';
import NoContributionImage from "../assets/NoContributionImage.svg"
import DeleteIcon from "../assets/DeleteIcon.svg";
import ChildGiftList from "../components/SideComponent/ChildPage/ChildGiftList";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNotify } from "../utils/Notify";
import EditIcon from "../assets/EditIcon.svg";
import EditEventDialog from "../components/EditEventDialog";
import ShareEventClickable from "../components/ShareEventClickable";
import { getBackgroundColor } from "../utils/Util";
import EventPageGiftList from "../components/SideComponent/EventPage/EventPageGiftList";

const useStyles = makeStyles({
    eventPage: {
        paddingTop: 30,
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingLeft: '0',
        width: '100% ',
        marginLeft: '0px',
        marginTop: 20
    },
    innerMainData: {
        display: 'flex',
        flex: 1,
        minWidth: 300,
        marginRight: 0,
        ['@media (min-width:780px)']: {
            marginRight: 20,
        }
    },
    innerWalletData: {
        display: 'flex',
        flex: 1,
        marginLeft: 0,
        marginBottom: 20,
        width: "100%",
        ['@media (min-width:780px)']: {
            maxWidth: 314,
        }
    },
    card: {
        width: '100%',
        margin: '0px 20px 0px 0px',
        mixBlendMode: "multiply",
    },
    cardLayout: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '20px',
        paddingLeft: '0px',
    },
    leftUpperDiv: {
        background: 'transparent',
        backgroundColor: 'transparent',
    },
    leftLowerDiv: {
        marginTop: '20px',
        background: 'transparent',
        backgroundColor: 'transparent',
    },
    divider: {
        marginBottom: '30px'
    },
    sendGiftBoxLayout: {
        position: "relative",
        left: "0",
        right: "0",
        height: "50%"
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    backDrop: {
        zIndex: 1000
    },
    noContribution: {
        background: "#FDCA80",
        boxShadow: "-10px 48.5967px 140px rgba(126, 123, 160, 0.2)",
        backdropFilter: "blur(19px)",
        borderRadius: "34px",
        height: '420px',
        maxWidth: '400px',
        padding: 30,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 250,
        margin: 'auto',
        marginTop: 30
    },
});

function EventsPage(props) {

    const classes = useStyles();
    let params = useParams();
    const ctx = useContext(KidfolioContext);
    const [childData, setChildData] = useState(null);
    const [editEvent, setEditEvent] = useState(false);
    const [childEventInfo, setChildEventInfo] = useState(null);
    const [openPaymentModel, setOpenPaymentModel] = useState(false);
    const [openEventDeleteConfirmModel, setOpenEventDeleteConfirmModel] = useState(false);
    const [beneficiaryWalletAddress, setBeneficiaryWalletAddress] = useState('NA');
    const navigate = useNavigate();
    const notify = useNotify();

    useEffect(async () => {
        const beneficiaryId = params.beneficiaryId;
        const eventId = params.eventId;
        await ctx.fetchEventContributorsData(beneficiaryId, eventId);
        await ctx.getInviteeForEvent(eventId);
    }, []);

    useEffect(async () => {
        if (!ctx.childData || !ctx.childData.beneficiaries?.length) {
            await ctx.fetchChildren();
        }
    }, []);

    useEffect(async () => {
        const beneficiaryId = params.beneficiaryId;
        await ctx.fetchChildEvents(beneficiaryId);
    }, []);

    useEffect(() => {
        if (ctx.childData && !childData) {
            const beneficiaryId = params.beneficiaryId;
            const childData = ctx.childData?.beneficiaries?.find((beneficiary) => {
                return beneficiary.id == beneficiaryId;
            });
            if (childData && childData.paymentOptions && childData.paymentOptions.filter(e => e.vendorName === 'Solana Pay')[0].identifier) {
                setBeneficiaryWalletAddress(childData.paymentOptions.filter(e => e.vendorName === 'Solana Pay')[0].identifier);
            }
            setChildData(childData);
        }
    }, [ctx.childData]);

    useEffect(() => {
        if (ctx.childEventData) {
            const eventId = params.eventId;
            const selectedEvent = ctx.childEventData.find((event) => {
                return event.post._id === eventId;
            });
            setChildEventInfo(selectedEvent);
        }
    }, [ctx.childEventData]);

    useEffect(() => {
        return () => {
            setChildData(null);
            setChildEventInfo(null);
        };
    }, []);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function closePaymentModelHandler() {
        console.log("Inside send gift handler");
        setOpenPaymentModel(false);
    }

    const gotoHome = () => {
        navigate({
            pathname: `/home`,
        });
    }

    const gotoChildPage = () => {
        navigate({
            pathname: `/child/${childData.id}`,
        });
    }

    const deleteEventConfirm = () => {
        setOpenEventDeleteConfirmModel(true);
    }

    const closeDeleteEventConfirm = () => {
        setOpenEventDeleteConfirmModel(false);
    }

    const deleteEvent = async () => {
        await ctx.deleteEvent(childEventInfo.post._id);
        closeDeleteEventConfirm();
        gotoChildPage();
        notify("success", "Event deleted successfully.", "");
    }

    const editEventDetail = () => {
        if (!childEventInfo.post.contributions.length) {
            setEditEvent(true);
        } else {
            notify('warning', 'Contribution is made for this event hence cannot be edited now.', "");
        }
    }

    const handleClose = () => {
        setEditEvent(false);
    }

    const getEventImage = () => {
        return childEventInfo.post.defaultImage;
    }

    const getEventImageColorClass = () => {
        const eventId = childEventInfo.post._id;
        const eventName = childEventInfo.post.title;
        const colorCode = ctx.eventImageColorCode[eventId];
        return `sprite-${colorCode}-medium ${colorCode}_${eventName.substring(0, 1).toUpperCase()}`;
    }

    const getEventBgColor = () => {
        let bgColor = 'none';
        if (!getEventImage()) {
            const eventId = childEventInfo.post._id;
            const colorCode = ctx.eventImageColorCode[eventId];
            bgColor = getBackgroundColor(colorCode);
        }
        return bgColor
    }

    return (
        <div className={classes.eventPage}>
            {(!ctx.eventContributorsData || !childData || !childEventInfo) ?
                <Backdrop
                    className={classes.backDrop}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <React.Fragment>
                    <div role="presentation">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                color="inherit"
                                href="#"
                                onClick={gotoHome}
                            >
                                Home
                            </Link>
                            <Link
                                underline="hover"
                                color="inherit"
                                href="#"
                                onClick={gotoChildPage}
                            >
                                <Typography color="text.primary">{capitalizeFirstLetter(childData.profile.fullName)}</Typography>
                            </Link>
                            <Typography color="text.primary"> {childEventInfo.post.title}</Typography>
                        </Breadcrumbs>
                    </div>

                    <div className={classes.innerContainer} style={{ background: 'transparent' }} >
                        <div className={classes.innerMainData} style={{ background: 'transparent', paddingLeft: 0 }}>
                            <Paper sx={{
                                backgroundColor: 'transparent',
                                textAlign: 'center',
                                minHeight: '100%',
                                boxShadow: 'none',
                                width: '100%'
                            }} id={'leftUpper'}>
                                <div className={classes.leftUpperDiv}>
                                    <Paper style={{
                                        border: 'none', background: 'transparent', boxShadow: 'none'
                                    }}>
                                        <Card variant="elevation" elevation="0" className={classes.card}>
                                            <div className={classes.cardLayout}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginRight: 40,
                                                    width: '150px',
                                                    height: '150px',
                                                    borderRadius: 15,
                                                    backgroundColor: `${getEventBgColor()}`
                                                }}>
                                                    <> {getEventImage() ?
                                                        <img src={getEventImage()} style={{
                                                            width: '150px', height: '100%', borderRadius: 15
                                                        }} />
                                                        : <div className={getEventImageColorClass()}></div>
                                                    }
                                                    </>
                                                </div>
                                                <div style={{
                                                    flex: 4,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    padding: '10px 0px',
                                                }}>

                                                    <div style={{
                                                        display: 'flex',
                                                        fontSize: "21px",
                                                        fontWeight: "500",
                                                        alignItems: 'center',
                                                    }}>
                                                        {childEventInfo.post.title}
                                                        <Link underline="none"
                                                            component="button"
                                                            title={childEventInfo.post.contributions.length ? 'Contribution is made for this event hence cannot edit' : ''}
                                                            style={{
                                                                paddingLeft: 30,
                                                                fontSize: 14,
                                                                color: '#a09b9c',
                                                                fontWeight: 'normal',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={editEventDetail}>
                                                            <img src={EditIcon} style={{
                                                                width: 16,
                                                                height: 16,
                                                                marginRight: 4
                                                            }} />Edit
                                                        </Link>
                                                        <Link href="#" underline="none" style={{
                                                            paddingLeft: 30,
                                                            fontSize: 14,
                                                            color: '#a09b9c',
                                                            fontWeight: 'normal',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                            onClick={deleteEventConfirm}>
                                                            <img src={DeleteIcon} style={{
                                                                width: 16,
                                                                height: 16,
                                                                marginRight: 4
                                                            }} />Delete
                                                        </Link>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        paddingTop: 5,
                                                        flexWrap: 'wrap'
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                        }}>
                                                            <Typography variant="subtitle1" gutterBottom component="div"
                                                                style={{
                                                                    color: "#000000",
                                                                    fontWeight: "500",
                                                                    display: 'flex',
                                                                    fontSize: 15,
                                                                    height: 20
                                                                }}>
                                                                {dayjs(childEventInfo.post.dateTime).format("MMM DD, YYYY")}
                                                            </Typography>
                                                        </div>
                                                        <div style={{ display: 'flex' }}>
                                                            <ShareEventClickable eventId={params.eventId} eventData={childEventInfo} />
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        textAlign: 'left',
                                                        paddingTop: 5
                                                    }}>
                                                        {childEventInfo.post.description}
                                                    </div>

                                                </div>
                                            </div>
                                        </Card>
                                    </Paper>
                                    {editEvent ? <EditEventDialog eventData={childEventInfo} visible={editEvent} handleClose={handleClose} /> : <div />}
                                </div>
                                <div className={classes.leftLowerDiv}>
                                    <Typography variant="subtitle1" gutterBottom component="div" align="left" className={classes.eventsLayout}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                        CONTRIBUTORS
                                    </Typography>
                                    <Divider className={classes.divider} />
                                    <Grid container spacing={2} height={'80%'} style={{ background: 'transparent', marginLeft: '-20px', marginRight: '-20px' }} >
                                        {
                                            (ctx.eventContributorsData && ctx.eventContributorsData.length) ?
                                                ctx.eventContributorsData.map((contributor, index) => (
                                                    (contributor) ?
                                                        <Grid lg={4} key={index} style={{ padding: '24px 24px 24px 24px' }}>
                                                            {<ContributionCard eventDetails={contributor} />}
                                                        </Grid> : <div></div>
                                                )) :
                                                <div className={classes.noContribution}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <img src={NoContributionImage} style={{
                                                            width: '165px'
                                                        }} />
                                                        <div style={{
                                                            fontSize: 18, lineHeight: '26px', marginTop: 20, marginBottom: 40, width: 240,
                                                            color: '#1A1A1A'
                                                        }}>There are no contributors for this event yet !</div>
                                                        <div style={{ width: 270, marginBottom: 40, fontSize: 15, color: '#666666' }}>But you can share this event with more friends and family</div>
                                                        <div>
                                                            <ShareEventClickable eventId={params.eventId} eventData={childEventInfo} />
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </Grid>
                                </div>
                            </Paper>
                        </div>
                        <div className={classes.innerWalletData} style={{ background: 'transparent' }}>
                            <EventPageGiftList beneficiaryWalletAddress={beneficiaryWalletAddress} eventId={params.eventId} childEvent={childEventInfo} />
                        </div>
                    </div>
                </React.Fragment>
            }
            {openPaymentModel ? <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openPaymentModel}
                onClose={closePaymentModelHandler}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Box style={{ width: '40%', background: '#ffffff' }}>
                    <PaymentContent onSuccess={closePaymentModelHandler} />
                </Box>
            </Modal> : <div />}
            {openEventDeleteConfirmModel ? <Dialog
                open={openEventDeleteConfirmModel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Event"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure want to delete the event and all the gifts and contribution associated with the event?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteEventConfirm} autoFocus>Cancel</Button>
                    <Button onClick={deleteEvent}>Yes, Delete</Button>
                </DialogActions>
            </Dialog> : <div />}
        </div>


    );
}

export default EventsPage;
