import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Avatar, Button, Dialog } from "@material-ui/core";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase";
import { useNotify } from "../../utils/Notify";
import KidfolioContext from "../../store/KidfolioContext";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getFromSession } from "../../utils/Util";
import NotificationsIcon from '@mui/icons-material/Notifications';
import UserProfile from "../User/UserProfile";

const useStyles = makeStyles({
  container: {
    width: 350,
    padding: 25,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "monospace",
  },
  profile: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    height: "92%",
  },
  logout: {
    height: "8%",
    width: "100%",
    backgroundColor: "#EEBC1D",
    marginTop: 20,
  },
  picture: {
    width: 200,
    height: 200,
    cursor: "pointer",
    backgroundColor: "#EEBC1D",
    objectFit: "contain",
  },
  watchlist: {
    flex: 1,
    width: "100%",
    backgroundColor: "grey",
    borderRadius: 10,
    padding: 15,
    paddingTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 12,
    overflowY: "scroll",
  },
  coin: {
    padding: 10,
    borderRadius: 5,
    color: "black",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#EEBC1D",
    boxShadow: "0 0 3px black",
  },
});

export default function UserSidebar() {
  const classes = useStyles();
  const [userImage, setUserImage] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const kidfolioCtx = useContext(KidfolioContext);
  const user = getFromSession('user') || kidfolioCtx.user;
  const navigate = useNavigate();

  useEffect(() => {
    setUserImage(kidfolioCtx.userProfileImageUrl);
  }, []);

  useEffect(() => {
    setUserImage(kidfolioCtx.userProfileImageUrl);
  }, [kidfolioCtx.userProfileImageUrl]);

  const capitalizeFirstLetter = (string) => {
    if (!string) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const handleOpen = () => {
    setOpenDialog(true);
  }

  const handleClose = () => {
    setOpenDialog(false);
  }

  const showUserSideBar = () => {
    const path = window.location.href;
    return !(path.includes('signUp') || path.includes('addUserDetails') || path.includes('addChild'));
  }

  return (
    <>
      {(showUserSideBar()) ?
        <div style={{ float: "right", width: '100%', color: '#000000' }}>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Avatar
                onClick={handleOpen}
                style={{
                  height: 38,
                  width: 38,
                  marginLeft: 15,
                  cursor: "pointer",
                  backgroundColor: "#EEBC1D",
                  float: 'right'
                }}
                src={userImage}
                alt={user?.displayName || user?.email}
              />
              <NotificationsIcon style={{
                height: 38,
                width: 38,
                marginLeft: 15,
                cursor: "pointer",
                float: 'right'
              }} onClick={() => navigate({ pathname: '/eventNotification' })} />
              <Typography style={{
                verticalAlign: 'middle', fontFamily: "Montserrat",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "22px",
                letterSpacing: "0px",
                textAlign: "right",
                paddingLeft: "25px", marginBottom: "0",
                marginTop: "12px"
              }}>
                {kidfolioCtx.loggedInUserDetails?.profile?.fullName && `Hello, ${capitalizeFirstLetter(kidfolioCtx.loggedInUserDetails?.profile?.fullName)}`}
              </Typography>
              {openDialog ? <UserProfile open={openDialog} handleClose={handleClose} /> : <div />}
              {/*<Drawer*/}
              {/*  anchor={anchor}*/}
              {/*  open={state[anchor]}*/}
              {/*  onClose={toggleDrawer(anchor, false)}*/}
              {/*>*/}
              {/*  <div className={classes.container}>*/}
              {/*    <div className={classes.profile}>*/}
              {/*      <Avatar*/}
              {/*        className={classes.picture}*/}
              {/*        src={user?.photoURL}*/}
              {/*        alt={user?.displayName || user?.email}*/}
              {/*      />*/}
              {/*      <span*/}
              {/*        style={{*/}
              {/*          width: "100%",*/}
              {/*          fontSize: 25,*/}
              {/*          textAlign: "center",*/}
              {/*          fontWeight: "bolder",*/}
              {/*          wordWrap: "break-word",*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        {user?.displayName || user?.email}*/}
              {/*        {anchor}*/}
              {/*        /!*{state[anchor]}*!/*/}
              {/*      </span>*/}
              {/*    </div>*/}
              {/*    <Button*/}
              {/*      variant="contained"*/}
              {/*      className={classes.logout}*/}
              {/*      onClick={logOut}*/}
              {/*    >*/}
              {/*      Log Out*/}
              {/*    </Button>*/}
              {/*  </div>*/}
              {/*</Drawer>*/}
              {/*<Dialog open={state[anchor]} maxWidth="xs">*/}
              {/*</Dialog>*/}
            </React.Fragment>
          ))}
        </div>
        : <div />}
    </>
  );
}
