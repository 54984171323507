import React, { useState } from "react";
import "./EmailChip.css";
import { List, Typography } from "@material-ui/core";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import ClearIcon from '@mui/icons-material/Clear';
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";


function EmailChip(props) {
    const [items, setItems] = useState([]);
    const [value, setValue] = useState("");
    const [error, setError] = useState(null);


    const handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            let trimValue = value.trim();

            if (trimValue && isValid(trimValue)) {
                setItems([...items, value]);
                props.setEmailList([...items, value]);
                setValue("");
            }
        }
    };

    const handleChange = evt => {
        setValue(evt.target.value);
        setError(null);
    };

    // const handleDelete = item => {
    //     setItems(items.filter(i => i !== item))
    //     props.setEmailList(items.filter(i => i !== item));
    // };

    // const handlePaste = evt => {
    //     evt.preventDefault();

    //     let paste = evt.clipboardData.getData("text");
    //     let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    //     if (emails) {
    //         const toBeAdded = emails.filter(email => !isInList(email));
    //         setItems([...items, ...toBeAdded]);
    //         props.setEmailList([...items, ...toBeAdded]);
    //     }
    // };

    const isValid = (email) => {
        let error = null;

        if (isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            setError(error);
            return false;
        }

        return true;
    }

    const isInList = (email) => {
        return items.includes(email);
    }

    const isEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }

    const processSendEmail = () => {
        let emails = [];
        let trimValue = value.trim();
        let errorFound = false;
        const mailList = trimValue.split(',');

        for (let index = 0; index < mailList.length; index++) {
            const element = mailList[index];
            let trimValue = element.trim();
            if (trimValue && !isValid(trimValue)) {
                errorFound = true;
                break;
            } else {
                emails.push(trimValue);
            }
        }
        if (!errorFound) {
            console.log(emails);
            props.sendInvite(emails);
            setValue("");
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <div style={{ marginRight: 10, width: '60%', minWidth: '263px', marginTop: 10 }}>
                    <input
                        className={"input " + (error && " has-error")}
                        value={value}
                        placeholder="Email, comma seperated"
                        onChange={handleChange}
                        width={'10em'}
                    />
                </div>
                <div style={{ width: 130, marginTop: 10 }}>
                    <Button style={{ width: '130px' }} variant={'contained'} onClick={processSendEmail} disabled={(value.length === 0)}>Send Invite</Button>
                </div>
            </div>
            <div className="error">{error}</div>
        </div>
    );
}

export default EmailChip;