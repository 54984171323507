import {createContext, useState} from "react";

const PaymentDetailContext = createContext({
    paymentDetail: {
        public_address: "",
        amount: 0
    },
    setPaymentDetail: () => {}
});

export function PaymentDetailContextProvider(props) {
    const [paymentDetails, setPaymentDetails] = useState({
        public_address: "",
        amount: 0
    });

    function setPaymentDetailHandler(value) {
        return setPaymentDetails(() => {return value});
    }

    const context = {
        paymentDetail: paymentDetails,
        setPaymentDetail: setPaymentDetailHandler
    };

    return (
        <PaymentDetailContext.Provider value={context}>
            {props.children}
        </PaymentDetailContext.Provider>
    )
}

export default PaymentDetailContext;