import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, Card, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import KidfolioContext from "../store/KidfolioContext";
import AddEventCard from "../components/AddEventCard";
import { PieChart } from 'react-minimal-pie-chart';
import Events from "../components/EventsTabComponent";
import EditIcon from "../assets/EditIcon.svg";
import DeleteIcon from "../assets/DeleteIcon.svg";
import dayjs from "dayjs";
import { useParams } from "react-router-dom"
import Link from '@mui/material/Link';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useNavigate } from "react-router-dom";
import ChildGiftList from "../components/SideComponent/ChildPage/ChildGiftList";
import EditChildDialog from '../components/EditChildDialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import AddIcon from '@mui/icons-material/Add';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNotify } from "../utils/Notify";
import { getChildProfile } from '../utils/Util';


const useStyles = makeStyles({
    childPage: {
        paddingTop: 30,
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingLeft: '0',
        width: '100% ',
        marginLeft: '0px',
        marginTop: 20
    },
    innerMainData: {
        display: 'flex',
        flex: 1,
        minWidth: 300,
        marginRight: 0,
        ['@media (min-width:780px)']: {
            marginRight: 20,
        }
    },
    innerWalletData: {
        display: 'flex',
        flex: 1,
        marginLeft: 0,
        marginBottom: 20,
        width: "100%",
        ['@media (min-width:780px)']: {
            maxWidth: 314,
        }
    },
    card: {
        // backgroundColor: '#FEA621',
        width: '100%',
        margin: '0px 20px 0px 0px',
        // borderRadius: '20px',
        mixBlendMode: "multiply",
        // boxShadow: "0px 4.478689670562744px 15.675413131713867px 6.718034744262695px #FFFFFF33 inset, -22.393447875976562px 105.24920654296875px 161.23281860351562px 0px #807D7D29",
    },
    cardLayout: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '20px',
        paddingLeft: 0
    },
    leftUpperDiv: {
        background: 'transparent',
        backgroundColor: 'transparent',
    },
    leftLowerDiv: {
        marginTop: '20px',
        background: 'transparent',
        backgroundColor: 'transparent',
    },
    divider: {
        marginTop: '5px',
        marginBottom: '5px'
    }
});

function ChildPage(props) {
    const classes = useStyles();
    const ctx = useContext(KidfolioContext);
    const [childData, setChildData] = useState(null);
    const [childEventData, setChildEventData] = useState(null);
    const [beneficiaryWalletAddress, setBeneficiaryWalletAddress] = useState('NA');
    let params = useParams();
    const navigate = useNavigate();
    const [editChild, setEditChild] = useState(false);
    const [openChildDeleteConfirmModel, setOpenChildDeleteConfirmModel] = useState(false);
    const notify = useNotify();

    useEffect(async () => {
        await ctx.fetchChildEvents(params.id);
    }, []);

    useEffect(async () => {
        if (ctx.childEventData) {
            setChildEventData(ctx.childEventData);
        }
    }, [ctx.childEventData]);

    useEffect(async () => {
        if (!ctx.beneficiariesData || !ctx.beneficiariesData.data?.beneficiaries?.length) {
            await ctx.fetchChildren();
        } else {
            const childData = ctx.beneficiariesData.data?.beneficiaries?.filter((beneficiary) => {
                return beneficiary.id ? beneficiary.id == params.id : null;
            });
            if (childData[0].paymentOptions && childData[0].paymentOptions.filter(e => e.vendorName === 'Solana Pay')[0].identifier) {
                setBeneficiaryWalletAddress(childData[0].paymentOptions.filter(e => e.vendorName === 'Solana Pay')[0].identifier);
            }
            setChildData(childData);
        }
    }, [ctx.beneficiariesData]);


    useEffect(() => {
        return () => {
            setChildData(null);
            setChildEventData(null);
            ctx.resetChildPage();
        };
    }, []);

    const getChildPhoto = () => {
        const benId = childData[0].id;
        return getChildProfile(ctx.beneficiaryImageUrl, benId);
    }

    const editChildDetail = () => {
        setEditChild(true);
    }

    const handleClose = () => {
        setEditChild(false);
    }

    const capitalizeFirstLetter = (string) => {
        if (!string) {
            return "";
        }
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const gotoHome = () => {
        navigate({
            pathname: `/home`,
        });
    }

    const deleteChildConfirm = () => {
        setOpenChildDeleteConfirmModel(true);
    }

    const closeDeleteChildConfirm = () => {
        setOpenChildDeleteConfirmModel(false);
    }

    const deleteChild = async () => {
        await ctx.deleteBeneficiary(childData[0].id);
        setChildData(null);
        setChildEventData(null);
        ctx.resetChildPage();
        await ctx.fetchChildren();
        closeDeleteChildConfirm();
        gotoHome();
        notify("success", "Child profile removed successfully.", "");
    }

    return (
        <div className={classes.childPage}>
            {(childData && childEventData) ?
                <React.Fragment>
                    <div role="presentation">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link
                                underline="hover"
                                color="inherit"
                                href="#"
                                onClick={gotoHome}
                            >
                                Home
                            </Link>
                            <Typography color="text.primary">{capitalizeFirstLetter(childData[0].profile.fullName)}</Typography>
                        </Breadcrumbs>
                    </div>

                    <div className={classes.innerContainer} style={{ background: 'transparent' }} >
                        <div className={classes.innerMainData} style={{ background: 'transparent', paddingLeft: 0 }}>
                            <Paper sx={{
                                backgroundColor: 'transparent',
                                textAlign: 'center',
                                minHeight: '100%',
                                boxShadow: 'none',
                                width: '100%'
                            }} id={'leftUpper'}>
                                <div className={classes.leftUpperDiv}>
                                    <Paper style={{
                                        border: 'none', background: 'transparent', boxShadow: 'none'
                                    }}>
                                        <Card variant="elevation" elevation="0" className={classes.card}>
                                            <div className={classes.cardLayout}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    paddingRight: 40
                                                }}>
                                                    <img src={getChildPhoto()} style={{
                                                        width: '100px',
                                                        borderRadius: "15px"
                                                    }} />
                                                </div>
                                                <div style={{
                                                    flex: 4,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-evenly'
                                                }}>

                                                    <div style={{
                                                        display: 'flex',
                                                        fontSize: "21px",
                                                        fontWeight: "500",
                                                        alignItems: 'center',
                                                        paddingTop: 15,
                                                        paddingBottom: 5
                                                    }}>{capitalizeFirstLetter(childData[0].profile.fullName)}
                                                        <Link href="#" underline="none" style={{
                                                            paddingLeft: 30,
                                                            fontSize: 14,
                                                            color: '#a09b9c',
                                                            fontWeight: 'normal',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                            onClick={editChildDetail}>
                                                            <img src={EditIcon} style={{
                                                                width: 16,
                                                                height: 16,
                                                                marginRight: 4
                                                            }} />Edit
                                                        </Link>
                                                        <Link href="#" underline="none" style={{
                                                            paddingLeft: 30,
                                                            fontSize: 14,
                                                            color: '#a09b9c',
                                                            fontWeight: 'normal',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                            onClick={deleteChildConfirm}>
                                                            <img src={DeleteIcon} style={{
                                                                width: 16,
                                                                height: 16,
                                                                marginRight: 4
                                                            }} />Delete
                                                        </Link>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        flexWrap: 'wrap',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            paddingBottom: 15
                                                        }}>
                                                            <Typography variant="subtitle1" gutterBottom component="div"
                                                                style={{
                                                                    color: "#000000",
                                                                    fontWeight: "500",
                                                                    display: 'flex',
                                                                    fontSize: 14,
                                                                    minWidth: 30
                                                                }}>
                                                                {childEventData ? childEventData.length : '0'} #
                                                            </Typography>
                                                            <Typography variant="subtitle1" gutterBottom component="div"
                                                                style={{
                                                                    color: "#000000",
                                                                    fontWeight: "350",
                                                                    fontSize: 14,
                                                                    minWidth: 75
                                                                }}>
                                                                &nbsp;of Events
                                                            </Typography>

                                                            <div style={{
                                                                display: 'flex',
                                                                paddingLeft: 50
                                                            }}>
                                                                <Typography variant="subtitle1" gutterBottom component="div" align="left"
                                                                    style={{
                                                                        width: 75,
                                                                        color: "#000000",
                                                                        fontWeight: "350",
                                                                        display: 'flex',
                                                                        fontSize: 14,
                                                                        height: 20
                                                                    }}>
                                                                    Birthday
                                                                </Typography>
                                                                <Typography variant="subtitle1" gutterBottom component="div"
                                                                    style={{
                                                                        color: "#000000",
                                                                        fontWeight: "500",
                                                                        fontSize: 14,
                                                                        height: 20
                                                                    }}>
                                                                    {dayjs(childData[0].profile.birthDate).format("MMM DD, YYYY")}
                                                                </Typography>
                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            display: 'flex',
                                                            paddingBottom: 15
                                                        }}>
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                    height: 32,
                                                                    backgroundColor: "#0096FF",
                                                                    color: 'white'
                                                                }}
                                                                startIcon={<AddIcon />}
                                                            >
                                                                <AddEventCard childName={capitalizeFirstLetter(childData[0].profile.fullName)} childId={params.id} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Paper>
                                </div>
                                <div className={classes.leftLowerDiv}>
                                    <Events childId={params.id} childData={childData[0]} />
                                </div>
                                {openChildDeleteConfirmModel ? <Dialog
                                    open={openChildDeleteConfirmModel}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Delete Child"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure want to delete this child profile ? All the child events and associated information will be deleted as well.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={closeDeleteChildConfirm} autoFocus>Cancel</Button>
                                        <Button onClick={deleteChild}>Yes, Delete</Button>
                                    </DialogActions>
                                </Dialog> : <div />}
                            </Paper>
                        </div>
                        <div className={classes.innerWalletData} style={{ background: 'transparent' }}>
                            <ChildGiftList beneficiaryWalletAddress={beneficiaryWalletAddress} />
                        </div>
                    </div>
                    {editChild ? <EditChildDialog childData={childData[0]} childPhoto={getChildPhoto()} visible={editChild} handleClose={handleClose} /> : <div />}
                </React.Fragment>
                :
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </div>
    );
}

export default ChildPage;
