import { Button, Card, Dialog, makeStyles, TextField } from "@material-ui/core";
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import AddChildDialog from "./AddChildDialog";

const useStyles = makeStyles({
    card: {
        background: "#EBE9E9",
        width: '184px',
        height: '210px',
        marginRight: 20,
        borderRadius: '30px',
        transformStyle: "preserve-3d",
        cursor: 'pointer',
        border: 'none',
        boxShadow: 'none'
    }
});

function AddChildCard() {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const handleClose = () => {
        setVisible(false);
    }

    const addChildHandler = () => {
        setVisible(true);
    }

    return (
        <div>
            <Card className={classes.card} onClick={addChildHandler}>
                <div style={{ fontSize: 15, justifyContent: 'center', height: '100%', display: "flex", alignItems: "center" }} >
                    <AddIcon />
                    Add Child
                </div>
            </Card>
            {visible ? <AddChildDialog visible={visible} handleClose={handleClose} /> : <div />}
        </div>
    )
}

export default AddChildCard;