import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './sprite.css';
import App from './App';
import {KidfolioContextProvider} from "./store/KidfolioContext";
import Context from "./Context";
import {SnackbarProvider} from "notistack";
import {PaymentDetailContextProvider} from "./store/PaymentDetailContext";

ReactDOM.render(
  <React.StrictMode>
      <SnackbarProvider anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
      }}>
          <Context>
              <KidfolioContextProvider>
                  <PaymentDetailContextProvider>
                    <App />
                  </PaymentDetailContextProvider>
              </KidfolioContextProvider>
          </Context>
      </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

