import {useCallback, useContext, useState} from 'react';

import {useConnection, useWallet} from "@solana/wallet-adapter-react";
import {useNotify} from "../utils/Notify";
import {WalletNotConnectedError} from "@solana/wallet-adapter-base";
// import {Connection, PublicKey, SystemProgram, Transaction} from "@solana/web3.js";
import {wait} from "@testing-library/user-event/dist/utils";
import {makeStyles, MenuItem, Select, TextField} from "@material-ui/core";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PaymentDetailContext from "../store/PaymentDetailContext";
import {WalletMultiButton} from "@solana/wallet-adapter-material-ui";
import KidfolioContext from "../store/KidfolioContext";
import Divider from "@material-ui/core/Divider";
import * as splToken from "@solana/spl-token";
import { web3, Wallet } from "@project-serum/anchor";
import {Keypair} from "@solana/web3.js";

const defaultValues = {
    public_address: "",
    amount: 0
};

const useStyles = makeStyles({
    leftUpperDiv: {
        padding: '8px',
        display: 'scroll',
        minHeight: '5vh',
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative'
    },
    leftLowerDiv: {
        padding: '8px',
        minHeight: '55vh',
        marginTop: '20px',
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative'
    },
})

function PaymentContent(props) {
    const { connection } = useConnection();
    const { publicKey, sendTransaction, wallet } = useWallet();
    const notify = useNotify();
    const classes = useStyles();
    // let paymentData = {
    //     public_address: "",
    //     amount: 0
    // }
    const [formValues, setFormValues] = useState(defaultValues);
    const paymentDetailCtx = useContext(PaymentDetailContext);
    const paymentDetails = paymentDetailCtx.paymentDetail;
    const kidfolioCtx = useContext(KidfolioContext);
    const childList = kidfolioCtx.childData;
    const [selectedValue, setSelectedValue] = useState('')

    const handleInputChange = async (e) => {
        let {name, value} = e.target;
        let address = '';
        setSelectedValue(value)
        if(name === "public_address") {
            address = childList.beneficiaries.find(child => child.id === value);
            if(address.paymentOptions) {
                value = address.paymentOptions.filter(e => e.vendorName === 'Solana Pay')[0].identifier;
            }
        }
        await setFormValues({
            ...formValues,
            [name]: value,
        });
        await paymentDetailCtx.setPaymentDetail({
            ...formValues,
            [name]: value
        });
    };

    const initiateUSDCPayment = async () => {
        if (!publicKey) throw new WalletNotConnectedError();
        // TODO: Move to env file and select based on environment.
        const tokenMintAddress = process.env.REACT_APP_USDC_MINT_ADDRESS;
        const mintPublicKey = new web3.PublicKey(tokenMintAddress);
        const {TOKEN_PROGRAM_ID} = splToken;
        const anotherKeypair = Keypair.generate();
        const fromTokenAccount = await splToken.getOrCreateAssociatedTokenAccount(
            connection,
            wallet.payer,
            mintPublicKey,
            publicKey,
            true
        );

        const destPublicKey = new web3.PublicKey(paymentDetails.public_address);
        // Get the derived address of the destination wallet which will hold the custom token
        const associatedDestinationTokenAddr = await splToken.getOrCreateAssociatedTokenAccount(
            connection,
            wallet.payer,
            mintPublicKey,
            destPublicKey,
            true
        );


        const receiverAccount = await connection.getAccountInfo(associatedDestinationTokenAddr.address);

        const instructions = [];

        instructions.push(
            splToken.createTransferInstruction(
                fromTokenAccount.address,
                associatedDestinationTokenAddr.address,
                publicKey,
                1,
                [],
                TOKEN_PROGRAM_ID
            )
        );
        const amountToSend = paymentDetails.amount * 1000000;

        let transaction = new web3.Transaction().add(splToken.createTransferInstruction(
            fromTokenAccount.address,
            associatedDestinationTokenAddr.address,
            publicKey,
            amountToSend,
            [],
            TOKEN_PROGRAM_ID
        ))

        const signature = await sendTransaction(transaction, connection);
        notify('info', 'Transaction sent:', signature);

        await connection.confirmTransaction(signature, 'processed').then(props.onSuccess);
        notify('success', 'Transaction successful!', signature);

        //TODO: Here for reference.
        //
        // Sign transaction, broadcast, and confirm
        // let signature = await web3.sendAndConfirmTransaction(
        //     connection,
        //     transaction,
        //     [anotherKeypair]
        // );
        // console.log("SIGNATURE", signature);
        // console.log("SUCCESS");

        // const transaction = new web3.Transaction().add(...instructions);
        // transaction.feePayer = publicKey;
        // transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        // console.log("Transaction", transaction);
        // // transaction.signatures = [{publicKey: publicKey}];
        //
        // const transactionSignature = await connection.sendRawTransaction(
        //     transaction.serialize(),
        //     {skipPreflight: true}
        // );
        // console.log("TransactionSignature", transactionSignature);
        //
        // await connection.confirmTransaction(transactionSignature);
    }

    //Fallback
    const initiateSOLPayment = useCallback(async () => {
        if (!publicKey) throw new WalletNotConnectedError();
        await wait(1000)
        const toAddress = new PublicKey(paymentDetails.public_address);
        const lamportsToSend = paymentDetails.amount * 1000000000;

        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: toAddress,
                lamports: lamportsToSend,
            })
        );

        const signature = await sendTransaction(transaction, connection);
        notify('info', 'Transaction sent:', signature);

        await connection.confirmTransaction(signature, 'processed').then(props.onSuccess);
        notify('success', 'Transaction successful!', signature);
        // setTransactionSignature(signature);
        // handleOpenSuccessModal();

        await connection.confirmTransaction(signature, 'processed');
    }, [publicKey, sendTransaction, connection, paymentDetails]);

    const childDropDownValue = (name, id) => {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() + '-' + id
    }

    // formValues.public_address.toString().slice(0,4) + '...' + formValues.public_address.toString().slice((formValues.public_address.length - 4), formValues.public_address.length)

    return (
        <div style={{paddingTop: '20px', paddingBottom: '20px', padding: '30px'}}>
            <div className={classes.leftUpperDiv}>
                <Typography variant={'h6'} style={{fontFamily: "Montserrat",
                    fontStyle: "bold",
                    fontWeight: "700",
                    lineHeight: "22px",
                    letterSpacing: "0px",
                    textAlign: "center",
                    color: "#000000",
                    paddingTop: "10px",
                    paddingLeft: "30px",
                    float: 'left'
                }}>
                    Wallet:
                </Typography>
                <div style={{float: 'right', paddingRight: '40px'}}>
                    <WalletMultiButton color={'inherit'}/>
                </div>
            </div>
            <Divider />
            <div style={{float: 'right', padding: '20px'}}>
                <form>
                    <Select
                        labelId="publicAddress"
                        id="publicAddress"
                        value={selectedValue}
                        onChange={handleInputChange}
                        fullWidth={true}
                        autoWidth={false}
                        label="Child"
                        name="public_address"
                    >
                        {
                            (childList.beneficiaries) ? (
                                childList.beneficiaries.map((child) => (
                                    <MenuItem value={child.id || ''}>{childDropDownValue(child.profile.fullName, child.id)}</MenuItem>
                                ))) : <div/>
                        }
                    </Select>
                    <TextField
                        label="Send Gift"
                        id="amount"
                        name="amount"
                        type={"number"}
                        sx={{ m: 1, width: '25ch' }}
                        step={0.00001}
                        inputProps={{
                            maxLength: 13,
                            step: "0.00001"
                        }}
                        fullWidth={true}
                        onChange={handleInputChange}
                    />
                    &nbsp;
                    <Button style={{background: '#0079D9', marginTop: '10px', float: 'right'}} onClick={initiateUSDCPayment}><Typography style={{color: '#ffffff'}}>Send SOL</Typography></Button>
                </form>
            </div>
        </div>
    );
}

export default PaymentContent;
