import { Grid } from "@mui/material";
import { Box, List, makeStyles, Typography, Button } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import KidfolioContext from "../store/KidfolioContext";
import EventsDashboardImg from "../assets/EventsDashboardImg.svg";
import NoNotificationImage from "../assets/NoNotificationImage.svg";
import dayjs from "dayjs";
import PaymentContent from "./PaymentContent";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import NoChildDialog from "./NoChildDialog";
import AddChildDialog from "./AddChildDialog";
import SelectChildDialog from "./SelectChildDialog";
import AddEventDialog from "./AddEventDialog";
import { getChildProfile, getBackgroundColor, getColorCode } from '../utils/Util';


const useStyles = makeStyles({
    lowerPanel: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: '-20px'
    },
    upComingEvents: {
        background: "white",
        boxShadow: "-10px 48.5967px 140px rgba(126, 123, 160, 0.2)",
        backdropFilter: "blur(19px)",
        borderRadius: "34px",
        height: '420px',
        padding: 30,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 250,
        margin: 20,
        marginRight: 0,
        ['@media (min-width:780px)']: {
            marginRight: 20,
        }
    },
    noNotification: {
        background: "#F7E1E2",
        boxShadow: "-10px 48.5967px 140px rgba(126, 123, 160, 0.2)",
        backdropFilter: "blur(19px)",
        borderRadius: "34px",
        height: '420px',
        padding: 30,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 250,
        margin: 20,
        marginRight: 0,
        ['@media (min-width:780px)']: {
            marginRight: 20,
        }
    },
    noEvents: {
        background: "#D0FFF1",
        boxShadow: "-10px 48.5967px 140px rgba(126, 123, 160, 0.2)",
        backdropFilter: "blur(19px)",
        borderRadius: "34px",
        height: '420px',
        padding: 30,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 250,
        margin: 20,
        marginRight: 0,
        ['@media (min-width:780px)']: {
            marginRight: 20,
        }
    },
    upComingEventsHeading: {
        display: 'flex',
        fontWeight: 700,
        fontSize: 18,
    },
    otherInfo: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
        flex: 1,
        minWidth: 250,
        margin: 20,
        marginRight: 0,
        ['@media (min-width:780px)']: {
            marginRight: 20,
        }
    },
    eventList: {
        overflow: "scroll",
        height: '100%',
        marginTop: 15,
        alignItems: 'center',
    },
    unOpenedGifts: {
        height: '120px',
        display: "flex",
        justifyContent: 'center',
        alignItems: "flex-start",
        background: "linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%)",
        boxShadow: "-10px 48.5967px 140px rgba(126, 123, 160, 0.2)",
        backdropFilter: "blur(19px)",
        borderRadius: "34px",
        padding: "25px"
    },
    unopenedImage: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: '65px',
        right: '0px',
    },
    sendGiftBoxLayout: {
        background: "#3BFFD9",
        boxShadow: "-10px 48.5967px 140px rgba(126, 123, 160, 0.2)",
        backdropFilter: "blur(19px)",
        borderRadius: "34px",
        height: "180px",
        padding: 25,
        marginTop: 20
    },
    sendGiftBox: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    notificationImage: {
        width: "50px",
        height: "50px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        float: "left",
        borderRadius: '15px'
    }
});

function HomeEventsComponent() {
    const classes = useStyles();
    const navigate = useNavigate();
    const ctx = useContext(KidfolioContext);
    const [openPaymentModel, setOpenPaymentModel] = useState(false);
    const [noChildModel, setNoChildModel] = useState(false);
    const [addNewChildModel, setAddNewChildModel] = useState(false);
    const [selectChildModel, setSelectChildModel] = useState(false);
    const [addEventModel, setAddEventModel] = useState(false);
    const [selectedChild, setSelectedChild] = useState(null);

    const getChildPhoto = (benId) => {
        return getChildProfile(ctx.beneficiaryImageUrl, benId);
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    function closePaymentModelHandler() {
        setOpenPaymentModel(false);
    }

    function redirectMe(eventData) {
        navigate({ pathname: `/child/${eventData.beneficiaryId}/events/${eventData.id}` });
    }

    const addNewEvent = () => {
        if (!ctx.beneficiariesData.data?.beneficiaries || !ctx.beneficiariesData.data?.beneficiaries.length) {
            setNoChildModel(true);
        } else {
            setSelectChildModel(true);
        }
    }

    const addNewChild = () => {
        setNoChildModel(false);
        setAddNewChildModel(true);
    }

    const handleNoChildClose = () => {
        setNoChildModel(false);
    }

    const handleClose = () => {
        setAddNewChildModel(false);
    }

    const handleChildSelectionClose = () => {
        setSelectChildModel(false);
    }

    const childSelected = (child) => {
        setSelectedChild(child);
        setSelectChildModel(false);
        setAddEventModel(true);
    }

    const handleNewEventClose = () => {
        setAddEventModel(false);
    }

    const getNotificationImage = (noti) => {
        let image = null;
        if (isContributionNotification(noti)) {
            if (noti.data.notificationDetails) {
                const info = JSON.parse(noti.data.notificationDetails);
                const imageId = info.senderImageId;
                if (imageId) {
                    image = ctx.findBlobUrlForResourceId(imageId);
                }
            }
        }
        return image;
    }

    function getNotificationImageIcon(noti, index) {
        const colorCode = getColorCode(index);
        let name = noti.data.Heading || noti.body;
        if (isContributionNotification(noti)) {
            if (noti.data.notificationDetails) {
                const info = JSON.parse(noti.data.notificationDetails);
                const senderName = info.senderFullName;
                if (senderName) {
                    name = senderName;
                }
            }
        }
        return `sprite-${colorCode}-extraSmall ${colorCode}_${name.substring(0, 1).toUpperCase()}`;
    }

    const getNotificationImageBgColor = (index) => {
        const colorCode = getColorCode(index);
        return getBackgroundColor(colorCode);
    }

    const isContributionNotification = (noti) => {
        return noti.data && noti.data.Category === 'Contribution';
    }

    const gotoEventPage = () => {
        navigate({ pathname: `/eventNotification` });
    }

    return (
        <React.Fragment>
            <div className={classes.lowerPanel} >
                {(ctx.upcomingEventData && ctx.upcomingEventData.length) ?
                    <div className={classes.upComingEvents}>
                        <div className={classes.upComingEventsHeading}>Upcoming Events {ctx.upcomingEventData.length > 0 && `(${ctx.upcomingEventData.length})`}</div>
                        <Box className={classes.eventList}>
                            <List>
                                {
                                    ctx.upcomingEventData.slice().map((eventData, index) => (
                                        (eventData.beneficiary) ?
                                            <Box key={index} style={{ minHeight: '80px', marginTop: '5px', marginBottom: '5px', cursor: 'pointer' }}
                                                onClick={() => redirectMe(eventData)}>
                                                <Grid container>
                                                    <div style={{ width: '50px', display: 'flex', alignItems: 'center' }}>
                                                        <img src={getChildPhoto(eventData.beneficiary._id)} style={{
                                                            height: '50px', width: '50px', float: "left", borderRadius: "15px"
                                                        }} />
                                                    </div>

                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: "center",
                                                        width: 'calc(100% - 80px)',
                                                        paddingLeft: '25px'
                                                    }}>
                                                        <div >
                                                            <div style={{
                                                                fontFamily: "Montserrat",
                                                                fontWeight: "400",
                                                                fontSize: "15px",
                                                                textAlign: "left",
                                                            }}>
                                                                {capitalizeFirstLetter(eventData.beneficiary.profile?.fullName)}<br />
                                                            </div>
                                                            <div style={{
                                                                fontSize: "14px",
                                                                fontWeight: "300",
                                                                lineHeight: "15px",
                                                                letterSpacing: "0px",
                                                                textAlign: "left",
                                                                paddingTop: "8px"
                                                            }}>
                                                                {capitalizeFirstLetter(eventData.title)}
                                                            </div>
                                                        </div>
                                                        <div style={{ justifyContent: 'center', height: '100%', display: "flex", alignItems: "center" }}>
                                                            <Typography style={{
                                                                fontSize: 12,
                                                                color: '#666666'
                                                            }}>
                                                                {dayjs(eventData.dateTime).format("MMM DD, YYYY")}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Box> : <div></div>
                                    ))
                                }
                            </List>
                        </Box>
                    </div>
                    : <div className={classes.noEvents}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                            <img src={EventsDashboardImg} style={{
                                height: '85px', width: '145px'
                            }} />
                            <div style={{
                                fontSize: 18, lineHeight: '26px', marginTop: 20, marginBottom: 40, width: 175, color: '#1A1A1A',
                                fontWeight: 400
                            }}>Add an event to celebrate</div>
                            <div style={{ width: 270, marginBottom: 40, fontSize: 16, color: '#666666', fontWeight: 325 }}>and begin collecting gifts from friends & family.</div>
                            <div>
                                <Button
                                    variant="contained"
                                    style={{
                                        height: 32,
                                        backgroundColor: "#0096FF",
                                        color: 'white'
                                    }}
                                    onClick={addNewEvent}
                                    startIcon={<AddIcon />}>
                                    New Event
                                </Button>
                            </div>
                        </div>
                    </div>
                }

                {(ctx.notificationData && ctx.notificationData.length) ?
                    <div className={classes.upComingEvents}>
                        <div className={classes.upComingEventsHeading}>Notifications {ctx.notificationData.length > 0 && `(${ctx.notificationData.length})`}</div>
                        <Box className={classes.eventList}>
                            <List>
                                {
                                    ctx.notificationData.slice(0, 3).map((notification, index) => (
                                        <Box key={index} style={{ paddingBottom: '20px', cursor: 'pointer' }}
                                            onClick={gotoEventPage}>
                                            <Grid container>
                                                <div style={{ width: '50px', display: 'flex' }}>
                                                    {getNotificationImage(notification) ?
                                                        <img src={getNotificationImage(notification)} style={{
                                                            height: '50px', width: '50px', float: "left",
                                                            borderRadius: "15px",
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                        }} />
                                                        :
                                                        <div className={classes.notificationImage} style={{
                                                            backgroundColor: `${getNotificationImageBgColor(index)}`
                                                        }}>
                                                            <div className={getNotificationImageIcon(notification, index)} style={{ zoom: '0.9' }}></div>
                                                        </div>
                                                    }
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: 'calc(100% - 75px)',
                                                    paddingLeft: '25px'
                                                }}>
                                                    {notification.title === "Kidfolio" && notification.data.Heading && <div style={{
                                                        fontFamily: "Montserrat",
                                                        fontWeight: "400",
                                                        fontSize: "15px",
                                                        textAlign: "left",
                                                        paddingBottom: '5px'
                                                    }}>
                                                        {notification.data.Heading}
                                                    </div>
                                                    }
                                                    <div style={{
                                                        fontSize: "14px",
                                                        fontWeight: "300",
                                                        lineHeight: "18px",
                                                        letterSpacing: "0px",
                                                        textAlign: "left",
                                                    }}>
                                                        {notification.body || notification.data.Body}
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Box>
                                    ))
                                }
                            </List>
                        </Box>
                        <Button onClick={gotoEventPage}> View All</Button>
                    </div>
                    : <div className={classes.noNotification}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={NoNotificationImage} style={{
                                height: '85px', width: '145px'
                            }} />
                            <div style={{
                                fontSize: 18, fontWeight: 400, color: '#1A1A1A', lineHeight: '22px', marginTop: 40, width: 210
                            }}>You are all caught up! You don’t have any notifications </div>
                            <div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                openPaymentModel ? <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openPaymentModel}
                    onClose={closePaymentModelHandler}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Box style={{ width: '40%', background: '#ffffff' }}>
                        <PaymentContent onSuccess={closePaymentModelHandler} />
                    </Box>
                </Modal> : <div />
            }
            {noChildModel && <NoChildDialog visible={noChildModel} addNewChild={addNewChild} handleClose={handleNoChildClose} />}
            {addNewChildModel ? <AddChildDialog visible={addNewChildModel} handleClose={handleClose} /> : <div />}
            {selectChildModel ? <SelectChildDialog visible={selectChildModel} handleClose={handleChildSelectionClose} childSelected={childSelected} /> : <div />}
            {addEventModel ? <AddEventDialog childName={selectedChild.profile.fullName} childId={selectedChild.id} visible={addEventModel} handleClose={handleNewEventClose} /> : <div />}
        </React.Fragment >
    )
}

export default HomeEventsComponent;