import { Typography } from "@material-ui/core";
import Banner from "../components/Banner/Banner";
import KidfolioContext from "../store/KidfolioContext";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import LaunchPageCircle from "../assets/LaunchPageCircle.svg";
import Paper from "@mui/material/Paper";
import FlexibilityIcon from "../assets/Flexibility.svg";
import FutureIcon from "../assets/FutureIcon.svg";
import FriendsIcon from "../assets/FriendFamilyIcon.svg";
import FreedomIcon from "../assets/FreedomIcon.svg";

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

function LaunchPage() {
    const kidfolioCtx = useContext(KidfolioContext);
    const navigate = useNavigate();
    const windowSize = useWindowSize();

    if (kidfolioCtx.user) {
        navigate({
            pathname: '/home'
        })
    }

    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Banner />
            <div style={{ backgroundColor: "#FEEAC9", height: "auto", width: `${windowSize.width}px` }}>
                <Typography variant='h4' style={{
                    margin: "auto", color: "#000000", paddingTop: "40px",
                    fontSize: "42px",
                    fontWeight: "500",
                    lineHeight: "50px",
                    letterSpacing: "0em",
                    textAlign: "center"
                }}>
                    Gifted Offers
                </Typography><br />
                <Grid container>
                    <Grid item xs={5} style={{ padding: 0, position: 'relative' }}>
                        <img src={LaunchPageCircle} style={{ zIndex: 0, position: 'absolute', bottom: 0 }} />
                    </Grid>
                    <Grid item xs={7} style={{ zIndex: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                        <Paper style={{ backgroundColor: "transparent", boxShadow: "none", width: 300, marginTop: 30 }}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <img width="100%" src={FlexibilityIcon} />
                                </Grid>
                                <Grid item xs={8} style={{ marginLeft: 10 }}>
                                    <Typography style={{ marginBottom: 10 }} variant='h5'>Flexibility</Typography>
                                    You can customize gifting ideas to fit your child’s needs and interests to ensure the gifts truly have an impact.
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper style={{ backgroundColor: "transparent", boxShadow: "none", width: 300, marginTop: 30 }}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <img width="100%" src={FutureIcon} />
                                </Grid>
                                <Grid item xs={8} style={{ marginLeft: 10 }}>
                                    <Typography style={{ marginBottom: 10 }} variant='h5'>Future</Typography>
                                    You can customize gifting ideas to fit your child’s needs and interests to ensure the gifts truly have an impact.
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper style={{ backgroundColor: "transparent", boxShadow: "none", width: 300, marginTop: 30 }}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <img width="100%" src={FriendsIcon} />
                                </Grid>
                                <Grid item xs={8} style={{ marginLeft: 10 }}>
                                    <Typography style={{ marginBottom: 10 }} variant='h5'>Friends & Family</Typography>
                                    You can customize gifting ideas to fit your child’s needs and interests to ensure the gifts truly have an impact.
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper style={{ backgroundColor: "transparent", boxShadow: "none", width: 300, marginTop: 30 }}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <img width="100%" src={FreedomIcon} />
                                </Grid>
                                <Grid item xs={8} style={{ marginLeft: 10 }}>
                                    <Typography style={{ marginBottom: 10 }} variant='h5'>Freedom</Typography>
                                    You can customize gifting ideas to fit your child’s needs and interests to ensure the gifts truly have an impact.
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default LaunchPage;