import { Button, makeStyles, Typography } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Grid from "@material-ui/core/Grid";
import { useContext, useState, useEffect } from "react";
import KidfolioContext from "../store/KidfolioContext";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ContributionDialog from "../components/ContributionDialog";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import { getBackgroundColor, getColorCode } from "../utils/Util";
import GiftedIcon from "../assets/GiftedIcon.svg";

const useStyles = makeStyles({
    root: {
        backgroundColor: 'transparent',
        width: '100%',
        display: 'flex',
        marginTop: 15
    },
    notificationImage: {
        width: "80px",
        height: "80px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        float: "left",
        marginRight: 20,
        borderRadius: '15px'
    }
})

function EventNotification() {
    const classes = useStyles();
    const ctx = useContext(KidfolioContext);
    const navigate = useNavigate();
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [selectedContribution, setSelectedContribution] = useState(null);
    const [selectedContributor, setSelectedContributor] = useState(null);
    const [showContribution, setShowContribution] = useState(false);
    const [blockPage, setBlockPage] = useState(false);

    useEffect(async () => {
        await ctx.getUsersAllContributions();
        await ctx.fetchNotifications();
    }, [])

    useEffect(() => {
        if (selectedNotification && ctx.eventContributorsData && ctx.eventContributorsData.length) {

            const contriData = ctx.eventContributorsData.find(item => {
                if (item.contributor._id === selectedNotification.data.contributorId) {
                    return item;
                }
            })
            if (contriData) {
                const contribution = contriData.contributions.filter(item => {
                    return item._id === selectedNotification.data.contributionId;
                })
                setSelectedContribution(contribution);
                setSelectedContributor(contriData.contributor);
                setBlockPage(false);
                setShowContribution(true);
            }
        }
    }, [ctx.eventContributorsData]);

    useEffect(() => {
        if (selectedNotification && ctx.childEventData && ctx.childEventData.length) {

            const event = ctx.childEventData.find(item => {
                return item.post._id === selectedNotification.data.EventId
            })

            if (event) {
                const myContributions = event.post.contributions.filter(item => {
                    return item.contributorId === ctx.loggedInUserDetails.id;
                })
                if (myContributions && myContributions.length) {
                    setSelectedContribution(myContributions);
                    setSelectedContributor(ctx.loggedInUserDetails);
                    setShowContribution(true);
                }
                setBlockPage(false);
            }
        }
    }, [ctx.childEventData]);

    const isAlreadyGifted = (noti) => {
        let giftedObj = null;
        if (ctx.usersAllContributions && ctx.usersAllContributions.length) {
            giftedObj = ctx.usersAllContributions.find(item => {
                return item?.post?._id === noti.data.EventId;
            })
        }
        return giftedObj;
    }

    const fetchContributionDtl = async (notification) => {
        setBlockPage(true);
        setSelectedNotification(notification);
        await ctx.fetchEventContributorsData(notification.data.beneficiaryId, notification.data.eventId);
    }

    const fetchMyContributionDtl = async (notification) => {
        setBlockPage(true);
        setSelectedNotification(notification);
        const giftedObj = isAlreadyGifted(notification);
        setSelectedContributor(ctx.loggedInUserDetails)
        await ctx.fetchChildEvents(giftedObj.beneficiary._id);
    }

    const isContributionNotification = (noti) => {
        return noti.data && noti.data.Category === 'Contribution';
    }

    const isInviteNotification = (noti) => {
        return noti.data && noti.data.Category === 'Invite';
    }

    const closeContriDialog = () => {
        setShowContribution(false);
    }
    const getNotificationImage = (noti) => {
        let image = null;
        if (isContributionNotification(noti)) {
            if (noti.data.notificationDetails) {
                const info = JSON.parse(noti.data.notificationDetails);
                const imageId = info.senderImageId;
                if (imageId) {
                    image = ctx.findBlobUrlForResourceId(imageId);
                }
            }
        }
        return image;
    }

    function getNotificationImageIcon(noti, index) {
        const colorCode = getColorCode(index);
        let name = noti.data.Heading || noti.body;
        if (isContributionNotification(noti)) {
            if (noti.data.notificationDetails) {
                const info = JSON.parse(noti.data.notificationDetails);
                const senderName = info.senderFullName;
                if (senderName) {
                    name = senderName;
                }
            }
        }
        return `sprite-${colorCode}-small ${colorCode}_${name.substring(0, 1).toUpperCase()}`;
    }

    const getNotificationImageBgColor = (index) => {
        const colorCode = getColorCode(index);
        return getBackgroundColor(colorCode);
    }

    const naviateToSendGift = async (notification) => {
        navigate({
            pathname: `/sendGift/event/${notification.data.EventId}`
        })
    }

    return (
        <div className={classes.root}>
            <Stack style={{ display: 'flex', boxSizing: 'border-box', width: '100%' }}>
                <Typography variant='h5' style={{
                    fontFamily: "Montserrat",
                    fontStyle: "bold",
                    fontSize: '21px',
                    fontWeight: "500",
                    lineHeight: "22px",
                    letterSpacing: "0px",
                    textAlign: "left",
                    color: "#000000",
                    paddingTop: "20px",
                    marginBottom: '15px',
                    float: 'left'
                }}>
                    Notification
                </Typography>
                {(ctx.notificationData) ? ctx.notificationData.map((notification, index) => (
                    <Paper key={index} style={{
                        margin: '16px 0px', minHeight: '120px', backgroundColor: '#daeeff',
                        borderRadius: '16pt', alignItems: 'center', justifyContent: 'center', display: 'flex',
                        boxSizing: 'border-box', padding: 16, boxShadow: 'none'
                    }}>
                        <Grid container>
                            <Grid item sm={2} style={{ maxWidth: '100px', display: 'flex', alignItems: 'center' }}>

                                {getNotificationImage(notification) ?
                                    <img src={getNotificationImage(notification)} style={{
                                        height: '80px', width: '80px', float: "left",
                                        borderRadius: "15px",
                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                    }} />
                                    :
                                    <div className={classes.notificationImage} style={{
                                        backgroundColor: `${getNotificationImageBgColor(index)}`
                                    }}>
                                        <div className={getNotificationImageIcon(notification, index)} style={{ zoom: '0.9' }}></div>
                                    </div>
                                }
                            </Grid>
                            <Grid item sm={7} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: '10px' }}>
                                <div style={{ fontWeight: 600 }}>
                                    {notification.title === "Kidfolio" ? notification.data.Heading : ""}
                                </div>
                                <div style={{ fontWeight: 400, paddingTop: 5 }}>
                                    {notification.body || notification.data.Body}
                                </div>
                                <div style={{ fontSize: 14, paddingTop: 5 }}>
                                    on {dayjs(notification.createdOn).format("DD MMM, YYYY")}
                                </div>
                            </Grid>

                            <Grid item sm={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: 'auto' }}>

                                {isContributionNotification(notification) &&
                                    <Button
                                        variant="contained"
                                        style={{
                                            height: 36,
                                            backgroundColor: "#0079D9",
                                            color: 'white'
                                        }}
                                        onClick={() => fetchContributionDtl(notification)}
                                        startIcon={<CardGiftcardOutlinedIcon />}>
                                        View gift
                                    </Button>
                                }

                                {isInviteNotification(notification) && isAlreadyGifted(notification)
                                    &&
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: 20, cursor: 'pointer' }}
                                            onClick={() => fetchMyContributionDtl(notification)}>
                                            <img src={GiftedIcon} />
                                            Gifted
                                        </div>
                                        <Button variant="outlined" style={{ height: 36 }} onClick={() => naviateToSendGift(notification)}
                                            startIcon={<CardGiftcardOutlinedIcon />}>
                                            Gift Again
                                        </Button>
                                    </div>
                                }
                                {isInviteNotification(notification) && !isAlreadyGifted(notification)
                                    && <Button variant="contained"
                                        style={{ backgroundColor: '#0079D9', color: '#ffffff', height: 36 }}
                                        onClick={() => naviateToSendGift(notification)}
                                        startIcon={<CardGiftcardOutlinedIcon />}>
                                        Send gift
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                )) : <div></div>}
            </Stack>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={blockPage}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {showContribution ? <ContributionDialog contributor={selectedContributor} contributions={selectedContribution} visible={showContribution} handleClose={closeContriDialog} /> : <div />}
        </div>
    )
};

export default EventNotification;
