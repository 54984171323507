import { Avatar, Button, Card, Dialog, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import AddIcon from '@mui/icons-material/Add';
import React, { useContext, useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import KidfolioContext from "../store/KidfolioContext";
import dayjs from "dayjs";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import Paper from "@mui/material/Paper";
import { useNotify } from "../utils/Notify";
import { getColorCode, getBackgroundColor, getChildProfile, getSolanaVendorName, childProfilePhoto } from "../utils/Util";
import AddProfileImage from "../assets/AddProfileImage.svg";
import Box from '@mui/material/Box';


const useStyles = makeStyles({
    card: {
        width: '600px',
        height: '200px',
        margin: '0px 20px 0px 20px',
        borderRadius: '20px',
        boxShadow: "0px 4.478689670562744px 15.675413131713867px 6.718034744262695px #FFFFFF33 inset, -22.393447875976562px 105.24920654296875px 161.23281860351562px 0px #807D7D29",
    },
    cardLayout: {
        paddingBottom: 15,
        /* position: "relative",
         left: "0",
         right: "0",
         height: "70%"*/
    },
    mainHeader: {
        alignItems: "center",
        height: '200px'
    },
    root: {
        //  flexGrow: 1,
        width: '495px',
        height: '600px',
        // marginBottom:  250,
        background: 'transparent',
        backgroundColor: 'transparent',
        position: 'relative',
        margin: 'auto',
        // display: 'flex'

    },
    contributorDtl: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        alignItems: 'center'
    },
    topSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    inputClass: {
        '& label': {
            lineHeight: 0.7,
        },
        '& input': {
            fontSize: '12px'
        },
    },
});

function AddGift(props) {
    const classes = useStyles();
    const [hideGiftOptions, setHideGiftOptions] = useState(false);
    const [hideAmountModel, setHideAmountModel] = useState(true);
    const [hideCustomGift, setHideCustomGift] = useState(true);
    const [hideSummary, setHideSummary] = useState(true);
    const [hideSingleWalletDiv, setHideSingleWalletDiv] = useState(false);
    const [giftName, setGiftName] = useState();
    const [customGiftList, setCustomGiftList] = useState([]);
    const [giftAmount, setGiftAmount] = useState();
    const [giftsList, setGiftsList] = useState([]);
    const [giftsAmountList, setGiftsAmountList] = useState([]);
    const { addCustomGift } = useContext(KidfolioContext);
    const { addEvent, fetchGifts, fetchChildEvents, fetchUpcomingUserEvents } = useContext(KidfolioContext);
    const notify = useNotify();
    const textInput = useRef(null);
    const customNameInput = useRef(null);
    const customAmtInput = useRef(null);

    const ctx = useContext(KidfolioContext);
    const userDetails = ctx.loggedInUserDetails;
    const solanaVendorName = getSolanaVendorName(ctx.paymentOption);
    const childList = ctx.childData;
    const [wallets, setWallets] = useState(getWallets());
    const [selectedValue, setSelectedValue] = useState(wallets[0]);

    function getSingleWallet() {

        let beneficiaryWalletAddress = wallets[0].walletAddress;

        let userWalletAddress = wallets[1].walletAddress;

        const constructBeneficiaryDetail = {
            name: props.benName,
            walletAddress: beneficiaryWalletAddress,
            imagePreviewUrl: getChildProfile(ctx.beneficiaryImageUrl, props.beneficiaryId)
        };
        const constructUserDetail = {
            name: userDetails.profile.fullName,
            walletAddress: userWalletAddress,
            imagePreviewUrl: ctx.userProfileImageUrl
        };

        beneficiaryWalletAddress !== "" && userWalletAddress !== "" ? setHideSingleWalletDiv(true) : setHideSingleWalletDiv(false);

        return beneficiaryWalletAddress !== "" ? constructBeneficiaryDetail : constructUserDetail;

    }
    const [singleWallet, setSingleWallet] = useState(getSingleWallet);


    useEffect(async () => {
        setSelectedValue(wallets[0]);
    }, []);

    const handleCloseInternalModal = () => {
        setHideAmountModel(true);
        setHideCustomGift(true);
        setHideGiftOptions(false);
        setGiftAmount("");
    }

    const setSelectedGift = (giftName) => {

        setHideGiftOptions(true);
        setHideCustomGift(true);
        setHideAmountModel(false);
        setGiftName(giftName);

        const foundIndex = giftsList.findIndex(gift => giftName === gift);
        if (foundIndex > -1) {
            setGiftAmount(giftsAmountList[foundIndex]);
        }
    }

    function createCustomGift() {
        setHideGiftOptions(true);
        setHideAmountModel(true);
        setHideCustomGift(false);
    }


    // onClick={(e) => {addGiftHandler(e.target)}}

    function addCustomGiftAmount() {
        customNameInput.current.value = "";
        customAmtInput.current.value = "";
        addCustomGift(giftName);
        const replacementCustomGiftsList = [...customGiftList, giftName];
        setCustomGiftList(replacementCustomGiftsList);
        addGiftAmount();
        fetchGifts();
        //handleCloseInternalModal();
        //setHideCustomGift(true);
    }


    function addGiftAmount() {
        //textInput.current.value = "";
        const selectedGift = giftsList?.find((giftItem) => {
            return giftItem === giftName;
        });
        let replacementGiftsList, replacementAmountList = [];
        if (selectedGift) {
            const foundIndex = giftsList.findIndex(gift => giftName === gift);
            replacementAmountList = [...giftsAmountList];
            replacementAmountList.splice(foundIndex, 1, giftAmount);

            setGiftsAmountList(replacementAmountList);

        } else {
            replacementGiftsList = [...giftsList, giftName];
            setGiftsList(replacementGiftsList);
            replacementAmountList = [...giftsAmountList, giftAmount];
            setGiftsAmountList(replacementAmountList);

        }

        setGiftAmount("");
        handleCloseInternalModal();
    }

    function showSummary() {
        setSelectedValue(wallets[0]);
        setHideGiftOptions(true);
        setHideAmountModel(true);
        setHideCustomGift(true);
        setHideSummary(false);
    }

    function randomIndex(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    async function createNewEvent() {
        const allEventTypes = ctx.categoriesData;
        let defaultImg = "";
        allEventTypes.forEach(item => {
            item.items.forEach(subItm => {
                if (subItm.title === props.eventName && subItm.imageUrls && subItm.imageUrls.length) {
                    defaultImg = subItm.imageUrls[randomIndex(0, subItm.imageUrls.length)];
                }
            })
        })

        const ownerId = ctx.childData.id
        let beneficiaryWalletAddress = wallets[0].walletAddress;

        let userWalletAddress = wallets[1].walletAddress;
        singleWallet.walletAddress

        // check if two address , then choose selectedValue
        // else choose valid  address


        const paymentWalletAddress = userWalletAddress !== "" && beneficiaryWalletAddress !== "" ? selectedValue.walletAddress : (beneficiaryWalletAddress !== "" ? beneficiaryWalletAddress : userWalletAddress);
        const createEventDataObj = {
            "type": "event",
            "title": props.eventName,
            "dateTime": props.eventDate,
            "ownerId": ownerId,
            "beneficiaryId": props.beneficiaryId,
            "giftItems": giftsList,
            "targetAmounts": giftsAmountList,
            "defaultImage": defaultImg,
            "paymentOptions": [
                {
                    "vendorName": "Solana Pay",
                    "identifier": paymentWalletAddress
                }
            ]
        }

        props.handleClose();
        await addEvent(createEventDataObj);
        fetchChildEvents(props.beneficiaryId);
        fetchUpcomingUserEvents();
        notify("success", `New ${props.eventName} event created for ${props.benName}`, "")

    }

    let counter = 0;

    function deleteSelectedGift(giftName) {

        const foundIndex = giftsList.findIndex(gift => giftName === gift);

        let replacementGiftsList = [...giftsList];
        replacementGiftsList.splice(foundIndex, 1);
        setGiftsList(replacementGiftsList);

        let replacementAmountList = [...giftsAmountList];
        replacementAmountList.splice(foundIndex, 1);
        setGiftsAmountList(replacementAmountList);

    }

    function printGiftCount(length) {
        return (length > 0 ? length : "");
    }

    function handleChooseGifts() {
        setHideAmountModel(true);
        setHideCustomGift(true);
        setHideSummary(true);
        setHideGiftOptions(false);
    }

    function setBgColor(gift) {
        const result = giftsList.find(giftItem => giftItem === gift)
        return result ? (result.length > 0 ? "#0772dc" : "#f3f5f5") : "#f3f5f5";
    }

    function setColor(gift) {
        const result = giftsList.find(giftItem => giftItem === gift)
        return result ? (result.length > 0 ? "#FFFFFF" : "#000000") : "#000000";
    }

    function getGiftImage(gift, index) {
        const colorCode = getColorCode(index);
        return `sprite-${colorCode}-small ${colorCode}_${gift.substring(0, 1).toUpperCase()}`;
    }

    const getEventBgColor = (index) => {
        const colorCode = getColorCode(index);
        return getBackgroundColor(colorCode);
    }

    const getChildPhoto = (benId) => {
        return getChildProfile(ctx.beneficiaryImageUrl, benId);
    }



    function getWallets() {
        const benDatas = ctx.beneficiariesData.data.beneficiaries
        const childDetail = benDatas.find((beneficiary) => (
            beneficiary.id === props.beneficiaryId
        ))
        const solanaWallet = childDetail.paymentOptions?.find((paymentOption) => (
            paymentOption.vendorName === solanaVendorName
        ));
        const beneficiaryWalletAddress = solanaWallet ? solanaWallet.identifier : "";


        let userWalletAddress = "";
        const userPaymentOptions = JSON.parse(JSON.stringify(userDetails.paymentOptions || []));
        if (userPaymentOptions && userPaymentOptions.length) {

            const solanaPaymentOption = userPaymentOptions.find(item => {
                return (item.vendorName === solanaVendorName);
            })

            userWalletAddress = solanaPaymentOption ? solanaPaymentOption.identifier : "";
            /*

                        let constructWallet;
                        if(beneficiaryWalletAddress!== "" && userWalletAddress !== ""){
                            setHideSingleWalletDiv(true);
                        } else{
                            setHideSingleWalletDiv(false);
                            if(beneficiaryWalletAddress !== ""){
                                constructWallet ={}
                            }
                        }*/
        } else {
            userWalletAddress = "";
        }

        const constructWallets = [
            {
                name: props.benName,
                walletAddress: beneficiaryWalletAddress ? beneficiaryWalletAddress : "",
                imagePreviewUrl: getChildProfile(ctx.beneficiaryImageUrl, props.beneficiaryId)
            },
            {
                name: userDetails.profile.fullName,
                walletAddress: userWalletAddress ? userWalletAddress : "",
                imagePreviewUrl: ctx.userProfileImageUrl
            }
        ];
        return constructWallets;
    }

    const childDropDownValue = (walletName, walletAddress) => {
        return walletName.charAt(0).toUpperCase() + walletName.slice(1).toLowerCase() + ' - ' + walletAddress.substring(0, 2) + '***********' + walletAddress.substring(walletAddress.length - 2, walletAddress.length);
    }

    const handleInputChange = async (e) => {
        let { name, value } = e.target;
        let address = '';
        setSelectedValue(value)

    };

    function getGiftIndex(item) {
        const foundIndex = giftsList.findIndex(gift => item === gift);
        return foundIndex;
    }

    function findValueifPresent(giftName) {
        return "5";
    }

    function handleGiftAmount(value) {
        if (value) {
            setGiftAmount(parseFloat(value));
        }
    }

    return (
        <div style={{
            overflow: 'auto',
            textAlign: 'justify'
        }}>

            <div >
                <Grid item xs={12} style={{
                    textAlign: "center",
                    marginTop: 5
                }}>
                    <img src={props.benPhoto} alt="putin" style={{
                        height: '70px',
                        width: '70px',
                        borderRadius: "50px"
                    }} />
                </Grid>
                <br /><br />
                <Grid item xs={12} >
                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            textAlign: "center",
                            fontWeight: 600
                        }}>
                        {props.benName}
                    </Typography>
                </Grid>

                <Grid item xs={12} >
                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            textAlign: "center",
                            fontWeight: 80,
                            fontSize: 13
                        }}>
                        {props.eventName} | {dayjs(props.eventDate).format("MMM DD, YYYY")}
                    </Typography>
                </Grid>
                <br /><br />

            </div>
            <div hidden={!hideSummary}>
                <Grid item xs={12} >
                    <Typography style={{
                        fontSize: "18px",
                        marginLeft: 80,
                    }}>
                        Do you want to add some gift list to the event?
                    </Typography>
                </Grid>

                <Grid spacing={2} item xs={12} style={{
                    textAlign: "center"
                }}>
                    <h4 style={{
                        height: 20
                    }}>Custom Gifts</h4>
                    <div>
                        {
                            customGiftList ? customGiftList.map((gift) => (
                                <Button
                                    value={gift}
                                    onClick={e => setSelectedGift(gift)}
                                    variant="text"
                                    style={{
                                        height: 30,
                                        marginRight: 7,
                                        marginTop: 7,
                                        backgroundColor: setBgColor(gift)
                                    }}>
                                    {gift}
                                </Button>
                            )) : <div></div>
                        }
                    </div>
                    <Button
                        style={{
                            color: "#0c7bea"
                        }}
                        onClick={createCustomGift}
                        variant="text">
                        <AddIcon /> Custom Gift
                    </Button>
                    <br /><br />
                </Grid>
                <Grid spacing={2} item xs={12} style={{
                    textAlign: "center"
                }}>
                    {
                        (ctx.giftsData) ?
                            (ctx.giftsData.map((gift) => (
                                <div>
                                    <h3 style={{
                                        height: 20,
                                        color: "#020202",
                                        textAlign: "center"
                                    }}>
                                        {gift.category}
                                        <br />
                                    </h3>
                                    <div spacing={2} item xs={12} >

                                        {
                                            (gift.items) ? gift.items.map((item) => (

                                                <Button
                                                    value={item}
                                                    onClick={e => setSelectedGift(item)}
                                                    variant="text"
                                                    style={{
                                                        height: 36,
                                                        marginRight: 10,
                                                        marginTop: 10,
                                                        backgroundColor: setBgColor(item),
                                                        color: setColor(item)
                                                    }}>
                                                    {item}
                                                </Button>)) : <div>No Data</div>
                                        }
                                    </div>
                                    <br />
                                </div>
                            ))) : <div>Nope</div>
                    }
                </Grid>


                <Grid item xs={12}>
                    <div>
                        <br /><br />
                        <Button type={"reset"}
                            onClick={e => props.handleBack()}
                            style={{
                                float: 'left',
                                width: 50,
                                marginTop: 60
                            }}>
                            Back
                        </Button>
                        <div style={{ textAlign: "right" }}>
                            <Button
                                onClick={e => showSummary()}
                                style={{
                                    backgroundColor: "#0078D9",
                                    marginTop: 60,
                                    color: 'white'
                                }}>Skip this step</Button>
                            <Button
                                disabled={giftsList.length < 0 || giftsList.length === 0}
                                onClick={showSummary}
                                style={{
                                    backgroundColor: "#0078D9",
                                    marginTop: 60,
                                    marginLeft: 10,
                                    color: 'white'
                                }}>
                                {giftsList.length > 0 && <span hidden={!printGiftCount(giftsList.length)}
                                    style={{
                                        borderRadius: "10px", width: 20, height: 20, backgroundColor: "#3BFFD9", color: 'black', display: 'flex',
                                        alignItems: 'center', justifyContent: 'center'
                                    }}>
                                    {printGiftCount(giftsList.length)}
                                </span>
                                }
                                <span style={{ marginLeft: 3 }}>Next</span>
                            </Button>
                        </div>

                    </div>
                </Grid>
            </div>

            <div hidden={hideCustomGift}>

                <Dialog
                    disableEnforceFocus
                    className={classes.root}

                    open={!hideCustomGift}
                    onClose={handleCloseInternalModal}>
                    <DialogContent className={classes.dialogPadding}>
                        <Grid container>
                            <Grid item xs={12} className={classes.topSection}>
                                <div style={{
                                    alignItems: "center",
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <div style={{
                                        float: "center",
                                        fontSize: 20,
                                        fontWeight: 400,
                                        marginBottom: 10
                                    }}>Add your own Gift</div>
                                    <div className="row"
                                        style={{
                                            fontSize: "13px",
                                            color: "#666666",
                                        }}>Describe the gift you are creating</div>
                                </div>

                                <IconButton
                                    edge="start"
                                    align="right"
                                    color="inherit"
                                    aria-label="Close"
                                    style={{ padding: 8, width: 30 }}
                                >
                                    <CloseIcon onClick={handleCloseInternalModal} />
                                </IconButton>
                            </Grid>

                            <Grid item xs={12} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                marginTop: 20
                            }} >
                                <TextField
                                    className={classes.inputClass}
                                    style={{
                                        width: 300,
                                    }}
                                    inputRef={customNameInput}
                                    variant="outlined"
                                    margin="dense"
                                    label={<span style={{ fontSize: 12 }}>
                                        Name of the gift
                                    </span>}
                                    id="giftName"
                                    onChange={(event) => {
                                        setGiftName(event.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                marginTop: 5
                            }} >
                                <TextField
                                    className={classes.inputClass}
                                    style={{
                                        width: 300,
                                    }}
                                    variant="outlined"
                                    inputRef={customAmtInput}
                                    margin="dense"
                                    label={<span style={{ fontSize: 12 }}>
                                        Set target amount
                                    </span>}
                                    id="giftName"
                                    onChange={(event) => {
                                        setGiftAmount(parseInt(event.target.value));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '30px 0px 20px 0px',
                                justifyContent: 'center',
                            }}>
                                <Button
                                    onClick={e => addCustomGiftAmount()}
                                    variant="contained"
                                    style={{
                                        height: 36,
                                        width: 100,
                                        float: "center",
                                        backgroundColor: "#0079D9",
                                        color: '#FFFFFF'
                                    }}>
                                    Add
                                </Button>
                            </Grid>


                        </Grid>
                    </DialogContent>
                </Dialog>


            </div>

            <div hidden={hideAmountModel}>

                <Dialog
                    disableEnforceFocus
                    className={classes.root}

                    open={!hideAmountModel}
                    onClose={handleCloseInternalModal}>
                    <DialogContent className={classes.dialogPadding}>
                        <Grid container>
                            <Grid item xs={12} className={classes.topSection}>
                                <div style={{ alignItems: "center" }}>
                                    <span style={{ float: "center", fontSize: 18, fontWeight: 400, marginRight: "20px" }}>Set target amount for {giftName}</span><br />
                                </div>

                                <IconButton
                                    edge="start"
                                    align="right"
                                    color="inherit"
                                    aria-label="Close"
                                    style={{ padding: 8 }}
                                >
                                    <CloseIcon onClick={handleCloseInternalModal} />
                                </IconButton>
                            </Grid>

                            <Grid item xs={12} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                marginTop: 5
                            }} >
                                <TextField
                                    className={classes.inputClass}
                                    style={{
                                        width: 300,
                                        marginTop: 20,
                                    }}
                                    value={giftAmount}
                                    variant="outlined"
                                    margin="dense"
                                    inputRef={textInput}
                                    label={<span style={{ fontSize: 12 }}>
                                        Enter gift amount
                                    </span>}
                                    id="giftName"
                                    onChange={(event) => {
                                        handleGiftAmount(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '30px 0px 20px 0px',
                                justifyContent: 'center',
                            }}>
                                <Button
                                    onClick={e => addGiftAmount()}
                                    variant="contained"
                                    style={{
                                        height: 36,
                                        width: 100,
                                        float: "center",
                                        backgroundColor: "#0079D9",
                                        color: '#FFFFFF'
                                    }}>
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>


            </div>

            <div hidden={hideSummary}>
                <h3> Gifts List</h3>
                <Paper style={{
                    width: '550px',
                    overflow: 'auto',
                    border: 'none', background: 'transparent', boxShadow: 'none',
                    padding: '20px 0px'
                }}>
                    {
                        giftsList.length > 0 ? giftsList.map((item, index) => (
                            <Box
                                style={{
                                    borderRadius: '4px',
                                    boxShadow: '0px 0px 10px rgb(0 0 0 / 12%)',
                                    padding: '10px',
                                    width: '95%',
                                    height: '100px',
                                    background: 'white',
                                    marginBottom: '15px',
                                }}>
                                <div className="row" style={{ height: '100%' }}>
                                    <div style={{
                                        width: "115px",
                                        height: "88px",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        float: "left",
                                        marginTop: 5,
                                        marginRight: 20,
                                        marginBottom: 5,
                                        backgroundColor: `${getEventBgColor(index)}`
                                    }}>
                                        <div className={getGiftImage(item, index)}></div>
                                    </div>
                                    <div style={{
                                        height: "100%",
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{
                                            fontFamily: "Montserrat",
                                            float: "left",
                                            color: "#000202"
                                        }}>
                                            <div style={{ alignItems: "left", textAlign: "left", fontSize: "14px", fontWeight: 400, marginBottom: 10 }}>{item}</div>
                                            <div style={{ alignItems: "left", color: "#666666", fontSize: "12px", fontWeight: 400 }}>Goal: ${giftsAmountList[getGiftIndex(item)]}</div>

                                        </div>
                                        <div style={{ textAlign: "right" }}>
                                            <IconButton
                                                edge="start"
                                                align="right"
                                                color="#999999"
                                                aria-label="Close"
                                                onClick={e => deleteSelectedGift(item)}>
                                                <DeleteOutlineOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            </Box>

                        )) : <Card variant="outlined"
                            style={{
                                width: '500px',
                                height: '100px',
                                textAlign: 'center',
                                boxShadow: "5px 4.478689670562744px 15.675413131713867px 6.718034744262695px #FFFFFF33 inset, -22.393447875976562px 105.24920654296875px 161.23281860351562px 0px #FFFFFF33"
                            }}><div style={{ marginTop: '25px' }}>There are no gifts selected.</div></Card>


                    }
                </Paper>

                <h3> Wallet</h3>

                <div hidden={hideSingleWalletDiv} style={{ height: '100px' }} fullWidth>
                    <Card
                        variant="outlined"
                        style={{
                            width: '550px',
                            border: 'none'
                        }}>
                        <div className="row">
                            <div style={{
                                width: "80px",
                                height: "80px",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                float: "left",
                                marginTop: 5,
                                marginBottom: 5,
                                marginLeft: 5,
                                marginRight: 20
                            }}>
                                <Avatar
                                    style={{
                                        height: '70px',
                                        width: '70px',
                                        float: "left",
                                        borderRadius: "50px",
                                        backgroundColor: "#EEBC1D"
                                    }}
                                    src={singleWallet.imagePreviewUrl}
                                />
                            </div>
                            <div style={{
                                height: "90px",
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <Typography className={classes.primaryColor} variant="subtitle1" style={{
                                    fontFamily: "Montserrat",
                                    float: "left",
                                    color: "#000202"
                                }}>
                                    {childDropDownValue(singleWallet.name, singleWallet.walletAddress)}
                                </Typography>
                            </div>
                        </div>
                    </Card>
                </div>

                <div hidden={!hideSingleWalletDiv}>
                    <Select
                        style={{ height: '100px', width: '520px' }}
                        labelId="publicAddress"
                        disableUnderline
                        id="publicAddress"
                        value={selectedValue}
                        onChange={handleInputChange}
                        label="Child"
                        name="public_address"
                    // IconComponent={() => <EditIcon style={{ color: '#999999' }} />}
                    >
                        {
                            (wallets) ? (
                                wallets.map((wallet) => (
                                    <MenuItem style={{
                                        width: '520px',
                                        height: '100px',
                                        backgroundColor: selectedValue.name === wallet.name ? "#0079D9" : "#ffffff"
                                    }}
                                        value={wallet}>
                                        <Card

                                            variant="outlined"
                                            style={{
                                                width: '520px',
                                                border: 'none'
                                            }}>
                                            <div className="row" style={{ display: 'flex' }}>
                                                <div
                                                    style={{
                                                        height: "100px",
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        float: "left",
                                                        marginTop: 5,
                                                        marginBottom: 5,
                                                        marginLeft: 5,
                                                        marginRight: 20
                                                    }}>
                                                    <Avatar
                                                        style={{
                                                            height: '60px',
                                                            width: '60px',
                                                            float: "left",
                                                            borderRadius: "50px",
                                                            backgroundColor: "#EEBC1D"
                                                        }}
                                                        src={wallet.imagePreviewUrl}
                                                    />
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <Typography className={classes.primaryColor} variant="subtitle1" style={{
                                                        fontFamily: "Montserrat",
                                                        float: "left",
                                                        color: "#000202"
                                                    }}>
                                                        {childDropDownValue(wallet.name, wallet.walletAddress)}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Card>
                                    </MenuItem>
                                ))) : <div />
                        }
                    </Select>
                </div>
                <Button type={"reset"}
                    onClick={e => handleChooseGifts()}
                    style={{
                        float: 'left',
                        width: 50,
                        marginTop: 35,
                    }}>
                    Back
                </Button>
                <Button
                    onClick={e => createNewEvent()}
                    variant="contained"
                    style={{
                        height: 40,
                        width: 100,
                        textAlign: "right",
                        float: 'right',
                        marginTop: 35,
                        marginLeft: 130,
                        backgroundColor: "#0079D9",
                        color: 'white'
                    }}>
                    Finish
                </Button>

            </div>
        </div>
    )
}
export default AddGift;
