import { makeStyles, Typography } from "@material-ui/core";
import { PieChart } from "react-minimal-pie-chart";
import Paper from "@mui/material/Paper";
import React, { useContext, useEffect, useState } from "react";
import KidfolioContext from "../../store/KidfolioContext";
import { Connection, PublicKey } from "@solana/web3.js";
import bigInt from "big-integer";
import WalletBalanceList from "./WalletBalanceList";
import CircularProgress from "@mui/material/CircularProgress";
import { getAccount, getAssociatedTokenAddress, TokenAccountNotFoundError } from "@solana/spl-token";
import { web3 } from "@project-serum/anchor";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import EventsDashboardImg from "../../assets/EventsDashboardImg.svg";

const useStyles = makeStyles({
    noEvents: {
        background: "#D0FFF1",
        boxShadow: "-10px 48.5967px 140px rgba(126, 123, 160, 0.2)",
        backdropFilter: "blur(19px)",
        borderRadius: "34px",
        height: '420px',
        padding: 30,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 250,
        margin: 20,
        marginRight: 0,
        color: 'black',
        ['@media (min-width:780px)']: {
            marginRight: 20,
        }
    },
    upComingEventsHeading: {
        display: 'flex',
        fontWeight: 700,
        fontSize: 18,
        color: 'black'
    },
});

function WalletBalance() {

    const charColors = ['#EB6572', '#FCA623', '#0278D7', '#3BFFD9'];

    const classes = useStyles();

    function getRandomColor(index) {
        if (index < charColors.length) {
            return charColors[index];
        }
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const kidfolioCtx = useContext(KidfolioContext);
    const childList = kidfolioCtx.childData;
    const loggedInUserDetails = kidfolioCtx.loggedInUserDetails;
    const [random, setRandom] = useState(0)
    const [walletBalances, setWalletBalances] = useState([{
        fullName: 'Sarath',
        walletAddress: '6s1MuhvF8udKHSvxCoUrkA52fFTeXhKvknnr7ooCG8qK',
        amount: 0
    }])
    const [chartData, setChartData] = useState([]);
    const [childWalletBalanceList, setChildWalletBalanceList] = useState(null);
    const [selected, setSelected] = useState(0);
    const [hovered, setHovered] = useState(undefined);
    const [totalBalance, setTotalBalance] = useState(0);
    let childWalletList = [];
    const tokenMintAddress = process.env.REACT_APP_USDC_MINT_ADDRESS;
    const mintPublicKey = new web3.PublicKey(tokenMintAddress);
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    let total = 0;

    // const data = props.data.map((entry, i) => {
    //     if (hovered === i) {
    //         return {
    //             ...entry,
    //             color: 'grey',
    //         };
    //     }
    //     return entry;
    // });

    const lineWidth = 60;
    const data = [
        {
            "title": "Sarath",
            "value": 73.9749996,
            "color": "#676767"
        },
        {
            "title": "shakti",
            "value": 26.0054908,
            "color": "#e8e8e8"
        }
    ];

    // let walletBalanceList = <WalletBalanceList childData={walletBalances}/>;
    // const rand = 1 + Math.random() * (100000 - 1);

    useEffect(async () => {
        await updateWalletBalance();
    }, [kidfolioCtx.loggedInUserDetails, kidfolioCtx.childData])

    const updateWalletBalance = async () => {
        if (kidfolioCtx.loggedInUserDetails) {
            await setUserData();
        }
        if (childList && childList.beneficiaries) {
            await setChildData();
        }
        // const conn = new Connection("https://api.devnet.solana.com");
        // let chartDataVal = [];
        // let total = 0;
        // for (const wallet of walletBalances) {
        //     if(wallet.walletAddress !== ''){
        //     wallet.amount = await conn.getBalance(new PublicKey(wallet.walletAddress)).then(res => {
        //         total = total + (bigInt(res))
        //         return (res + (bigInt(1)))
        //     })
        // }}
        // chartDataVal = walletBalances.map((wallet, index) => (
        //     ({title: wallet.fullName, value: ((wallet.amount / total) * 100), color: getRandomColor()})
        // ));
        // chartDataVal.forEach((data, index) => {
        //     if(chartData.length === 0 ) setChartData([data])
        //     if (!chartData.some(el => el.title === data.title)){
        //         if(chartData.length !== 0 && data.value !== chartData[index].value)
        //         setChartData([...chartData, data])
        //     }
        // })
    }

    const setUserData = async () => {
        if (loggedInUserDetails && loggedInUserDetails.paymentOptions) {
            let userBalance = 0;
            const userWallet = loggedInUserDetails.paymentOptions.filter(e => e.vendorName === 'Solana Pay')[0]?.identifier ?
                loggedInUserDetails.paymentOptions.filter(e => e.vendorName === 'Solana Pay')[0]?.identifier : 'NA';
            try {
                const userBalance = await getTokenAccountBalance(mintPublicKey, new PublicKey(userWallet));
                // const tokenAccount = await splToken.getOrCreateAssociatedTokenAccount(
                //     connection,
                //     userWallet.payer,
                //     mintPublicKey,
                //     new PublicKey(userWallet),
                //     true
                // );
                if (userBalance || userBalance === 0) {
                    // const myWalletMyTokenBalance = await connection.getTokenAccountBalance(
                    //     tokenAccount.address
                    // );
                    // userBalance = myWalletMyTokenBalance?.value?.uiAmount;
                    total = total + userBalance;
                    childWalletList.push({
                        id: loggedInUserDetails.id,
                        title: loggedInUserDetails.profile.fullName,
                        imageId: loggedInUserDetails.profile.imageId,
                        walletAddress: userWallet,
                        value: userBalance,
                        portion: 100
                    })
                }
                // userBalance = await conn.getBalance(new PublicKey(userWallet)).then(res => {
                //     total = total + (bigInt(res))
                //     return (res + (bigInt(1)))
                // })
            } catch (err) {
                childWalletList.push({
                    id: loggedInUserDetails.id,
                    title: loggedInUserDetails.profile.fullName,
                    imageId: loggedInUserDetails.profile.imageId,
                    walletAddress: userWallet,
                    value: userBalance,
                    portion: 100
                })
            }
        } else {
            childWalletList.push({
                id: loggedInUserDetails.id,
                title: loggedInUserDetails.profile.fullName,
                imageId: loggedInUserDetails.profile.imageId,
                walletAddress: 'NA',
                value: 0,
                portion: 100
            })
        }
        setChildWalletBalanceList(childWalletList);
        const pieChartData = childWalletList.map((child, index) => (
            ({ title: child.title, value: child.portion, color: getRandomColor(index), balance: child.value })
        ));
        setChartData(pieChartData);
    }

    const setChildData = async () => {
        // const conn = new Connection("https://api.devnet.solana.com");
        for (const child of childList.beneficiaries) {
            const index = childList.beneficiaries.indexOf(child);
            if (child.paymentOptions) {
                const wallet = child.paymentOptions.filter(e => e.vendorName === 'Solana Pay')[0].identifier;
                if (wallet !== '') {
                    let balance = 0;
                    try {
                        //TODO: Use below function to validate and get token account balance during clean-up
                        //
                        //console.log(validateSolanaAddress(wallet));
                        const tokenBal = await getTokenAccountBalance(mintPublicKey, new PublicKey(wallet));
                        // console.log("tokenBal", tokenBal);
                        // const tokenAccount = await splToken.getOrCreateAssociatedTokenAccount(
                        //     connection,
                        //     wallet.payer,
                        //     mintPublicKey,
                        //     new PublicKey(wallet),
                        //     true
                        // );
                        if (tokenBal || tokenBal === 0) {
                            // const myWalletMyTokenBalance = await connection.getTokenAccountBalance(
                            //     tokenAccount.address
                            // );
                            // balance = myWalletMyTokenBalance?.value?.uiAmount;
                            total = total + tokenBal;
                            childWalletList.push({
                                id: child.id,
                                title: child.profile.fullName,
                                walletAddress: wallet,
                                value: tokenBal,
                                portion: 0
                            })
                        }
                        // balance = await conn.getBalance(new PublicKey(wallet)).then(res => {
                        //     total = total + (bigInt(res))
                        //     return (res + (bigInt(1)))
                        // })
                    } catch (err) {
                        console.log("Error", err);
                        childWalletList.push({
                            id: child.id,
                            title: child.profile.fullName,
                            walletAddress: wallet,
                            value: balance,
                            portion: 0
                        })
                    }
                }
            } else {
                childWalletList.push({
                    id: child.id,
                    title: child.profile.fullName,
                    walletAddress: 'NA',
                    value: 0,
                    portion: 0
                })
            }
            if (index === (childList.beneficiaries.length - 1)) {
                childWalletList.forEach((child, index) => {
                    child.portion = ((child.value / total) * 100) ? ((child.value / total) * 100) : 0;
                })
                setChildWalletBalanceList(childWalletList);
                const pieChartData = childWalletList.map((child, index) => (
                    ({ title: child.title, value: child.portion, color: getRandomColor(index), balance: child.value })
                ));
                setChartData(pieChartData);
            }
        }
    }

    //TODO: Validaconst validateSolanaAddress = (address) => {
    //         try {
    //             let pubkey = new PublicKey(address)
    //             return PublicKey.isOnCurve(pubkey.toBuffer())
    //         } catch (error) {
    //             return false
    //         }
    //     }te solana address each time while adding/editing address.


    //TODO: Please use this to fetch token account balance. Changes to be made during clean-up.
    const getTokenAccountBalance = async (tokenAddress, key) => {
        try {
            const userCouponAddress = await getAssociatedTokenAddress(tokenAddress, key);
            const userCouponAccount = await getAccount(connection, userCouponAddress);
            const { amount } = userCouponAccount;
            console.log("Amount", amount);
            return (bigInt(amount) / bigInt(1000000));
        } catch (e) {
            if (e instanceof TokenAccountNotFoundError) {
                // No account for the user
                // amount = 0
                console.log("No token found");
                return 0;
            } else {
                console.error('Error getting coupon balance', e)
                return 0;
            }
        }
    }

    const getRoundedValue = (value) => {
        if (!value) {
            return value;
        }
        return value.toFixed(0);
    }

    return (
        <>
            {!childWalletBalanceList &&
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress size={24} />
                </div>
            }
            {childWalletBalanceList && childWalletBalanceList?.length > 0 ?
                <Paper sx={{
                    // background: "linear-gradient(190.57deg, rgba(255, 255, 255, 0.56) 19.25%, rgba(248, 248, 255, 0.71) 54.39%, rgba(255, 255, 255, 0.59) 90.02%)",
                    background: "white",
                    padding: '15px',
                    textAlign: 'center',
                    minHeight: '80%',
                    boxShadow: 'none',
                    borderRadius: '25px',
                    width: '100%'
                }}>
                    <Typography variant={'h6'} style={{
                        fontFamily: "Montserrat",
                        fontStyle: "bold",
                        fontWeight: "700",
                        lineHeight: "22px",
                        fontSize: '18px',
                        letterSpacing: "0px",
                        textAlign: "center",
                        color: "#000000",
                        paddingTop: "20px"
                    }}>
                        Balance
                    </Typography>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        marginBottom: 15,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <PieChart
                            style={{
                                fontFamily:
                                    '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                                fontSize: '5px',
                                height: '250px'
                            }}
                            data={chartData}
                            radius={PieChart.defaultProps.radius - 6}
                            lineWidth={60}
                            // segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                            // segmentsShift={(index) => (index === selected ? 6 : 1)}
                            animate
                            label={({ dataEntry }) => (`$${getRoundedValue(dataEntry.balance)}`)}
                            labelPosition={100 - lineWidth / 2}
                            labelStyle={{
                                fill: '#fff',
                                opacity: 1,
                                pointerEvents: 'none',
                                fontSize: '5px'
                            }}
                            onClick={(_, index) => {
                                setSelected(index === selected ? undefined : index);
                            }}
                            onMouseOver={(_, index) => {
                                setHovered(index);
                            }}
                            onMouseOut={() => {
                                setHovered(undefined);
                            }}
                        />
                    </div>
                    {
                        (childWalletBalanceList && childWalletBalanceList?.length > 0) ?
                            <WalletBalanceList childData={childWalletBalanceList} random={random} loggedInUserId={loggedInUserDetails.id} /> :
                            <CircularProgress />
                    }
                </Paper>
                : childWalletBalanceList && childWalletBalanceList?.length === 0 &&
                <div className={classes.noEvents}>
                    <div className={classes.upComingEventsHeading}>Balance</div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                        <img src={EventsDashboardImg} style={{
                            height: '85px', width: '145px'
                        }} />
                        <div style={{
                            fontSize: 21, lineHeight: '26px', marginTop: 20, marginBottom: 40, width: 175
                        }}>You have not added any child/user wallet address.</div>
                        <div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default WalletBalance;