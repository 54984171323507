import { Box, List } from "@material-ui/core";
import { Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import { getChildProfile, childProfilePhoto } from "../../utils/Util";
import KidfolioContext from "../../store/KidfolioContext";

function WalletBalanceList(props) {
    const ctx = useContext(KidfolioContext);
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const getChildPhoto = (childData) => {
        let childUrl = getChildProfile(ctx.beneficiaryImageUrl, childData.id);
        if (!childUrl) {
            childUrl = ctx.findBlobUrlForResourceId(childData.imageId);
            if (!childUrl) {
                childUrl = childProfilePhoto[0];
            }
        }
        return childUrl
    }

    useEffect(() => {
        console.log("re-rendering")
    }, [])

    const getRoundedValue = (value) => {
        if (!value) {
            return value;
        }
        return value.toFixed(0);
    }

    return (
        <Box style={{ overflow: "scroll" }}>
            <List>
                {
                    (props.childData) ?
                        props.childData.map((childData, index) => (
                            (childData) ?
                                <Box key={index} style={{ minHeight: '80px', marginTop: '5px', marginBottom: '5px' }}>
                                    <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '50px', display: 'flex', alignItems: 'center' }}>
                                            <img src={getChildPhoto(childData)} style={{
                                                height: '50px', width: '50px', borderRadius: "15px"
                                            }} />
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: "center",
                                            width: 'calc(100% - 65px)',
                                            paddingLeft: '10px'
                                        }}>
                                            <div >
                                                <div style={{
                                                    fontFamily: "Montserrat",
                                                    fontSize: 15,
                                                    fontWeight: "400",
                                                    textAlign: "left",
                                                    color: "#002884",
                                                }}>
                                                    {capitalizeFirstLetter(childData.title)}
                                                </div>
                                            </div>
                                            <div style={{ justifyContent: 'center', height: '100%', display: "flex", alignItems: "center" }}>
                                                <div style={{
                                                    fontSize: 14,
                                                    fontWeight: 400,
                                                    color: 'black'
                                                }}>
                                                    ${getRoundedValue(childData.value)}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Box> : <div></div>
                        )) : <div>N/a</div>
                }
            </List>
        </Box>
    )
};

export default WalletBalanceList;
